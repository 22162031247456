import React from "react";
import styled from "@emotion/styled";

interface Props {
  icon:
    | "plus"
    | "filter"
    | "x"
    | "edit"
    | "delete"
    | "upload"
    | "minus"
    | "refresh";
}

function Icon({ icon }: Props) {
  return (
    <>
      {icon === "plus" ? (
        <PlusIcon />
      ) : icon === "filter" ? (
        <FilterIcon />
      ) : icon === "x" ? (
        <XIcon />
      ) : icon === "edit" ? (
        <EditIcon />
      ) : icon === "delete" ? (
        <DeleteIcon />
      ) : icon === "upload" ? (
        <UploadIcon />
      ) : icon === "refresh" ? (
        <RefreshIcon />
      ) : icon === "minus" ? (
        <MinusIcon />
      ) : null}
    </>
  );
}

const SVG = styled.svg`
  @media only screen and (max-width: 450px) {
    height: 18px;
    width: auto;
  }
`;

const PlusIcon = () => {
  return (
    <SVG
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0694 8V16M8.06944 12H16.0694M5.06944 3H19.0694C20.174 3 21.0694 3.89543 21.0694 5V19C21.0694 20.1046 20.174 21 19.0694 21H5.06944C3.96487 21 3.06944 20.1046 3.06944 19V5C3.06944 3.89543 3.96487 3 5.06944 3Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const FilterIcon = () => {
  return (
    <SVG
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.56946 12H18.5695M3.56946 6H21.5695M9.56946 18H15.5695"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const XIcon = () => {
  return (
    <SVG
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 5L5.5 15M5.5 5L15.5 15"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const EditIcon = () => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21H21M16.5 4.49998C16.8978 4.10216 17.4374 3.87866 18 3.87866C18.2786 3.87866 18.5544 3.93353 18.8118 4.04014C19.0692 4.14674 19.303 4.303 19.5 4.49998C19.697 4.69697 19.8532 4.93082 19.9598 5.18819C20.0665 5.44556 20.1213 5.72141 20.1213 5.99998C20.1213 6.27856 20.0665 6.55441 19.9598 6.81178C19.8532 7.06915 19.697 7.303 19.5 7.49998L7 20L3 21L4 17L16.5 4.49998Z"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const DeleteIcon = () => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="#515151"
      />
    </SVG>
  );
};

const MinusIcon = () => {
  return (
    <SVG
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.06946 12H16.0695M22.0695 12C22.0695 17.5228 17.5923 22 12.0695 22C6.54661 22 2.06946 17.5228 2.06946 12C2.06946 6.47715 6.54661 2 12.0695 2C17.5923 2 22.0695 6.47715 22.0695 12Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const UploadIcon = () => {
  return (
    <SVG
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0694 16L12.0694 12M12.0694 12L8.06942 16M12.0694 12V21M20.4594 18.39C21.4348 17.8583 22.2053 17.0169 22.6493 15.9986C23.0933 14.9804 23.1856 13.8432 22.9116 12.7667C22.6376 11.6901 22.0129 10.7355 21.1361 10.0534C20.2593 9.37137 19.1803 9.00072 18.0694 8.99998H16.8094C16.5067 7.82923 15.9426 6.74232 15.1594 5.82098C14.3762 4.89964 13.3943 4.16783 12.2875 3.68059C11.1808 3.19335 9.97797 2.96334 8.76954 3.00787C7.5611 3.05239 6.37849 3.37028 5.3106 3.93765C4.2427 4.50501 3.31733 5.30709 2.60404 6.28357C1.89075 7.26004 1.40811 8.38552 1.1924 9.57538C0.976688 10.7652 1.03352 11.9885 1.35863 13.1532C1.68374 14.318 2.26866 15.3938 3.06942 16.3"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const RefreshIcon = () => {
  return (
    <SVG
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4498 5.75644C17.8375 5.9853 17.9509 6.46098 17.703 6.8189C17.455 7.17682 16.9572 8.22892 16.5695 8.00006C15.2415 7.21624 13.6816 6.50006 12.0695 6.50006C7.46708 6.50006 4.56946 9.50006 4.56946 13.5001C4.56946 17.5001 7.46708 20.2693 12.0695 20.2693C16.6718 20.2693 19.5695 17.2484 19.5695 13.0001C19.5695 12.5752 20.6092 12.5001 21.0695 12.5001C21.5297 12.5001 22.0695 12.5752 22.0695 13.0001C22.0695 18.0981 17.5923 22.7693 12.0695 22.7693C6.54661 22.7693 2.06946 18.6365 2.06946 13.5385C2.06946 8.44051 6.54661 4.30775 12.0695 4.30775C14.0022 4.30775 15.8557 4.81555 17.4498 5.75644ZM22.0695 3.53852V8.15391C22.0695 9.42841 20.9502 11.0001 19.5695 11.0001H14.5695C14.1092 11.0001 13.7361 10.1172 13.7361 9.69237C13.7361 9.26753 14.1092 8.92314 14.5695 8.92314H19.5695C20.0297 8.92314 20.0695 8.57874 20.0695 8.15391V3.53852C20.0695 3.11369 20.7759 2.76929 21.2361 2.76929C21.6964 2.76929 22.0695 3.11369 22.0695 3.53852Z"
        fill="white"
      />
    </SVG>
  );
};
export default Icon;
