import React from "react";
import { useAuth } from "../../../Context/AuthContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../../../Components/Button2/Button2";

interface Props {
  username: string;
  handleOpen: any;
  setActivePanel: any;
  otp: string;
}

function CESSuccess({ username, otp, handleOpen, setActivePanel }: Props) {
  let navigate = useNavigate();
  const { t }: { t: any } = useTranslation("signup");

  const handleClose = () => {
    setActivePanel(0);
    handleOpen(false);
  };

  return (
    <>
      <Box component="div" sx={{ mx: 6, mb: 8 }}>
        <Typography
          variant="h1"
          sx={{
            mb: 4,
            fontSize: "3.438rem",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          {t("create_new_user")}
        </Typography>
        <Box
          component="div"
          sx={{
            width: "100%",
            maxWidth: "680px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ConfirmationSVG />
          <Typography
            paragraph
            sx={{ fontSize: "2.125rem", fontWeight: "400" }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: "2.125rem",
                color: "primary.main",
                fontWeight: "500",
              }}
            >
              {username}
            </Typography>
            {" " + t("has_been_created")}.
          </Typography>
          <Typography
            paragraph
            sx={{ fontSize: "2.125rem", fontWeight: "400" }}
          >
            OTP: {otp}
          </Typography>
          <Typography paragraph sx={{ fontSize: "2.125rem" }}>
            {t("you_can_now")}
          </Typography>
          <Button onClick={() => handleClose()}>{t("close")}</Button>
        </Box>
      </Box>
    </>
  );
}

const SVG = styled.svg`
  height: 160px;
  width: 160px;
`;

const ConfirmationSVG = () => {
  return (
    <SVG
      width="160"
      height="161"
      viewBox="0 0 160 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.0002 13.553C43.2002 13.553 13.3335 43.4196 13.3335 80.2197C13.3335 117.02 43.2002 146.886 80.0002 146.886C116.8 146.886 146.667 117.02 146.667 80.2197C146.667 43.4196 116.8 13.553 80.0002 13.553ZM66.6668 113.553L33.3335 80.2197L42.7335 70.8196L66.6668 94.6863L117.267 44.0863L126.667 53.553L66.6668 113.553Z"
        fill="#4AB9B6"
      />
    </SVG>
  );
};

export default CESSuccess;
