import { JobOption } from "../Models/job";
import endpoints from "./config";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const jobApi = {
  post: async (jobOption: JobOption, uid?: string) =>
    axios.post(endpoints.JOBS, jobOption, {
      headers: headers,
      params: { uid },
    }),
};

export default jobApi;
