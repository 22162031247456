import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import Tooltip from "../../Tooltip";
import SVG from "../BodyNavSVG";
import ConditionalWrapper from "../../../Helpers/ConditionalWrapper";

interface Props {
  label: string;
  onClick: any;
  selected: boolean;
  disabled: boolean;
}

const BodyNavButton = ({ label, onClick, selected, disabled }: Props) => {
  const { t } = useTranslation("translations");
  return (
    <>
      <ConditionalWrapper
        condition={disabled}
        wrapper={(children) => <Tooltip comingSoon>{children}</Tooltip>}
      >
        <Box
          component="button"
          disabled={disabled}
          onClick={() => (onClick ? onClick(label) : undefined)}
          sx={{
            border: "1px solid #DFE0E2",
            cursor: disabled ? "auto" : "pointer",
            background: disabled ? "#c8c8c8" : selected ? "#5C76AA" : "#F7F7F7",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            py: 1,
            pl: 1,
            pr: 2,
            transition: "all 180ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            "&:hover": {
              backgroundColor: disabled ? null : selected ? null : "#DFE0E2",
            },
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              mr: 1,
              svg: {
                height: "20px",
                width: "auto",
                maxWidth: "20px",
                minWidth: "20px",
              },
            }}
          >
            <SVG label={label} disabled={disabled} selected={selected} />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: selected ? "white" : null,
              transition: "all 180ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            }}
          />
          <Box
            component="div"
            className="textContainer"
            sx={{
              ml: 1,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: disabled ? "#696969" : selected ? "white" : "#343434",
                transition: "all 180ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
              }}
            >
              {t(label)}
            </Typography>
          </Box>
        </Box>
      </ConditionalWrapper>
    </>
  );
};

export default BodyNavButton;
