import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

interface Props {
  dimensions: string;
  setDimensions: any;
  gender: string;
  setGender: any;
  view?: string;
  setView?: any;
}

const BodyControls = ({
  dimensions,
  setDimensions,
  gender,
  setGender,
  view,
  setView,
}: Props) => {
  const { t } = useTranslation("body");
  const genderOptions = ["female", "male"];
  const dimensionOptions = ["2d", "3d"];
  const viewOptions = ["front", "frontc", "back", "side"];

  return (
    <>
      <Box
        component="div"
        sx={{
          borderRadius: "16px",
          background: "#F7F7F7",
          alignSelf: "end",
          p: 1,
        }}
      >
        <Box component="div" sx={{ display: "flex" }}>
          <Box component="div">
            {genderOptions.map((i: any, index: number) => {
              return (
                <Switch
                  key={index}
                  onClick={() => setGender(t(i))}
                  selected={t(i) === gender}
                >
                  {t(i)}
                </Switch>
              );
            })}
          </Box>
          <Box component="div" sx={{ ml: 1 }}>
            {dimensionOptions.map((i: any, index: number) => {
              return (
                <Switch
                  key={index}
                  onClick={() => setDimensions(t(i))}
                  selected={t(i) === dimensions}
                >
                  {t(i)}
                </Switch>
              );
            })}
          </Box>
        </Box>
        {/* <Box component="div">
          {viewOptions.map((i: any) => {
            return (
              <Switch
                onClick={() => setView(t(i))}
                selected={t(i) === view}
                disable={dimensions.toLowerCase() === "3d"}
                disabled={dimensions.toLowerCase() === "3d"}
              >
                {t(i)}
              </Switch>
            );
          })}
        </Box> */}
      </Box>
    </>
  );
};

interface SwitchProps {
  selected: boolean;
  disable?: boolean;
}

const Switch = styled.button<SwitchProps>`
  cursor: ${(props) =>
    props.disable ? null : props.selected ? null : "pointer"};
  border: 1px solid #dfe0e2;
  background: ${(props) =>
    props.disable ? "#c8c8c8" : props.selected ? "#5C76AA" : " #F7F7F7"};
  padding: 4px 12px;
  color: ${(props) =>
    props.disable ? "#DFE0E2" : props.selected ? "white" : "#5C76AA"};
  transition: all 180ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  &:hover {
    background: ${(props) =>
      props.disable ? "#c8c8c8" : props.selected ? "#5C76AA" : " #DFE0E2"};
  }

  &:first-of-type {
    border-radius: 8px 0 0 8px;
    border-right: none;
  }

  &:last-of-type {
    border-radius: 0 8px 8px 0;
    border-left: none;
  }
`;

export default BodyControls;
