const header = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER_URL}`,
};

interface GetParamProps {
  query: object;
  distinct: string;
}

export const getParam = async ({ query, distinct }: GetParamProps) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...header,
    },
    body: JSON.stringify({ query: query, distinct: distinct }),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/api/sizing`;

  try {
    const res = await fetch(URL, requestOptions);
    const data = await res.json();
    return data;
  } catch (err) {
    return 400;
  }
};
