import React from "react";
import Box from "@mui/material/Box";
import Button from "./BodyNavButton";

interface Props {
  labels: any;
  onClick: any;
  selected: string | boolean;
}

const BodyNav = ({ labels, selected, onClick }: Props) => {
  return (
    <>
      <Box
        component="div"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          my: 2,
          background: "#F7F7F7",
          p: 1,
          borderRadius: "16px",
        }}
      >
        {labels.map((i: any, index: number) => {
          return (
            <Button
              key={index}
              label={i}
              onClick={onClick}
              selected={selected === i}
              disabled={false}
            />
          );
        })}
      </Box>
    </>
  );
};

export default BodyNav;
