import React from "react";
import MUITooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

// comingsoon_header

interface Props {
  comingSoon?: boolean;
  text?: any;
  children?: any;
}

const Tooltip = ({ comingSoon, text, children }: Props) => {
  const { t } = useTranslation("translations");

  return (
    <MUITooltip
      placement="right-end"
      title={comingSoon ? t("comingsoon_header") : text}
    >
      <span>{children}</span>
    </MUITooltip>
  );
};

export default Tooltip;
