import React, { useState, useEffect, Suspense } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Context/AuthContext";
import { editUser } from "../../Services/Users/Users";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

import Body from "../../Components/Body";
import Toast from "../../Components/Toast";
import BodyNav from "../../Components/BodyNav";
import BodySelections from "../../Components/BodySelections/BodySelections";
import BodyControls from "../../Components/BodyControls";
import Button from "../../Components/Button";

const BodyMeasures = () => {
  const { currentUser } = useAuth();
  const { t }: { t: any } = useTranslation("body");
  const [formData, setFormData, allToggleInitial] = useOutletContext<any>();
  const [toastLoading, setToastLoading] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<number | null>(null);
  const [dimensions, setDimensions] = useState("2D");
  const [gender, setGender] = useState("Female");
  const [view, setView] = useState<"Front" | "Front C" | "Side" | "Back">(
    "Front"
  );
  const [allToggle, setAllToggle] = useState<any[]>([
    { heading: "Hand", circumference: false, length: false },
    { heading: "Lower Body", circumference: false, length: false },
    { heading: "Foot", circumference: false, length: false },
    { heading: "Upper Body", circumference: false, length: false },
    { heading: "Head", circumference: false, length: false },
    { heading: "Other", circumference: false, length: false },
  ]);

  useEffect(() => {
    setAllToggle(allToggleInitial);
  }, [allToggleInitial]);

  let labels: any[] = [];

  formData &&
    formData?.map((i: any) => {
      labels.push(i.heading);
    });

  const [selected, setSelected] = useState<string | false>(
    labels ? labels[0] : false
  );

  const handleItem = (label: string, heading: string) => {
    let newData = formData;

    newData.map((i: any) => {
      if (i.heading === heading) {
        i.data.map((e: any) => {
          if (e.title === label) {
            e.checked = !e.checked;

            if (e.checked === false) {
              e.selected = false;
              allToggle.map((j: any) => {
                if (j.heading === heading) {
                  j[e?.type] = false;
                }
              });
            }
            setFormData([...newData]);
          }
        });
      }
    });
  };

  const handleAllItems = (category: string, heading: string) => {
    let newData = formData;
    let toggleData = allToggle;
    heading.toLowerCase();
    category.toLowerCase();

    toggleData.map((i: any) => {
      if (i.heading.toLowerCase().replace(/ /g, "_") === heading) {
        if (category === "circumference") {
          i.circumference = !i.circumference;
        } else if (category === "length") {
          i.length = !i.length;
        }
      }
      setAllToggle([...toggleData]);
    });

    newData.map((i: any) => {
      if (i.heading.toLowerCase().replace(/ /g, "_") === heading) {
        i.data.map((e: any) => {
          if (e.type.toLowerCase().replace(/ /g, "_") === category) {
            allToggle.map((a: any) => {
              if (a.heading.toLowerCase().replace(/ /g, "_") === heading) {
                if (category === "circumference") {
                  e.checked = a.circumference;
                  if (e.checked === false) {
                    e.selected = false;
                  }
                } else if (category === "length") {
                  e.checked = a.length;
                  if (e.checked === false) {
                    e.selected = false;
                  }
                }
              }
            });
          }
        });
      }
    });

    let modified: any[] = [];

    newData.map((e: any) => {
      if (e.heading === heading) {
        e.data.map((f: any) => {
          if (f.type === category) {
            modified.push(f);
          }
        });
      }
    });

    for (let i = 0; i < newData.length; i++) {
      for (let j = 0; j < newData[i].data.length; j++) {
        for (let k = 0; k < modified.length; k++) {
          if (modified[k].id === newData[i].data[j].id) {
            newData[i].data[j].checked = modified[k].checked;
          }
        }
      }
    }
  };

  const addToCart = () => {
    let newData = formData;

    for (let i = 0; i < newData.length; i++) {
      for (let j = 0; j < newData[i].data.length; j++) {
        if (newData[i].data[j].checked === true) {
          newData[i].data[j].selected = true;
        } else {
          newData[i].data[j].selected = false;
        }
      }
    }

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].data.some((item: any) => item.selected)) {
        newData[i].enabled = true;
      } else {
        newData[i].enabled = false;
      }
    }

    saveConfig();
  };

  const handleNav = (tab: string) => {
    setSelected(tab);
  };

  const checkSelected = (heading: string, category: string) => {
    let data = allToggle.find(
      (i: any) =>
        i.heading.toLowerCase().replace(" ", "_") ===
        heading.toLowerCase().replace(" ", "_")
    );
    if (data && data[category]) {
      return data[category];
    }
  };

  const saveConfig = async () => {
    setToastLoading(true);

    const token = await currentUser.getIdToken();
    await editUser({
      token: token,
      uid: currentUser?.uid,
      tmpUser: { configuration: formData },
    }).then((res: any) => {
      if (res) {
        setToastStatus(res.status);
        setTimeout(() => {
          setToastLoading(false);
        }, 3000);
      }
    });
  };
  return (
    <>
      <Toast
        loading={toastLoading}
        message={
          toastStatus === 200
            ? t("config_saved")
            : toastStatus === 400
            ? t("error")
            : t("loading")
        }
      />
      <Grid container>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box component="div">
            <BodyNav labels={labels} selected={selected} onClick={handleNav} />
          </Box>
          <Grid container>
            <Grid item sx={{ width: "100%" }}>
              <Box
                component="div"
                sx={{
                  borderRadius: "8px",
                  background: "#F7F7F7",
                  p: 2,
                }}
              >
                {formData &&
                  formData.map((i: any, index: any) => {
                    if (i.heading === selected) {
                      return (
                        <BodySelections
                          key={index}
                          data={i.data}
                          heading={i.heading}
                          handleClick={handleItem}
                          handleAllItems={handleAllItems}
                          allSelectedCirc={checkSelected(
                            i.heading,
                            "circumference"
                          )}
                          allSelectedLength={checkSelected(i.heading, "length")}
                        />
                      );
                    }
                  })}
                <Box
                  component="div"
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button onClick={() => addToCart()} lowPadding>
                    {t("save_config")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            pr: { xs: 0, lg: 2 },
            pt: 2,
          }}
        >
          <Box
            component="div"
            sx={{
              height: "100%",
              width: "100%",
              ml: { xs: 0, lg: 2 },
            }}
          >
            <BodyControls
              dimensions={dimensions}
              setDimensions={setDimensions}
              gender={gender}
              setGender={setGender}
              view={view}
              setView={setView}
            />
            <Suspense
              fallback={
                <Skeleton
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "16px",
                    transform: "none !important",
                  }}
                />
              }
            >
              <Body
                formData={formData}
                dimensions={dimensions}
                gender={gender}
              />
            </Suspense>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BodyMeasures;
