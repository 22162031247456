const header = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER_URL}`,
};

export const getSwaggerDoc = async (token: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
  };
  const URL = `${process.env.REACT_APP_SERVER_URL}/api/openapi`;
  try {
    const res = await fetch(URL, requestOptions);
    const data = await res.json();
    return data;
  } catch (err) {
    return 400;
  }
};
