// @TODO:
// Convert attributes to correct data

const convertAttributes = new Map<
  string | boolean | number | undefined,
  string
>([
  ["comfort", "Comfort"],
  ["issue", "Wrong Measure"],
  [false, "Left"],
  [true, "Right"],
  [0, "Ankle"],
  [1, "Calf"],
  [2, "Thigh"],
  ["C1.obj", "C1"],
  ["C2.obj", "C2"],
  ["C3.obj", "C3"],
  ["F1.obj", "F1"],
  ["F2.obj", "F2"],
  ["F3.obj", "F3"],
  ["F4.obj", "F4"],
  ["F5.obj", "F5"],
]);
export default convertAttributes;
