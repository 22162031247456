const cleanTMPUser = (user: any) => {
  if (user.hasOwnProperty("uid")) {
    delete user.uid;
  }
  Object.keys(user).map((i: any) => {
    if (i === "trial_app_status") {
      delete user[i];
    } else if (i === "trial_app_permissions") {
      user[i].map((e: any) => {
        if (e.hasOwnProperty("is_enabled")) {
          delete e.is_enabled;
        }
      });
    }
  });
  return user;
};

export default cleanTMPUser;
