import dayjs from "dayjs";

const formatDate = (date: Date | string) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format("YYYY-MM-DD HH:mm");
};

export default formatDate;
