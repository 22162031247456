import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

interface Props {
  username: string;
  handleEditMode: any;
  editMode: boolean;
  setUserModalOpen: any;
}

function ProfileHeader({
  username,
  handleEditMode,
  editMode,
  setUserModalOpen,
}: Props) {
  return (
    <>
      <Box
        component="div"
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid var(--primary-color)",
        }}
      >
        <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{ height: 40, width: 40, mr: 2 }}
            {...stringAvatar(username)}
          />
          <Typography
            variant="h3"
            sx={{
              fontWeight: "400",
              color: "primary.main",
              mr: 4,
            }}
          >
            {username}
          </Typography>
        </Box>
        <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            sx={{ display: { sm: "none" } }}
            size="small"
            onClick={() => setUserModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <IconButton size="large" onClick={() => handleEditMode()}>
            <EditIcon
              color={editMode ? "primary" : "action"}
              fontSize="large"
            />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default ProfileHeader;
