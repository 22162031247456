import React from "react";
import MUIAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

interface Props {
  header: string;
  children: any;
  defaultExpanded?: boolean;
}

function Accordion({ header, children, defaultExpanded }: Props) {
  return (
    <MUIAccordion disableGutters defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: "1.4rem", fontWeight: "400" }}>
          {header}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MUIAccordion>
  );
}

export default Accordion;
