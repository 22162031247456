import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

interface Props {
  cta: "clients" | "bodyMeasures" | "captureDashboard";
}

function HomeCTA({ cta }: Props) {
  const { t } = useTranslation("general");

  const locationData =
    cta === "clients"
      ? "/userdashboard"
      : cta === "bodyMeasures"
      ? "/bodymeasures"
      : "/";

  const bodyTest =
    cta === "clients"
      ? t("visualize_clients_details")
      : cta === "bodyMeasures"
      ? t("view_and_select")
      : cta === "captureDashboard"
      ? t("visualize_clients_captures")
      : "";

  return (
    <>
      <Link
        component={RouterLink}
        to={locationData}
        sx={{
          textDecoration: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "180px",
          "&:hover": {
            ".iconBorder": {
              borderColor: "var(--secondary-color)",
              background: "white",
            },
          },
          mt: 2,
        }}
      >
        <Box
          component="div"
          className="iconBorder"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "120px",
            width: "120px",
            mb: 2,
            borderRadius: "100%",
            border: "5px solid",
            borderColor: "var(--grey-inactive)",
            transition: "all var(--transition-animation)",
          }}
        >
          <Icon icon={cta} />
        </Box>

        <Typography sx={{ textAlign: "center" }}>{bodyTest}</Typography>
      </Link>
    </>
  );
}

export default HomeCTA;
