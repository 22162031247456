import * as React from "react";
import { ContentCopy } from "@mui/icons-material"
import { Button } from "@mui/material";

type Props = {
  text: string;
  styleProp: any;
};

function CopyButton({ text, styleProp }: Props) {
  return (
    <Button 
      sx={styleProp}
    >
      <Button
        onClick={() => navigator.clipboard.writeText(text)}
      >
        <ContentCopy />
      </Button>
    </Button>
  );
}

export default CopyButton;