import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { TextField as MUIField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

interface TextFieldProps {
  showLabel?: boolean;
  labelText?: string;
  inputType?: string;
  className?: string;
  placeholder?: string;
  register?: any;
  pattern?: string;
  value?: any;
  setFunction?: any;
  dataIndex?: string;
  userData?: any;
  required?: boolean;
  noDefault?: boolean;
  variant?: string;
  password?: boolean;
}

const TextField = ({
  showLabel = true,
  labelText,
  inputType = "text",
  placeholder = "",
  register,
  pattern,
  value,
  setFunction,
  dataIndex,
  userData,
  required,
  noDefault,
  variant,
  password,
}: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (dataIndex === "phoneNumber") {
      setFunction({ ...userData, [dataIndex]: formatToPhone(event) });
    } else if (setFunction && dataIndex) {
      setFunction({ ...userData, [dataIndex]: event.target.value });
    }
  };

  if (!value && dataIndex && userData[dataIndex]) {
    value = userData[dataIndex];
  }
  const isModifierKey = (event: any) => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  };

  const formatToPhone = (event: any) => {
    if (isModifierKey(event)) {
      return;
    }

    const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      event.target.value = `(${areaCode}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      event.target.value = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      event.target.value = `(${areaCode}`;
    }

    return event.target.value;
  };

  return (
    <Box
      component="div"
      sx={{ width: "100%", "&:not(:last-child)": { mb: 2 } }}
    >
      {password ? (
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor={`outlined-adornment-${placeholder}`}>
            {placeholder}
          </InputLabel>
          <OutlinedInput
            id={`outlined-adornment-${placeholder}`}
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            fullWidth
            defaultValue={noDefault ? "" : value}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={placeholder}
          />
        </FormControl>
      ) : (
        <>
          {showLabel && (
            <InputLabel
              sx={{
                whiteSpace: "break-spaces",
                fontSize: "0.9375rem",
              }}
            >
              {labelText}
            </InputLabel>
          )}
          <MUIField
            placeholder={placeholder}
            type={inputType}
            onChange={handleChange}
            variant={variant ? variant : "outlined"}
            defaultValue={noDefault ? "" : value}
            required={required}
            label={placeholder}
            inputProps={{
              pattern: pattern,
            }}
            sx={{
              width: "100%",
              color: "black",
              background: "white",
            }}
            {...register}
          />
        </>
      )}
    </Box>
  );
};

export default TextField;
