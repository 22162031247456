const FemaleBack: any[] = [
  <>
    <g
      id="Ol"
      data-name="Group 17773"
      transform="translate(4733.384 13570.605)"
    >
      <circle
        id="Ellipse_20"
        data-name="Ellipse 20"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4597 -12564.605)"
        fill="#5c76aa"
      />
      <circle
        id="Ellipse_21"
        data-name="Ellipse 21"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4600 -13218)"
        fill="#5c76aa"
      />
      <path
        id="Path_12124"
        data-name="Path 12124"
        d="M-6130.012-17990.941s5.712,30.4-8.1,55.7-26.5,78.191-26.867,110.145,9.844,133.92,13.6,148.719,12.322,73.92-1.8,116.266,25.538,173.408,26.672,220.666"
        transform="translate(1538 4781.004)"
        fill="none"
        stroke="#5c76aa"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        id="Path_12205"
        data-name="Path 12205"
        d="M-2.65-13.34c-3.68,0-6.2,2.92-6.2,6.76C-8.85-2.68-6.53.2-2.65.2.99.2,3.55-2.5,3.53-6.64,3.51-10.34,1.27-13.34-2.65-13.34Zm-3.7,6.76c0-2.96,1.54-4.62,3.66-4.62,2.34,0,3.72,1.82,3.72,4.62C1.05-3.8-.31-1.94-2.57-1.94-5.03-1.94-6.35-4-6.35-6.58ZM5.53-2.38C5.53-.28,6.57.2,7.85.2A2.916,2.916,0,0,0,8.99.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H5.71c-.12,0-.18.06-.18.16Z"
        transform="translate(-4591 -12532.605)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="HWl2"
      data-name="Group 17620"
      transform="translate(2970.884 13560.521)"
    >
      <g
        id="Group_17498"
        data-name="Group 17498"
        transform="translate(-2697 -13218)"
      >
        <circle
          id="Ellipse_20-2"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 663.479)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-2"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 0.479)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-2"
          data-name="Path 12124"
          d="M-6145.63-17993.664v665.814"
          transform="translate(6153 17999.002)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12206"
        data-name="Path 12206"
        d="M-11.15,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM.65-12.98c-.04-.12-.06-.16-.16-.16H-1.83a.179.179,0,0,0-.2.16C-3.57-6.44-4.17-4.24-4.41-2.94h-.02C-4.59-4.04-4.95-5.86-6.65-13c-.02-.14-.1-.14-.2-.14H-9.11c-.1,0-.14.04-.12.16L-5.89-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02C-.37-8.44.97-2.72,1.57-.12c.04.08.06.12.2.12H3.93c.12,0,.16-.02.2-.12L7.51-12.96c.02-.12-.02-.18-.14-.18H5.27a.182.182,0,0,0-.2.16C3.67-6.7,3.15-4.04,2.95-2.92H2.93C2.39-5.66,1.43-9.68.65-12.98Zm8.3,10.6C8.95-.28,9.99.2,11.27.2A2.916,2.916,0,0,0,12.41.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H9.13c-.12,0-.18.06-.18.16ZM21.73,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14h-2.3c-1.42,0-2.18,0-2.94.06a23.065,23.065,0,0,0,2.48-2.16A6.132,6.132,0,0,0,21.69-8.8a3.916,3.916,0,0,0-4.3-4.06,7.391,7.391,0,0,0-3.52.86.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02a42.211,42.211,0,0,1-3.32,2.72c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(-2640 -12522.521)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="Bw"
      data-name="Group 17614"
      transform="translate(-12743.807 2933) rotate(90)"
    >
      <g
        id="Group_17498-2"
        data-name="Group 17498"
        transform="translate(-2699 -13070)"
      >
        <circle
          id="Ellipse_20-3"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 182.309)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-3"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 0.309)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-3"
          data-name="Path 12124"
          d="M-6145.63-17993.664v182.734"
          transform="translate(6153 17998.51)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12212"
        data-name="Path 12212"
        d="M-11.7-.16a.152.152,0,0,0,.14.16c.76.02,2.12.04,3.86.04C-4.4.04-2.22-1.48-2.22-4A3.351,3.351,0,0,0-4.14-7.1,3.122,3.122,0,0,0-2.88-9.7c0-1.28-.64-3.48-4.82-3.48-1.34,0-3.16.04-3.88.06-.1.02-.12.08-.12.18Zm2.38-5.66H-7.5a5.262,5.262,0,0,1,1.36.14A1.679,1.679,0,0,1-4.7-3.94c0,1.34-1.2,1.98-3.08,1.96-.6,0-1.1-.02-1.54-.04Zm0-5.28c.42,0,.94-.02,1.58-.02,1.64,0,2.52.58,2.52,1.76a1.893,1.893,0,0,1-.76,1.58A12.321,12.321,0,0,0-7.8-7.9H-9.32ZM9.9,0c.14,0,.16-.04.22-.16l2.86-9.56c.04-.1-.04-.14-.12-.14H10.84a.161.161,0,0,0-.18.12C9.5-5.48,9.2-3.48,8.96-2.28H8.92c-.16-.8-.44-2.58-1.68-7.46a.14.14,0,0,0-.16-.12H4.78c-.14,0-.16.04-.18.16C3.28-4.86,2.94-3.04,2.78-2.34H2.74c-.14-.8-.44-2.7-1.64-7.4-.02-.1-.06-.12-.16-.12h-2.2c-.12,0-.18.06-.14.16L1.2-.16c.04.12.06.16.26.16H3.64a.2.2,0,0,0,.22-.16C4.8-3.48,5.3-5.4,5.76-7.4h.02c.2.98.54,2.76,1.7,7.22.04.12.08.18.22.18Z"
        transform="translate(-2684.5 -12829.191) rotate(-90)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="Sl"
      data-name="Group 17613"
      transform="translate(-12600.275 2848) rotate(90)"
    >
      <g
        id="Group_17498-3"
        data-name="Group 17498"
        transform="translate(-1081 147.508)"
      >
        <circle
          id="Ellipse_20-4"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618.131 -12982.508)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-4"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1289.544 -13175.145)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-4"
          data-name="Path 12124"
          d="M-5817.044-17948.646c-83.236,9.084-119.5,32.92-119.5,32.92s-138.258,29.986-162.81,43.322c-22.671,11.393-35.955,64.326-35.955,64.326"
          transform="translate(4535 4781.002)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12213"
        data-name="Path 12213"
        d="M-3.17.2C-.15.2,1.69-1.32,1.69-3.66c0-1.58-.74-2.84-3.34-3.92l-.76-.32c-1.58-.66-2.04-1.16-2.04-1.88,0-.7.54-1.42,2.26-1.42a6.248,6.248,0,0,1,3.02.66c.12.08.18,0,.18-.08v-1.94c0-.14-.02-.2-.12-.26a7.332,7.332,0,0,0-3.06-.52c-3.02,0-4.64,1.64-4.64,3.78,0,1.5.64,2.74,3.1,3.78l.96.4c1.52.64,2,1.2,2,1.94,0,.86-.7,1.5-2.34,1.5A6.73,6.73,0,0,1-6.65-2.98c-.08-.04-.16,0-.16.08V-.82a.3.3,0,0,0,.14.3A7.612,7.612,0,0,0-3.17.2ZM3.49-2.38C3.49-.28,4.53.2,5.81.2A2.916,2.916,0,0,0,6.95.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H3.67c-.12,0-.18.06-.18.16Z"
        transform="translate(-2684.5 -12809.5) rotate(-90)"
        fill="#52a3b3"
      />
    </g>
    <g id="Bl1" data-name="Group 17616" transform="translate(182.224 148.777)">
      <g id="Group_17498-4" data-name="Group 17498" transform="translate(37)">
        <circle
          id="Ellipse_20-5"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 203.591)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-5"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-5"
          data-name="Path 12124"
          d="M-6145.63-17993.666v205.928"
          transform="translate(6153 17999.004)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12211"
        data-name="Path 12211"
        d="M-10.33-.16a.152.152,0,0,0,.14.16c.76.02,2.12.04,3.86.04C-3.03.04-.85-1.48-.85-4A3.351,3.351,0,0,0-2.77-7.1,3.122,3.122,0,0,0-1.51-9.7c0-1.28-.64-3.48-4.82-3.48-1.34,0-3.16.04-3.88.06-.1.02-.12.08-.12.18Zm2.38-5.66h1.82a5.262,5.262,0,0,1,1.36.14A1.679,1.679,0,0,1-3.33-3.94c0,1.34-1.2,1.98-3.08,1.96-.6,0-1.1-.02-1.54-.04Zm0-5.28c.42,0,.94-.02,1.58-.02,1.64,0,2.52.58,2.52,1.76a1.893,1.893,0,0,1-.76,1.58A12.321,12.321,0,0,0-6.43-7.9H-7.95ZM.99-2.38C.99-.28,2.03.2,3.31.2A2.916,2.916,0,0,0,4.45.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H1.17c-.12,0-.18.06-.18.16ZM9.89,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H8.53a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(17 216.591)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="KWl"
      data-name="Group 17618"
      transform="translate(2970.884 13560.521)"
    >
      <g
        id="Group_17498-5"
        data-name="Group 17498"
        transform="translate(-2697 -13217.521)"
      >
        <circle
          id="Ellipse_20-6"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 395)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-6"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-6"
          data-name="Path 12124"
          d="M-6145.63-17993.664v398.191"
          transform="translate(6153 17998.523)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12207"
        data-name="Path 12207"
        d="M-10.7-6.76a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2h-2.3a.262.262,0,0,0-.24.12L-13.14-7.1v-5.88c0-.12-.04-.16-.16-.16h-2.1c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18h2.08c.12,0,.16-.04.16-.18V-6.1L-7.92-.16A.354.354,0,0,0-7.58,0h2.54a.129.129,0,0,0,.1-.22ZM4.7-12.98c-.04-.12-.06-.16-.16-.16H2.22a.179.179,0,0,0-.2.16C.48-6.44-.12-4.24-.36-2.94H-.38C-.54-4.04-.9-5.86-2.6-13c-.02-.14-.1-.14-.2-.14H-5.06c-.1,0-.14.04-.12.16L-1.84-.16c.06.1.1.16.22.16H.5C.64,0,.68-.08.7-.18,1.34-2.62,2.9-8.4,3.26-10.42h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12H7.98c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18H9.32a.182.182,0,0,0-.2.16C7.72-6.7,7.2-4.04,7-2.92H6.98C6.44-5.66,5.48-9.68,4.7-12.98ZM13-2.38C13-.28,14.04.2,15.32.2A2.916,2.916,0,0,0,16.46.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.18c-.12,0-.18.06-.18.16Z"
        transform="translate(-2629 -12808.521)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="WHl"
      data-name="Group 17619"
      transform="translate(2915.884 13570.127)"
    >
      <g
        id="Group_17498-6"
        data-name="Group 17498"
        transform="translate(-2697 -13217.521)"
      >
        <circle
          id="Ellipse_20-7"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 130.395)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-7"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-7"
          data-name="Path 12124"
          d="M-6145.63-17993.664v133.3"
          transform="translate(6153 17998.523)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12210"
        data-name="Path 12210"
        d="M-7.77-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-14.31-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L-.91-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-4.75-6.7-5.27-4.04-5.47-2.92h-.02C-6.03-5.66-6.99-9.68-7.77-12.98ZM11.01,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14H8.89a.126.126,0,0,0-.14.14v5.08H3.17v-5.06c0-.12-.04-.16-.16-.16H.93c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18H3.01c.12,0,.16-.06.16-.18V-5.74H8.75V-.16c0,.12.04.16.16.16Zm2.78-2.38C13.79-.28,14.83.2,16.11.2A2.916,2.916,0,0,0,17.25.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.97c-.12,0-.18.06-.18.16Z"
        transform="translate(-2661 -13073.126)"
        fill="#52a3b3"
      />
    </g>
    <g id="Bl2" data-name="Group 17617" transform="translate(182.224 148.777)">
      <g id="Group_17498-7" data-name="Group 17498" transform="translate(37 0)">
        <circle
          id="Ellipse_20-8"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 419.77)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-8"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-8"
          data-name="Path 12124"
          d="M-6145.63-17993.668v422.107"
          transform="translate(6153 17999.006)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12208"
        data-name="Path 12208"
        d="M-11.89-.16a.152.152,0,0,0,.14.16c.76.02,2.12.04,3.86.04C-4.59.04-2.41-1.48-2.41-4A3.351,3.351,0,0,0-4.33-7.1,3.122,3.122,0,0,0-3.07-9.7c0-1.28-.64-3.48-4.82-3.48-1.34,0-3.16.04-3.88.06-.1.02-.12.08-.12.18Zm2.38-5.66h1.82a5.262,5.262,0,0,1,1.36.14A1.679,1.679,0,0,1-4.89-3.94c0,1.34-1.2,1.98-3.08,1.96-.6,0-1.1-.02-1.54-.04Zm0-5.28c.42,0,.94-.02,1.58-.02,1.64,0,2.52.58,2.52,1.76a1.893,1.893,0,0,1-.76,1.58A12.321,12.321,0,0,0-7.99-7.9H-9.51ZM-.57-2.38C-.57-.28.47.2,1.75.2A2.916,2.916,0,0,0,2.89.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H-.39c-.12,0-.18.06-.18.16ZM12.21,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14h-2.3c-1.42,0-2.18,0-2.94.06A23.064,23.064,0,0,0,9.91-4.18,6.132,6.132,0,0,0,12.17-8.8a3.916,3.916,0,0,0-4.3-4.06A7.391,7.391,0,0,0,4.35-12a.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02A42.211,42.211,0,0,1,4.03-1.74c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(15 432.77)"
        fill="#52a3b3"
      />
    </g>
    <g id="WCl" data-name="Group 17615" transform="translate(184.224 352.605)">
      <g id="Group_17498-8" data-name="Group 17498" transform="translate(35)">
        <circle
          id="Ellipse_20-9"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 169.768)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-9"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-9"
          data-name="Path 12124"
          d="M-6145.63-17993.666v172.105"
          transform="translate(6153 17999.004)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12209"
        data-name="Path 12209"
        d="M-6.67-12.98c-.04-.12-.06-.16-.16-.16H-9.15a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-13.21-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L.19-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-3.65-6.7-4.17-4.04-4.37-2.92h-.02C-4.93-5.66-5.89-9.68-6.67-12.98ZM10.85-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1,3.59-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9a.212.212,0,0,0-.12-.22,6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C1.09-1.98,4.25.2,7.95.2a8.624,8.624,0,0,0,2.76-.36.254.254,0,0,0,.14-.26Zm1.84-.18C12.69-.28,13.73.2,15.01.2A2.916,2.916,0,0,0,16.15.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H12.87c-.12,0-.18.06-.18.16Z"
        transform="translate(10 184.768)"
        fill="#52a3b3"
      />
    </g>
    <path
      id="Sl"
      data-name="Path 12160"
      d="M-6145.63-17755.238l8.364-45.838"
      transform="translate(-17528.785 6302) rotate(90)"
      fill="none"
      stroke="#52a3b3"
      strokeLinecap="round"
      strokeWidth="5"
      strokeDasharray="10"
    />
  </>,
];

export default FemaleBack;
