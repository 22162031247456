import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import SVG from "../SelectionSVG";
import Tooltip from "../SelectionTooltip";

interface Props {
  label: any;
  checked: boolean;
  heading: string;
  handleClick: any;
  id: string;
  type?: string;
}

const SelectionButton = ({
  label,
  checked,
  handleClick,
  heading,
  id,
  type,
}: Props) => {
  const { t } = useTranslation("measures");

  return (
    <>
      <Button
        onClick={() => handleClick(label, heading)}
        checked={checked}
        buttonType={type}
      >
        <Typography
          component="p"
          variant="body2"
          sx={{
            color: "#343434",
            textAlign: "left",
            wordBreak: "break-word",
          }}
        >
          {t(label)}
        </Typography>
      </Button>
    </>
  );
};

interface ButtonProps {
  checked: boolean;
  buttonType?: string;
}

const Button = styled.button<ButtonProps>`
  cursor: pointer;
  border: 1px solid
    ${(props) => (props.buttonType === "circumference" ? "#52A3B3" : "#e7e7e7")};
  border-radius: 8px;
  padding: 4px 8px;
  width: 100%;
  background: ${(props) =>
    props.checked && props.buttonType === "circumference"
      ? "#52A3B3"
      : props.checked && props.buttonType === "length"
      ? "#5C76AA"
      : "#F7F7F7"};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: ${(props) =>
      props.checked && props.buttonType === "circumference"
        ? "#52A3B3"
        : props.checked && props.buttonType === "length"
        ? "#5C76AA"
        : "#DFE0E2"};
  }
  p {
    color: ${(props) => (props.checked ? "white !important" : "#343434")};
  }
`;

export default SelectionButton;
