const header = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER_URL}`,
};

export const signUp = async (body: any) => {
  const requestOptions = {
    method: "POST",
    headers: { ...header },
    body: JSON.stringify({
      first_name: body.first_name,
      last_name: body.last_name,
      email: body.email,
      brand: body.brand ?? null,
    }),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/auth/signupces`;
  const req = await fetch(URL, requestOptions);
  const res = await req.json();
  return res;
};

interface LoginProps {
  email: string;
}

export const login = async ({ email }: LoginProps) => {
  const requestOptions = {
    method: "POST",
    headers: { ...header },
    body: JSON.stringify({
      email: email,
    }),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/auth/verifylogin`;
  const req = await fetch(URL, requestOptions);
  const res = await req.json();
  return res;
};
