const MaleFront = [
  <>
    <g id="H" data-name="Group 17681" transform="translate(2739.14 13574.891)">
      <g
        id="Group_17498"
        data-name="Group 17498"
        transform="translate(-2697 -13575)"
      >
        <circle
          id="Ellipse_20"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 1023.109)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 0.109)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124"
          data-name="Path 12124"
          d="M-6145.63-17993.666v1023.283"
          transform="translate(6153 18001.166)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12222"
        data-name="Path 12222"
        d="M5.05,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14H2.93a.126.126,0,0,0-.14.14v5.08H-2.79v-5.06c0-.12-.04-.16-.16-.16H-5.03c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74H2.79V-.16c0,.12.04.16.16.16Z"
        transform="translate(-2708 -13560.891)"
        fill="#5c76aa"
      />
    </g>
    <g id="WIl" data-name="Group 17687" transform="translate(2960.14 13598)">
      <g
        id="Group_17498-2"
        data-name="Group 17498"
        transform="translate(-1079)"
      >
        <circle
          id="Ellipse_20-2"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -12575)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-2"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -13218)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-2"
          data-name="Path 12124"
          d="M-6145.63-17993.664v645.336"
          transform="translate(4535 4781.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12241"
        data-name="Path 12241"
        d="M-3.78-12.98c-.04-.12-.06-.16-.16-.16H-6.26a.179.179,0,0,0-.2.16C-8-6.44-8.6-4.24-8.84-2.94h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-10.32-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12H-.5c.12,0,.16-.02.2-.12L3.08-12.96c.02-.12-.02-.18-.14-.18H.84a.182.182,0,0,0-.2.16C-.76-6.7-1.28-4.04-1.48-2.92H-1.5C-2.04-5.66-3-9.68-3.78-12.98Zm10.94,0c0-.12-.04-.16-.16-.16H4.9c-.1,0-.16.02-.16.16V-.18c0,.12.04.18.16.18H7c.12,0,.16-.04.16-.18ZM9.8-2.38C9.8-.28,10.84.2,12.12.2A2.916,2.916,0,0,0,13.26.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H9.98c-.12,0-.18.06-.18.16Z"
        transform="translate(-2663 -13203)"
        fill="#5c76aa"
      />
    </g>
    <g id="Il" data-name="Group 17691" transform="translate(3018.48 13598)">
      <g
        id="Group_17498-3"
        data-name="Group 17498"
        transform="translate(-1079 162.289)"
      >
        <circle
          id="Ellipse_20-3"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -12737.289)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-3"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -13218)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-3"
          data-name="Path 12124"
          d="M-6145.63-17993.664v483.047"
          transform="translate(4535 4781.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12239"
        data-name="Path 12239"
        d="M-1.39-12.98c0-.12-.04-.16-.16-.16h-2.1c-.1,0-.16.02-.16.16V-.18c0,.12.04.18.16.18h2.1c.12,0,.16-.04.16-.18ZM1.25-2.38C1.25-.28,2.29.2,3.57.2A2.916,2.916,0,0,0,4.71.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H1.43c-.12,0-.18.06-.18.16Z"
        transform="translate(-2717 -13042)"
        fill="#5c76aa"
      />
    </g>
    <g id="MHl" data-name="Group 17752" transform="translate(6467.14 13589)">
      <g
        id="Group_17697"
        data-name="Group 17697"
        transform="matrix(-0.574, 0.819, -0.819, -0.574, -18228.199, -17871.289)"
      >
        <g
          id="Group_17498-4"
          data-name="Group 17498"
          transform="translate(-2697 -13055.711)"
        >
          <circle
            id="Ellipse_20-4"
            data-name="Ellipse 20"
            cx="7.5"
            cy="7.5"
            r="7.5"
            transform="translate(0 61.609)"
            fill="#5c76aa"
          />
          <circle
            id="Ellipse_21-4"
            data-name="Ellipse 21"
            cx="7.5"
            cy="7.5"
            r="7.5"
            transform="translate(0 0)"
            fill="#5c76aa"
          />
          <path
            id="Path_12124-4"
            data-name="Path 12124"
            d="M-6145.63-17993.664v63.945"
            transform="translate(6153 17999.002)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeWidth="5"
          />
        </g>
        <path
          id="Path_12234"
          data-name="Path 12234"
          d="M-15.57-13.02-16.33-.16c-.02.14.02.16.14.16h2c.12,0,.16-.02.16-.14.2-4.2.36-9.28.38-11.3h.04c.56,2.44,2.16,8.26,3.02,11.3.02.12.06.14.2.14h2a.159.159,0,0,0,.18-.16c.8-2.66,2.86-9.34,3.32-11.26h.02c-.02,1.26.02,7.02.12,11.28,0,.12.06.14.16.14h2.22c.1,0,.16-.04.14-.16l-.54-12.86c-.02-.1-.06-.12-.16-.12H-6.05c-.12,0-.14.06-.18.14-.62,2.06-2.6,8.12-3.02,9.82h-.02c-.4-1.86-2.14-7.8-2.74-9.82a.179.179,0,0,0-.2-.14h-3.2C-15.51-13.14-15.55-13.14-15.57-13.02ZM10.77,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14H8.65a.126.126,0,0,0-.14.14v5.08H2.93v-5.06c0-.12-.04-.16-.16-.16H.69c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18H2.77c.12,0,.16-.06.16-.18V-5.74H8.51V-.16c0,.12.04.16.16.16Zm2.78-2.38C13.55-.28,14.59.2,15.87.2A2.916,2.916,0,0,0,17.01.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.73c-.12,0-.18.06-.18.16Z"
          transform="matrix(-0.602, -0.799, 0.799, -0.602, -2713.883, -13032.83)"
          fill="#5c76aa"
        />
      </g>
      <g
        id="Group_17698"
        data-name="Group 17698"
        transform="matrix(0.574, 0.819, -0.819, 0.574, -6252.826, -12668.639)"
      >
        <g
          id="Group_17498-5"
          data-name="Group 17498"
          transform="translate(37 4)"
        >
          <ellipse
            id="Ellipse_20-5"
            data-name="Ellipse 20"
            cx="7.5"
            cy="7.5"
            rx="7.5"
            ry="7.5"
            transform="translate(0 0)"
            fill="#5c76aa"
          />
          <ellipse
            id="Ellipse_21-5"
            data-name="Ellipse 21"
            cx="7.5"
            cy="7.5"
            rx="7.5"
            ry="7.5"
            transform="translate(0 61.609)"
            fill="#5c76aa"
          />
          <path
            id="Path_12124-5"
            data-name="Path 12124"
            d="M0,63.945V0"
            transform="translate(7.37 7.326)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeWidth="5"
          />
        </g>
        <path
          id="Path_12231"
          data-name="Path 12231"
          d="M-15.57-13.02-16.33-.16c-.02.14.02.16.14.16h2c.12,0,.16-.02.16-.14.2-4.2.36-9.28.38-11.3h.04c.56,2.44,2.16,8.26,3.02,11.3.02.12.06.14.2.14h2a.159.159,0,0,0,.18-.16c.8-2.66,2.86-9.34,3.32-11.26h.02c-.02,1.26.02,7.02.12,11.28,0,.12.06.14.16.14h2.22c.1,0,.16-.04.14-.16l-.54-12.86c-.02-.1-.06-.12-.16-.12H-6.05c-.12,0-.14.06-.18.14-.62,2.06-2.6,8.12-3.02,9.82h-.02c-.4-1.86-2.14-7.8-2.74-9.82a.179.179,0,0,0-.2-.14h-3.2C-15.51-13.14-15.55-13.14-15.57-13.02ZM10.77,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14H8.65a.126.126,0,0,0-.14.14v5.08H2.93v-5.06c0-.12-.04-.16-.16-.16H.69c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18H2.77c.12,0,.16-.06.16-.18V-5.74H8.51V-.16c0,.12.04.16.16.16Zm2.78-2.38C13.55-.28,14.59.2,15.87.2A2.916,2.916,0,0,0,17.01.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.73c-.12,0-.18.06-.18.16Z"
          transform="matrix(0.545, -0.839, 0.839, 0.545, 22.335, 54.562)"
          fill="#5c76aa"
        />
      </g>
    </g>
    <g
      id="THl"
      data-name="Group 17700"
      transform="matrix(0.574, 0.819, -0.819, 0.574, 293.837, 977.361)"
    >
      <g id="Group_17498-6" data-name="Group 17498" transform="translate(37 4)">
        <ellipse
          id="Ellipse_20-6"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          rx="7.5"
          ry="7.5"
          transform="translate(0 0)"
          fill="#5c76aa"
        />
        <ellipse
          id="Ellipse_21-6"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          rx="7.5"
          ry="7.5"
          transform="translate(0 61.609)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-6"
          data-name="Path 12124"
          d="M0,63.945V0"
          transform="translate(7.37 7.326)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12232"
        data-name="Path 12232"
        d="M-9.432-.162c0,.126.036.18.162.162h1.854c.09,0,.144-.054.144-.162v-9.7h3.636c.09,0,.144-.054.126-.162l-.162-1.638c-.018-.126-.072-.162-.18-.162H-12.8c-.108,0-.144.036-.162.144l-.234,1.674c-.018.09.018.144.108.144h3.654ZM7.074,0c.108,0,.144-.036.144-.144V-11.7c0-.09-.036-.126-.126-.126H5.166a.114.114,0,0,0-.126.126v4.572H.018v-4.554c0-.108-.036-.144-.144-.144H-2c-.108,0-.144.036-.144.162v11.5c0,.108.036.162.162.162H-.126c.108,0,.144-.054.144-.162v-5H5.04V-.144c0,.108.036.144.144.144Zm2.5-2.142c0,1.89.936,2.322,2.088,2.322A2.624,2.624,0,0,0,12.69.036c.09-.054.108-.072.108-.2v-1.35c0-.09-.018-.126-.072-.108a2.3,2.3,0,0,1-.342.018c-.432,0-.63-.18-.63-.738V-12.672c0-.09-.054-.144-.162-.144H9.738c-.108,0-.162.054-.162.144Z"
        transform="translate(26.452 108.778) rotate(-54)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="THl"
      data-name="Group 17701"
      transform="matrix(-0.574, 0.819, -0.819, -0.574, 408.27, 1053.905)"
    >
      <g id="Group_17498-7" data-name="Group 17498" transform="translate(0 0)">
        <ellipse
          id="Ellipse_20-7"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          rx="7.5"
          ry="7.5"
          transform="translate(0 61.609)"
          fill="#5c76aa"
        />
        <ellipse
          id="Ellipse_21-7"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          rx="7.5"
          ry="7.5"
          transform="translate(0 0)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-7"
          data-name="Path 12124"
          d="M0,0V63.945"
          transform="translate(7.37 5.338)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12233"
        data-name="Path 12233"
        d="M-9.432-.162c0,.126.036.18.162.162h1.854c.09,0,.144-.054.144-.162v-9.7h3.636c.09,0,.144-.054.126-.162l-.162-1.638c-.018-.126-.072-.162-.18-.162H-12.8c-.108,0-.144.036-.162.144l-.234,1.674c-.018.09.018.144.108.144h3.654ZM7.074,0c.108,0,.144-.036.144-.144V-11.7c0-.09-.036-.126-.126-.126H5.166a.114.114,0,0,0-.126.126v4.572H.018v-4.554c0-.108-.036-.144-.144-.144H-2c-.108,0-.144.036-.144.162v11.5c0,.108.036.162.162.162H-.126c.108,0,.144-.054.144-.162v-5H5.04V-.144c0,.108.036.144.144.144Zm2.5-2.142c0,1.89.936,2.322,2.088,2.322A2.624,2.624,0,0,0,12.69.036c.09-.054.108-.072.108-.2v-1.35c0-.09-.018-.126-.072-.108a2.3,2.3,0,0,1-.342.018c-.432,0-.63-.18-.63-.738V-12.672c0-.09-.054-.144-.162-.144H9.738c-.108,0-.162.054-.162.144Z"
        transform="translate(-8.794 -25.663) rotate(-124)"
        fill="#5c76aa"
      />
    </g>
    <g id="CAl" data-name="Group 17693" transform="translate(3043.48 13598)">
      <g
        id="Group_17498-8"
        data-name="Group 17498"
        transform="translate(-1079 162.289)"
      >
        <circle
          id="Ellipse_20-8"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1592.431 -12801.289)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-8"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -13218)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-8"
          data-name="Path 12124"
          d="M-6142.793-17574.605c9.048-76.687-8.011-114.375-10.005-208.09s-10.31-91.145-14.782-206.992"
          transform="translate(4557 4781.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12238"
        data-name="Path 12238"
        d="M10.48-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1,3.22-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9a.212.212,0,0,0-.12-.22,6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C.72-1.98,3.88.2,7.58.2a8.624,8.624,0,0,0,2.76-.36.254.254,0,0,0,.14-.26Zm9.48-1.26L21.12-.12a.2.2,0,0,0,.2.12h2.24c.12,0,.14-.06.12-.18L19.06-13.02c-.02-.1-.04-.12-.14-.12H16.14c-.08,0-.12.06-.12.14a3.058,3.058,0,0,1-.18,1.08L11.72-.2c-.02.14.02.2.14.2h2a.2.2,0,0,0,.22-.16l1.1-3.3ZM15.82-5.64c.6-1.82,1.4-4.16,1.72-5.5h.02c.4,1.4,1.34,4.18,1.76,5.5Zm9.14,3.26C24.96-.28,26,.2,27.28.2A2.916,2.916,0,0,0,28.42.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H25.14c-.12,0-.18.06-.18.16Z"
        transform="translate(-2679.431 -13042)"
        fill="#5c76aa"
      />
    </g>
    <g id="WKl" data-name="Group 17688" transform="translate(2960.14 13598)">
      <g
        id="Group_17498-9"
        data-name="Group 17498"
        transform="translate(-1079)"
      >
        <circle
          id="Ellipse_20-9"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -12845)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-9"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -13218)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-9"
          data-name="Path 12124"
          d="M-6145.63-17993.664v372.934"
          transform="translate(4535 4781.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12237"
        data-name="Path 12237"
        d="M-7.13-12.98c-.04-.12-.06-.16-.16-.16H-9.61a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-13.67-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L-.27-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-4.11-6.7-4.63-4.04-4.83-2.92h-.02C-5.39-5.66-6.35-9.68-7.13-12.98ZM6.25-6.76a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2H9.19a.262.262,0,0,0-.24.12L3.81-7.1v-5.88c0-.12-.04-.16-.16-.16H1.55c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18H3.65c.12,0,.16-.04.16-.18V-6.1L9.03-.16A.354.354,0,0,0,9.37,0h2.54a.129.129,0,0,0,.1-.22Zm6.9,4.38C13.15-.28,14.19.2,15.47.2A2.916,2.916,0,0,0,16.61.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.33c-.12,0-.18.06-.18.16Z"
        transform="translate(-2744 -12831)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="Sw"
      data-name="Group 17683"
      transform="translate(-12592.2 2900) rotate(90)"
    >
      <g
        id="Group_17498-10"
        data-name="Group 17498"
        transform="translate(-2699 -13070)"
      >
        <circle
          id="Ellipse_20-10"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 279.66)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-10"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 -0.34)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-10"
          data-name="Path 12124"
          d="M-6145.63-17993.664v280.086"
          transform="translate(6153 17998.51)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12242"
        data-name="Path 12242"
        d="M-7.89.2c3.02,0,4.86-1.52,4.86-3.86,0-1.58-.74-2.84-3.34-3.92l-.76-.32c-1.58-.66-2.04-1.16-2.04-1.88,0-.7.54-1.42,2.26-1.42a6.248,6.248,0,0,1,3.02.66c.12.08.18,0,.18-.08v-1.94c0-.14-.02-.2-.12-.26a7.332,7.332,0,0,0-3.06-.52c-3.02,0-4.64,1.64-4.64,3.78,0,1.5.64,2.74,3.1,3.78l.96.4c1.52.64,2,1.2,2,1.94,0,.86-.7,1.5-2.34,1.5a6.73,6.73,0,0,1-3.56-1.04c-.08-.04-.16,0-.16.08V-.82a.3.3,0,0,0,.14.3A7.612,7.612,0,0,0-7.89.2ZM8.97,0c.14,0,.16-.04.22-.16l2.86-9.56c.04-.1-.04-.14-.12-.14H9.91a.161.161,0,0,0-.18.12C8.57-5.48,8.27-3.48,8.03-2.28H7.99c-.16-.8-.44-2.58-1.68-7.46a.14.14,0,0,0-.16-.12H3.85c-.14,0-.16.04-.18.16C2.35-4.86,2.01-3.04,1.85-2.34H1.81c-.14-.8-.44-2.7-1.64-7.4-.02-.1-.06-.12-.16-.12h-2.2c-.12,0-.18.06-.14.16L.27-.16C.31-.04.33,0,.53,0H2.71a.2.2,0,0,0,.22-.16C3.87-3.48,4.37-5.4,4.83-7.4h.02c.2.98.54,2.76,1.7,7.22.04.12.08.18.22.18Z"
        transform="translate(-2684.5 -12758.84) rotate(-90)"
        fill="#5c76aa"
      />
    </g>
    <g id="HWl1" data-name="Group 17682" transform="translate(2739.14 13598)">
      <g id="Group_17499" data-name="Group 17499" transform="translate(-1131)">
        <circle
          id="Ellipse_2"
          data-name="Ellipse 2"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1566 -12575)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_3"
          data-name="Ellipse 3"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1566 -13211)"
          fill="#5c76aa"
        />
        <path
          id="Path_12115"
          data-name="Path 12115"
          d="M-6145.63-17993.666v636.387"
          transform="translate(4587.13 4790.166)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12223"
        data-name="Path 12223"
        d="M-30.92,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16H-41c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16Zm11.8-12.98c-.04-.12-.06-.16-.16-.16H-21.6a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-25.66-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C-17.38-5.66-18.34-9.68-19.12-12.98Zm8.3,10.6C-10.82-.28-9.78.2-8.5.2A2.916,2.916,0,0,0-7.36.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16h-2.06c-.12,0-.18.06-.18.16ZM-1.92,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H-3.28a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(-2697 -13197)"
        fill="#5c76aa"
      />
    </g>
    <g id="HTl2" data-name="Group 17685" transform="translate(2739.14 13598)">
      <g id="Group_17501" data-name="Group 17501" transform="translate(-1240)">
        <circle
          id="Ellipse_6"
          data-name="Ellipse 6"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1457 -12575)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_7"
          data-name="Ellipse 7"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1457 -13011)"
          fill="#5c76aa"
        />
        <path
          id="Path_12117"
          data-name="Path 12117"
          d="M-6145.63-17993.664v432.52"
          transform="translate(4696 4994.164)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12224"
        data-name="Path 12224"
        d="M-27.38,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14H-29.5a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16Zm5.84-.18c0,.14.04.2.18.18h2.06c.1,0,.16-.06.16-.18V-10.96h4.04c.1,0,.16-.06.14-.18l-.18-1.82c-.02-.14-.08-.18-.2-.18h-9.94c-.12,0-.16.04-.18.16l-.26,1.86c-.02.1.02.16.12.16h4.06Zm7.6-2.2C-13.94-.28-12.9.2-11.62.2A2.916,2.916,0,0,0-10.48.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16h-2.06c-.12,0-.18.06-.18.16ZM-1.16,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14H-3c-1.42,0-2.18,0-2.94.06A23.064,23.064,0,0,0-3.46-4.18,6.132,6.132,0,0,0-1.2-8.8a3.916,3.916,0,0,0-4.3-4.06A7.391,7.391,0,0,0-9.02-12a.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02A42.211,42.211,0,0,1-9.34-1.74c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(-2701 -12998)"
        fill="#5c76aa"
      />
    </g>
    <g id="HTl1" data-name="Group 17686" transform="translate(2739.14 13598)">
      <g id="Group_17502" data-name="Group 17502" transform="translate(-1274)">
        <circle
          id="Ellipse_8"
          data-name="Ellipse 8"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1423 -12575)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_9"
          data-name="Ellipse 9"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1423 -12955)"
          fill="#5c76aa"
        />
        <path
          id="Path_12118"
          data-name="Path 12118"
          d="M-6145.63-17993.666v376.512"
          transform="translate(4730 5050.166)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12225"
        data-name="Path 12225"
        d="M-24.26,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08H-32.1v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16Zm5.84-.18c0,.14.04.2.18.18h2.06c.1,0,.16-.06.16-.18V-10.96h4.04c.1,0,.16-.06.14-.18l-.18-1.82c-.02-.14-.08-.18-.2-.18h-9.94c-.12,0-.16.04-.18.16l-.26,1.86c-.02.1.02.16.12.16h4.06Zm7.6-2.2C-10.82-.28-9.78.2-8.5.2A2.916,2.916,0,0,0-7.36.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16h-2.06c-.12,0-.18.06-.18.16ZM-1.92,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H-3.28a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(-2702 -12941)"
        fill="#5c76aa"
      />
    </g>
    <g id="HKl" data-name="Group 17689" transform="translate(2739.14 13598)">
      <g id="Group_17503" data-name="Group 17503" transform="translate(-1314)">
        <circle
          id="Ellipse_10"
          data-name="Ellipse 10"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1383 -12575)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_11"
          data-name="Ellipse 11"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1383 -12858)"
          fill="#5c76aa"
        />
        <path
          id="Path_12119"
          data-name="Path 12119"
          d="M-6145.63-17993.664v276.512"
          transform="translate(4770.241 5150.164)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12226"
        data-name="Path 12226"
        d="M-18.92,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16H-29c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16Zm7.98-6.76a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2H-8a.262.262,0,0,0-.24.12L-13.38-7.1v-5.88c0-.12-.04-.16-.16-.16h-2.1c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18h2.08c.12,0,.16-.04.16-.18V-6.1L-8.16-.16A.354.354,0,0,0-7.82,0h2.54a.129.129,0,0,0,.1-.22Zm6.9,4.38C-4.04-.28-3,.2-1.72.2A2.916,2.916,0,0,0-.58.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H-3.86c-.12,0-.18.06-.18.16Z"
        transform="translate(-2702 -12844)"
        fill="#5c76aa"
      />
    </g>
    <g id="HFl" data-name="Group 17690" transform="translate(2739.14 13598)">
      <g id="Group_17504" data-name="Group 17504" transform="translate(-1350)">
        <circle
          id="Ellipse_12"
          data-name="Ellipse 12"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1347 -12575)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_13"
          data-name="Ellipse 13"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1347 -12818)"
          fill="#5c76aa"
        />
        <path
          id="Path_12120"
          data-name="Path 12120"
          d="M-6145.63-17993.666v237.26"
          transform="translate(4806 5189.166)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12227"
        data-name="Path 12227"
        d="M-16.94,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16Zm5.54-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16h-7.72c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16Zm7.36,2.92C-4.04-.28-3,.2-1.72.2A2.916,2.916,0,0,0-.58.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H-3.86c-.12,0-.18.06-.18.16Z"
        transform="translate(-2703 -12805)"
        fill="#5c76aa"
      />
    </g>
    <g id="HCl2" data-name="Group 17694" transform="translate(3240.14 13598)">
      <g id="Group_17505" data-name="Group 17505" transform="translate(-1884)">
        <circle
          id="Ellipse_14"
          data-name="Ellipse 14"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1314 -12575)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_15"
          data-name="Ellipse 15"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1314 -12751)"
          fill="#5c76aa"
        />
        <path
          id="Path_12121"
          data-name="Path 12121"
          d="M-6145.63-17993.664v174.875"
          transform="translate(4839 5252.164)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12228"
        data-name="Path 12228"
        d="M-27.9,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16Zm12.12-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-23.04-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9a.212.212,0,0,0-.12-.22,6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8,0,4.56,3.16,6.74,6.86,6.74a8.624,8.624,0,0,0,2.76-.36.254.254,0,0,0,.14-.26Zm1.84-.18C-13.94-.28-12.9.2-11.62.2A2.916,2.916,0,0,0-10.48.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16h-2.06c-.12,0-.18.06-.18.16ZM-1.16,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14H-3c-1.42,0-2.18,0-2.94.06A23.064,23.064,0,0,0-3.46-4.18,6.132,6.132,0,0,0-1.2-8.8a3.916,3.916,0,0,0-4.3-4.06A7.391,7.391,0,0,0-9.02-12a.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02A42.211,42.211,0,0,1-9.34-1.74c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(-3202 -12736)"
        fill="#5c76aa"
      />
    </g>
    <g id="HCl" data-name="Group 17696" transform="translate(3284.14 13598)">
      <g id="Group_17506" data-name="Group 17506" transform="translate(-1965)">
        <circle
          id="Ellipse_16"
          data-name="Ellipse 16"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1277 -12575)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_17"
          data-name="Ellipse 17"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1277 -12670)"
          fill="#5c76aa"
        />
        <path
          id="Path_12122"
          data-name="Path 12122"
          d="M-6145.63-17993.664v87.707"
          transform="translate(4876 5339.164)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12229"
        data-name="Path 12229"
        d="M-18,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18H-26c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM-5.88-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-13.14-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0-5.88-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-15.64-1.98-12.48.2-8.78.2A8.624,8.624,0,0,0-6.02-.16a.254.254,0,0,0,.14-.26Zm1.84-.18C-4.04-.28-3,.2-1.72.2A2.916,2.916,0,0,0-.58.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H-3.86c-.12,0-.18.06-.18.16Z"
        transform="translate(-3247 -12656)"
        fill="#5c76aa"
      />
    </g>
    <g id="HAl" data-name="Group 17699" transform="translate(3334.14 13598)">
      <g id="Group_17517" data-name="Group 17517" transform="translate(-595)">
        <g
          id="Group_17507"
          data-name="Group 17507"
          transform="translate(-1446)"
        >
          <circle
            id="Ellipse_18"
            data-name="Ellipse 18"
            cx="7.5"
            cy="7.5"
            r="7.5"
            transform="translate(-1251 -12575)"
            fill="#5c76aa"
          />
          <circle
            id="Ellipse_19"
            data-name="Ellipse 19"
            cx="7.5"
            cy="7.5"
            r="7.5"
            transform="translate(-1251 -12642)"
            fill="#5c76aa"
          />
          <path
            id="Path_12123"
            data-name="Path 12123"
            d="M-6145.63-17993.666v63.447"
            transform="translate(4902 5363.166)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeWidth="5"
          />
        </g>
        <path
          id="Path_12230"
          data-name="Path 12230"
          d="M-18.92,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16H-29c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16Zm9.88-3.46L-7.88-.12a.2.2,0,0,0,.2.12h2.24c.12,0,.14-.06.12-.18L-9.94-13.02c-.02-.1-.04-.12-.14-.12h-2.78c-.08,0-.12.06-.12.14a3.058,3.058,0,0,1-.18,1.08L-17.28-.2c-.02.14.02.2.14.2h2a.2.2,0,0,0,.22-.16l1.1-3.3Zm-4.14-2.18c.6-1.82,1.4-4.16,1.72-5.5h.02c.4,1.4,1.34,4.18,1.76,5.5Zm9.14,3.26C-4.04-.28-3,.2-1.72.2A2.916,2.916,0,0,0-.58.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H-3.86c-.12,0-.18.06-.18.16Z"
          transform="translate(-2702 -12626)"
          fill="#5c76aa"
        />
      </g>
    </g>
    <g id="CCl" data-name="Group 17695" transform="translate(3042.48 13598)">
      <g
        id="Group_17498-11"
        data-name="Group 17498"
        transform="translate(-1079 162.289)"
      >
        <circle
          id="Ellipse_20-11"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1594.823 -12902.688)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-11"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -13218)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-11"
          data-name="Path 12124"
          d="M-6167.58-17989.687c4.472,115.848,12.787,113.277,14.782,206.992.933,43.84,5.163,75.418,8.506,104.785"
          transform="translate(4557 4781.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12235"
        data-name="Path 12235"
        d="M-3.23-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-10.49-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0-3.23-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-12.99-1.98-9.83.2-6.13.2A8.624,8.624,0,0,0-3.37-.16a.254.254,0,0,0,.14-.26Zm11.06,0c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1,.57-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0,7.83-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-1.93-1.98,1.23.2,4.93.2A8.624,8.624,0,0,0,7.69-.16a.254.254,0,0,0,.14-.26Zm1.84-.18C9.67-.28,10.71.2,11.99.2A2.916,2.916,0,0,0,13.13.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H9.85c-.12,0-.18.06-.18.16Z"
        transform="translate(-2606.715 -12727)"
        fill="#5c76aa"
      />
    </g>
    <g id="CKl" data-name="Group 17692" transform="translate(3042.48 13598)">
      <g
        id="Group_17498-12"
        data-name="Group 17498"
        transform="translate(-1079 162.289)"
      >
        <circle
          id="Ellipse_20-12"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1602.292 -13004.688)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-12"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -13218)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-12"
          data-name="Path 12124"
          d="M-6167.58-17989.687c4.472,115.848,12.787,113.277,14.782,206.992"
          transform="translate(4557 4781.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12236"
        data-name="Path 12236"
        d="M-3.73-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-10.99-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0-3.73-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-13.49-1.98-10.33.2-6.63.2A8.624,8.624,0,0,0-3.87-.16a.254.254,0,0,0,.14-.26Zm7-4.56a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2H6.21a.262.262,0,0,0-.24.12L.83-7.1v-5.88c0-.12-.04-.16-.16-.16h-2.1c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18H.67C.79,0,.83-.04.83-.18V-6.1L6.05-.16A.354.354,0,0,0,6.39,0H8.93a.129.129,0,0,0,.1-.22Zm6.9,4.38C10.17-.28,11.21.2,12.49.2A2.916,2.916,0,0,0,13.63.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H10.35c-.12,0-.18.06-.18.16Z"
        transform="translate(-2602.715 -12829)"
        fill="#5c76aa"
      />
    </g>
    <g id="WAl" data-name="Group 17824" transform="translate(3013.869 13598)">
      <g
        id="Group_17498-13"
        data-name="Group 17498"
        transform="translate(-2697 -13218)"
      >
        <circle
          id="Ellipse_20-13"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 51.016)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-13"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-13"
          data-name="Path 12124"
          d="M-6145.63-17993.662v53.352"
          transform="translate(6153 17999)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12240"
        data-name="Path 12240"
        d="M-6.83-12.98c-.04-.12-.06-.16-.16-.16H-9.31a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-13.37-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L.03-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-3.81-6.7-4.33-4.04-4.53-2.92h-.02C-5.09-5.66-6.05-9.68-6.83-12.98ZM7.85-3.46,9.01-.12a.2.2,0,0,0,.2.12h2.24c.12,0,.14-.06.12-.18L6.95-13.02c-.02-.1-.04-.12-.14-.12H4.03c-.08,0-.12.06-.12.14a3.058,3.058,0,0,1-.18,1.08L-.39-.2c-.02.14.02.2.14.2h2a.2.2,0,0,0,.22-.16l1.1-3.3ZM3.71-5.64c.6-1.82,1.4-4.16,1.72-5.5h.02c.4,1.4,1.34,4.18,1.76,5.5Zm9.14,3.26C12.85-.28,13.89.2,15.17.2A2.916,2.916,0,0,0,16.31.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.03c-.12,0-.18.06-.18.16Z"
        transform="translate(-2657 -13156.984)"
        fill="#5c76aa"
      />
    </g>
  </>,
];

export default MaleFront;
