import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

import AuthLayout from "../../../Layouts/AuthLayout";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "../../../Components/TextField/TextField";
import Button from "../../../Components/Button2";

function OTPUpdate() {
  const { currentUser, updateUserPasswordOTP, logout } = useAuth();
  const [tmpUser, setTmpUser] = useState<any>({
    password: null,
    confirmPassword: null,
  });
  const { t }: { t: any } = useTranslation("auth_new");
  let navigate = useNavigate();

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (tmpUser.password !== tmpUser.confirmPassword) {
      return;
    }

    await updateUserPasswordOTP({
      user: currentUser,
      newPassword: tmpUser.password,
    }).then((res: any) => {
      if (res === 200) {
        logout();
        navigate("/otpupdatesuccess");
      }
    });
  };

  useEffect(() => {
    if (!currentUser) {
      navigate("/signin");
    }
  }, [currentUser]);

  return (
    <>
      <AuthLayout
        heading={t("first_login")}
        onSubmit={onSubmit}
        id="first_login"
      >
        <Typography paragraph sx={{ textAlign: "center" }}>
          {t("this_is_your")}
        </Typography>
        <Typography paragraph sx={{ textAlign: "center" }}>
          {t("please_update")}
        </Typography>
        <TextField
          required
          password
          setFunction={setTmpUser}
          userData={tmpUser}
          dataIndex="password"
          placeholder={t("new_password")}
        />
        <TextField
          required
          password
          setFunction={setTmpUser}
          userData={tmpUser}
          dataIndex="confirmPassword"
          placeholder={t("confirm_password")}
        />
        <Box component="div" sx={{ my: 2 }}>
          <Button
            type="submit"
            form="first_login"
            fullWidth
            disabled={
              !Boolean(
                tmpUser.password &&
                  tmpUser.password === tmpUser.confirmPassword &&
                  tmpUser.password.length >= 6
              )
            }
          >
            {t("submit")}
          </Button>
        </Box>
      </AuthLayout>
    </>
  );
}

export default OTPUpdate;
