const fonts = [
  "Ubuntu",
  "-apple-system",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Rajdhani",
];

export default fonts;
