import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";

function AuthOutlet() {
  return (
    <Box component="main" sx={{ minHeight: "100vh" }}>
      <Outlet />
    </Box>
  );
}

export default AuthOutlet;
