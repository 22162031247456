import React from "react";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface Props {
  handleOpen: any;
  open: boolean;
}

function MinimizeButton({ handleOpen, open }: Props) {
  return (
    <>
      <IconButton
        size="small"
        sx={{
          display: { xs: "none", md: "flex" },
          background: "white",
          border: "1px solid var(--grey-inactive)",
          position: "absolute",
          right: "-28px",
          top: "-20px",
          transform: open ? "rotate(180deg)" : null,
          "&:hover": {
            background: "rgba(241, 241, 241, 1)",
          },
        }}
        onClick={() => handleOpen()}
      >
        <ChevronLeftIcon />
      </IconButton>
    </>
  );
}

export default MinimizeButton;
