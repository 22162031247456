import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../Context/AuthContext";
import { editUser } from "../../../Services/Users/Users";
import copy from "../../../Helpers/copy";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";

import ProfileHeader from "./ProfileHeader";
import Accordion from "./Accordion";

import AboutThisContact from "./AboutThisContact";
import TrialAppPermissions from "./TrialAppPermissions";
import APIKey from "./APIKey";
import AppPermissions from "./AppPermissions";
import BrandSection, { Brand } from "./BrandSection";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { Portal } from "@mui/base/Portal";
import Alert from "@mui/material/Alert";

interface Props {
  userData: any;
  userModalOpen: boolean;
  setUserModalOpen: any;
  fetchUsers: any;
  brands: any;
}

// TODO:
// mockups don't contain a 'submit' or 'cancel' button but
// there should be on the bottom

function UserDrawer({
  userData,
  userModalOpen,
  setUserModalOpen,
  fetchUsers,
  brands
}: Props) {
  const { currentUser } = useAuth();
  const { t } = useTranslation("general");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editedData, setEditedData] = useState<any>(copy(userData));
  const [toastLoading, setToastLoading] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<string | null>(null);
  const containerRef = useRef(null);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleEditedData = (
    protocol_type: string,
    index: string,
    value: Date | string | number | any,
    is_trial: boolean = true
  ) => {
    let state_copy = copy(editedData);
    if (protocol_type === "permissions") {
      state_copy.permissions[index] = value
    } else if (protocol_type === "brand") {
      state_copy.brand = value;
    } else {
      if (is_trial) {
        state_copy.trial_app_permissions.map((i: any) => {
          if (i.protocol_type === protocol_type) {
            return (i[index] = dayjs(value).utc(true));
          }
        });
      } else {
        state_copy.permissions.bodyparts.map((i: any) => {
          if (i.protocol_type === protocol_type) {
            if (value instanceof Date) {
              return (i[index] = dayjs(value).utc(true));
            } else {
              return (i[index] = value)
            }
          }
        });
      }
    }
    setEditedData(state_copy);
  };

  const resetPermission = (protocol_type: string, is_trial: boolean = true) => {
    let state_copy = copy(editedData);
    if (is_trial) {
      state_copy?.trial_app_permissions
        .map((i: any) => {
          if (i?.protocol_type === protocol_type) {
            return (i.start_date = null);
          }
        })
        .map((i: any) => {
          if (i?.protocol_type === protocol_type) {
            return (i.end_date = null);
          }
        });
    } else {
      state_copy?.permissions?.bodyparts
        .map((i: any) => {
          if (i?.protocol_type === protocol_type) {
            return (i.start_date = null);
          }
        })
        .map((i: any) => {
          if (i?.protocol_type === protocol_type) {
            return (i.end_date = null);
          }
        })
        .map((i: any) => {
          if (i?.protocol_type === protocol_type) {
            return (i.captures_allowed = 0);
          }
        });
    }
    setEditedData(state_copy);
  };

  const submitEditedData = async () => {
    let token = await currentUser.getIdToken();
    let uid = userData.uid;
    setToastLoading(true);
    await editUser({ token: token, uid: uid, tmpUser: editedData })
      .then((res: any) => {
        if (res.status === 200) {
          setToastLoading(false);
          setEditedData(res.data);
        } else {
          setToastStatus(res.error);
        }
      }
    );
  };

  const handleReset = () => {
    let state_copy = copy(userData);
    setEditedData(state_copy);
  };

  const handleClose = () => {
    fetchUsers();
    setUserModalOpen(false);
  };
  
  return (
    <>
      <Portal>
        <Snackbar
          open={toastLoading}
          autoHideDuration={5000}
          onClose={() =>  {
            setToastLoading(false);
            setToastStatus(null);
          }}
        >
          <Alert icon={false} severity={toastStatus ? "error" : "success"}>
            {toastStatus ? "Error" : "Success"}
          </Alert>
        </Snackbar>
      </Portal>
      <Drawer
        open={userModalOpen}
        onClose={() => handleClose()}
        anchor="right"
        sx={{
          justifyContent: "space-apart",
        }}
      >
        <Box
          component="div"
          ref={containerRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            minWidth: { xs: "none", md: "629px" },
          }}
        >
          <Box component="div" sx={{}}>
            <ProfileHeader
              username={`${userData?.first_name} ${userData?.last_name}`}
              handleEditMode={handleEditMode}
              editMode={editMode}
              setUserModalOpen={setUserModalOpen}
            />
            <Accordion header="About this contact" defaultExpanded>
              <AboutThisContact
                email={userData.email}
                company={userData.company}
              />
            </Accordion>
            <Accordion header="Brand">
              <BrandSection
                key={editedData} 
                brand={brands.find( (value: Brand) => value?.name === editedData?.brand)}
                brands={brands}
                editMode={editMode}
                handleEditedData={handleEditedData}
              />
            </Accordion>
            <Accordion header="Trial App Permissions">
              <TrialAppPermissions
                key={editedData}
                trial_app_permissions={editedData?.trial_app_permissions}
                editMode={editMode}
                handleEditedData={handleEditedData}
                resetPermission={resetPermission}
              />
            </Accordion>
            <Accordion header="App Permissions">
              <AppPermissions
                key={editedData}
                app_permissions={editedData?.permissions}
                editMode={editMode}
                handleEditedData={handleEditedData}
                resetPermission={resetPermission}
              />
            </Accordion>
            <Accordion header="API Key">
              <APIKey
                uid={userData.uid}
                key_status={userData?.api_key_status}
                userModalOpen={userModalOpen}
              />
            </Accordion>
          </Box>
          <Slide direction="up" in={editMode} container={containerRef.current}>
            <Paper
              square
              elevation={4}
              sx={{
                width: "100%",
                background: "#fdfdfd",
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  p: 2,
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  disabled={!editMode}
                  onClick={() => handleReset()}
                >
                  Undo
                </Button>
                <Button
                  variant="contained"
                  disabled={!editMode}
                  sx={{ color: "white" }}
                  onClick={() => submitEditedData()}
                >
                  Submit
                </Button>
              </Box>
            </Paper>
          </Slide>
        </Box>
      </Drawer>
    </>
  );
}

export default UserDrawer;
