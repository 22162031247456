import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { getUser } from "../../Services/Users/Users";
import checkAllSelected from "../../Helpers/checkAllSelected";
import forwardCompatible from "../../Helpers/forwardCompatible";

import newMeasures from "../../mockData/measuresWholeBody";

import Box from "@mui/material/Box";
import NavDrawer from "../../Components/NavDrawer";

const DefaultLayout = () => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState(newMeasures);
  const [gender, setGender] = useState("Female");
  const [allToggleInitial, setAllToggle] = useState<any[]>([
    { heading: "Hand", circumference: true, length: true },
    { heading: "Lower Body", circumference: true, length: true },
    { heading: "Foot", circumference: true, length: true },
    { heading: "Upper Body", circumference: true, length: true },
    { heading: "Head", circumference: true, length: true },
    { heading: "Other", circumference: true, length: true },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const token = await currentUser?.getIdToken();
      await getUser({ token: token, uid: currentUser?.uid }).then(
        (res: any) => {
          if (res && res?.configuration) {
            let checkedData = forwardCompatible(res.configuration, newMeasures);
            setFormData(checkedData);

            setAllToggle([
              {
                heading: "Hand",
                circumference: checkAllSelected(
                  "Hand",
                  "circumference",
                  checkedData
                ),
                length: checkAllSelected("Hand", "length", checkedData),
              },
              {
                heading: "Lower Body",
                circumference: checkAllSelected(
                  "Lower Body",
                  "circumference",
                  checkedData
                ),
                length: checkAllSelected("Lower Body", "length", checkedData),
              },
              {
                heading: "Foot",
                circumference: checkAllSelected(
                  "Foot",
                  "circumference",
                  checkedData
                ),
                length: checkAllSelected("Foot", "length", checkedData),
              },
              {
                heading: "Other",
                circumference: checkAllSelected(
                  "Other",
                  "circumference",
                  checkedData
                ),
                length: checkAllSelected("Other", "length", checkedData),
              },
              {
                heading: "Upper Body",
                circumference: checkAllSelected(
                  "Upper Body",
                  "circumference",
                  checkedData
                ),
                length: checkAllSelected("Upper Body", "length", checkedData),
              },
              {
                heading: "Head",
                circumference: checkAllSelected(
                  "Head",
                  "circumference",
                  checkedData
                ),
                length: checkAllSelected("Head", "length", checkedData),
              },
            ]);
          } else {
            return;
          }
        }
      );
    };

    fetchData().catch(console.error);
  }, [currentUser]);

  return (
    <Box
      component="div"
      sx={{
        minHeight: "100vh",
        display: "flex",
        background: "white",
      }}
    >
      <NavDrawer />
      <Box
        component="div"
        sx={{
          flex: "1",
          m: 2,
          pt: { xs: 4, md: 0 },
        }}
      >
        <main>
          <Outlet
            context={[
              formData,
              setFormData,
              allToggleInitial,
              gender,
              setGender,
            ]}
          />
        </main>
      </Box>
    </Box>
  );
};

export default DefaultLayout;
