import React, { useState } from "react";
import { useAuth } from "../../../Context/AuthContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../../Layouts/AuthLayout";
import styled from "@emotion/styled";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MUIButton from "@mui/material/Button";

import Button from "../../../Components/Button2";
import TextField from "../../../Components/TextField";
import Toast from "../../../Components/Toast";

const ForgotPassword = () => {
  const [userData, setUserData] = useState<any>({ email: null });
  const [toastLoading, setToastLoading] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<string | null>(null);
  const [status, setStatus] = useState<number | null>(null);
  const { t }: { t: any } = useTranslation("auth_new");
  const { resetPassword } = useAuth();

  const onSubmit = async (e: React.SyntheticEvent) => {
    setToastLoading(true);
    e.preventDefault();

    await resetPassword(userData.email).then((res: any) => {
      if (res === 200) {
        setToastLoading(false);
        setStatus(res);
      } else {
        setToastStatus(res);
        setTimeout(() => {
          setToastLoading(false);
          setToastStatus(null);
        }, 2500);
      }
    });
  };

  return (
    <>
      <Toast
        warning={toastStatus !== null}
        loading={toastLoading}
        message={
          toastStatus === "auth/user-not-found"
            ? t("user_not_found")
            : t("loading")
        }
      />
      {status === 200 ? (
        <AuthLayout heading={t("forgot_password")}>
          <Typography paragraph sx={{ textAlign: "center" }}>
            {t("thank_you_link")}
          </Typography>
          <Grid2
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              my: 4,
            }}
          >
            <MUIButton
              component="a"
              href="/"
              fullWidth
              variant="contained"
              sx={{ color: "white" }}
            >
              {t("go_to_login")}
            </MUIButton>
          </Grid2>
        </AuthLayout>
      ) : (
        <AuthLayout
          onSubmit={onSubmit}
          id="forgot"
          heading={t("forgot_password")}
        >
          <Typography paragraph sx={{ textAlign: "center", mb: 2 }}>
            {t("please_enter_your")}
          </Typography>
          <TextField
            placeholder={t("email_address")}
            inputType="email"
            setFunction={setUserData}
            userData={userData}
            dataIndex="email"
          />
          <MUIButton
            type="submit"
            form="forgot"
            fullWidth
            variant="contained"
            disabled={!userData.email}
            sx={{ mb: 2, color: "white" }}
          >
            {t("send")}
          </MUIButton>
          <Typography
            variant="caption"
            sx={{ textAlign: "center", display: "block" }}
          >
            {t("no_account")}{" "}
            <Typography
              variant="caption"
              component="a"
              href="https://www.vyoo.ai/request-a-demo/"
              target="_blank"
              sx={{
                cursor: "pointer",
                transition: "all var(--transition-animation)",
                "&:hover": {
                  color: "var(--primary-color)",
                },
              }}
            >
              {t("request_a_demo")}
            </Typography>
          </Typography>
        </AuthLayout>
      )}
    </>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default ForgotPassword;
