import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Icon from "../Icon";

interface Props {
  location: string;
  to: string;
  label: string;
  icon:
    | "home"
    | "clients"
    | "bodyMeasures"
    | "captureDashboard"
    | "sizingTool"
    | "signOut"
    | "swaggerui";
  disabled: boolean;
  mobileOpen: boolean;
}

// TODO:
// Add translations for each label, pass to t()

function NavLink({ location, to, label, icon, disabled, mobileOpen }: Props) {
  const { t } = useTranslation("general");

  return (
    <Link
      component={RouterLink}
      to={to}
      sx={{
        textDecoration: "none",
        display: "flex",
        padding: { xs: mobileOpen ? "12px" : "0px", md: "12px" },
        border: "1px solid",
        pointerEvents: disabled ? "none" : null,
        overflow: "hidden",
        width: { xs: mobileOpen ? "100%" : "0px", md: "100%" },
        transition: "all var(--transition-animation)",
        borderColor: `${
          disabled
            ? "var(--bg-grey)"
            : location === to
            ? "var(--secondary-color)"
            : "transparent"
        }`,
        borderRadius: "6px",
        color: `${
          disabled
            ? "var(--grey-inactive)"
            : location === to
            ? "var(--secondary-color)"
            : "var(--hover-grey)"
        }`,
        background: `${
          disabled
            ? "var(--bg-grey)"
            : location === to
            ? "var(--primary-20)"
            : null
        }`,
        "&:hover": {
          background: "var(--primary-20)",
        },
        svg: {
          path: {
            stroke: `${
              disabled
                ? "var(--grey-inactive)"
                : location === to
                ? "var(--secondary-color)"
                : null
            }`,
          },
        },
      }}
    >
      <Icon icon={icon} />
      <Typography sx={{ ml: 1, fontWeight: "500", color: "inherit" }}>
        {t(label)}
      </Typography>
    </Link>
  );
}

export default NavLink;
