import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

import AuthLayout from "../../../Layouts/AuthLayout";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Toast from "../../../Components/Toast";
import TextField from "../../../Components/TextField";
import MUIButton from "@mui/material/Button";

const SignIn = () => {
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [toastLoading, setToastLoading] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<string | null>(null);
  const [userSignInData, setUserSignInData] = useState<any>({
    email: null,
    password: null,
  });
  const { t }: { t: any } = useTranslation("auth_new");
  const { signIn, userData } = useAuth();

  let navigate = useNavigate();

  const onSubmit = async (e: React.SyntheticEvent) => {
    setToastLoading(true);
    e.preventDefault();
    await signIn(
      userSignInData.email,
      userSignInData.password,
      rememberMe
    ).then((res: any) => {
      if (res.status === 200) {
        if (res.data?.first_login) {
          navigate("/");
        } else {
          navigate("/otpupdate");
        }
        setTimeout(() => {
          setToastLoading(false);
        }, 2000);
      } else {
        setToastStatus(res);
        setTimeout(() => {
          setToastLoading(false);
          setToastStatus(null);
        }, 2500);
      }
    });
  };

  const handleCheck = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <>
      <Toast
        warning={toastStatus !== null}
        loading={toastLoading}
        message={toastStatus !== null ? t("wrong_password") : t("loading")}
      />
      <AuthLayout heading={t("login")} onSubmit={onSubmit} id="login">
        <Typography sx={{ textAlign: "center" }}>
          {t("welcome_back")}
        </Typography>
        <Typography paragraph sx={{ textAlign: "center", pb: 2 }}>
          {t("please_enter_details")}
        </Typography>
        <Box component="div" sx={{ mb: 2 }}>
          <TextField
            inputType="email"
            setFunction={setUserSignInData}
            userData={userSignInData}
            dataIndex="email"
            placeholder={t("email_address")}
          />
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography
            variant="caption"
            component="a"
            href="/resetpassword"
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              transition: "all var(--transition-animation)",
              "&:hover": {
                color: "var(--primary-color)",
              },
            }}
          >
            {t("forgot_your_password")}
          </Typography>
          <TextField
            inputType="password"
            setFunction={setUserSignInData}
            userData={userSignInData}
            dataIndex="password"
            placeholder={t("password")}
            password
          />
        </Box>
        <Grid2
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            my: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                size="medium"
                checked={rememberMe}
                onChange={handleCheck}
              />
            }
            label={t("remember_me")}
            sx={{ mb: 2, ".MuiTypography-root": { fontSize: "0.9375rem" } }}
          />
          <MUIButton
            type="submit"
            form="login"
            fullWidth
            variant="contained"
            disabled={!userSignInData.email || !userSignInData.password}
            sx={{ mb: 2, color: "white" }}
          >
            {t("sign_in")}
          </MUIButton>
          <Typography variant="caption">
            {t("no_account")}{" "}
            <Typography
              variant="caption"
              component="a"
              href="https://www.vyoo.ai/request-a-demo/"
              target="_blank"
              sx={{
                cursor: "pointer",
                transition: "all var(--transition-animation)",
                "&:hover": {
                  color: "var(--primary-color)",
                },
              }}
            >
              {t("request_a_demo")}
            </Typography>
          </Typography>
        </Grid2>
      </AuthLayout>
    </>
  );
};

export default SignIn;
