import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Logo from "../Logo";

interface Props {
  heading: string;
  children: any;
  comingSoon?: boolean;
}

const AuthCard = ({ heading, children, comingSoon }: Props) => {
  return (
    <Grid item xs={12} sm={8} md={6} lg={4}>
      <Box
        component="div"
        sx={{
          borderRadius: "16px 16px 0 0",
          background: comingSoon ? "#F7F7F7" : "white",
          px: 4,
          pt: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          component="div"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {comingSoon ? null : <Logo height="43px" />}
          <Typography
            component="h1"
            variant={comingSoon ? "h4" : "body1"}
            sx={{
              textAlign: "center",
              my: comingSoon ? null : 1,
              mb: comingSoon ? 1 : null,
            }}
          >
            {heading}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ borderColor: "transparent" }} />
      <Box
        component="div"
        sx={{
          borderRadius: "0 0 16px 16px",
          background: comingSoon ? "#F7F7F7" : "white",
          px: 4,
          py: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          component="div"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

export default AuthCard;
