import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Button from "../Button2";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getAllFws, getFwUrl } from "../../Services/Framework/Frameworks";
import { useAuth } from "../../Context/AuthContext";
import Dialog from "@mui/material/Dialog";
import CopyButton from "../CopyButton";

function Framework() {
  const { currentUser } = useAuth();
  const { t }: { t: any } = useTranslation("signup");
  const [fwPath, setFwPath] = useState<string>("");
  const [fwUrl, setFwUrl] = useState<string>("");
  const [fwList, setFwList] = useState<[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let token = await currentUser.getIdToken();
    getFwUrl({path: fwPath, token}).then((res: any) => {
      setFwUrl(res);
      setModalOpen(true);
    });
  };

  const fetchFwVersions = async () => {
    let token = await currentUser.getIdToken();
    let response = await getAllFws(token);
    setFwList(response);
  };

  useEffect(() => {
    fetchFwVersions();
  }, []);
  

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    setFwPath(value);
    setDisabled(false);
  };

  return (
    <>
      <Dialog fullWidth={ true } open={modalOpen} onClose={() => setModalOpen(false)}>
         <Box 
          component="div" 
          sx={{
            padding: "1em",
            overflow: "hidden",
            
          }}
        >
          <CopyButton 
            text={fwUrl} 
            styleProp={
              {
                position: "absolute", 
                top: 0, 
                right: 0,
                cursor: "pointer"
              }
            }
          />
          <Box
            component="span"
            sx={{
              display: 'block',
              p: 1,
              m: 1,
              overflowWrap: "break-word"
            }}
          >
            {fwUrl}
          </Box>
        </Box>
      </Dialog>
      <Box component="div" sx={{ mx: 6, mb: 8 }}>
        <Typography
          variant="h1"
          sx={{
            mb: 4,
            fontSize: "3.438rem",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Create Framework Download URL
        </Typography>
        <Box
          component="form"
          id="get_fw_download_url"
          onSubmit={(e: any) => onSubmit(e)}
          sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="framework-label">Version</InputLabel>
            <Select
              labelId="framework-label"
              id="framework-select"
              value={fwPath}
              label="Version"
              onChange={handleChange}
            >
              {fwList?.map(item => (
                <MenuItem key={item} value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            fullWidth
            type="submit"
            form="get_fw_download_url"
            disabled={disabled}
            onClick={(e: React.SyntheticEvent) => onSubmit(e)}
          >
            {t("submit")}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Framework;
