/**
 *
 * @param incoming Incoming sesponse data
 * @param local The ideal form with proper headings etc
 * @returns Form whose selected/checked are taken from incoming but are matched with and modify the local version
 */
const forwardCompatible = (incoming: any[], local: any[]) => {
  let newData = local.filter((i: any) => {
    return i.data.filter((locData: any) => {
      incoming.filter((f: any) => {
        f.data.filter((incData: any) => {
          if (incData.id === locData.id) {
            locData.selected = incData.selected;
            locData.checked = incData.checked;
          }
        });
      });
    });
  });
  return newData;
};

export default forwardCompatible;
