import React from "react";
import SelectionButton from "../../BodySelections/SelectionButton";
import styled from "@emotion/styled";

interface Props {
  data: any;
  handleClick: any;
  heading: string;
}

const CarouselItem = ({ data, handleClick, heading }: Props) => {
  return (
    <>
      <GridContainer>
        {data.map((i: any, index: number) => {
          return (
            <GridItem key={index}>
              <SelectionButton
                label={i.title}
                checked={i.checked}
                handleClick={handleClick}
                heading={heading}
                id={i.id}
                type={i.type}
              />
            </GridItem>
          );
        })}
      </GridContainer>
    </>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  width: 100%;

  @media screen and (min-width: 900px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const GridItem = styled.div`
  grid-column: span 6;
  display: block;
  box-sizing: border-box;

  @media screen and (min-width: 800px) {
    grid-column: span 3;
  }

  @media screen and (min-width: 1200px) {
    grid-column: span 4;
  }

  @media screen and (min-width: 1536px) {
    grid-column: span 2;
  }
`;

export default CarouselItem;
