/* eslint-disable camelcase */
import { GridFilterItem, GridSortItem } from "@mui/x-data-grid";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RejectError } from "../../../api/apiError";
import jobsApi from "../../../api/jobApi";
import type { RootState } from "../../../app/store";
import convertAttributes from "../../../Helpers/attributeConverter";
import { Job, JobOption, PaginationOption } from "../../../Models/job";

interface JobState {
  jobs: Job[];
  rowCount: number;
  pagination: PaginationOption;
  sorting: GridSortItem;
  filtering: GridFilterItem;
  metric?: boolean;
}

const initialState: JobState = {
  jobs: [],
  rowCount: 0,
  pagination: { limit: 100, page: 1 },
  sorting: { field: "", sort: undefined },
  filtering: {
    columnField: "",
    operatorValue: undefined,
    value: undefined,
  },
  metric: false,
};

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setJobs: (state, action: PayloadAction<Job[]>) => {
      state.jobs = action.payload;
    },
    setRowCount: (state, action: PayloadAction<number>) => {
      state.rowCount = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload + 1;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload;
    },
    setSorting: (state, action: PayloadAction<GridSortItem>) => {
      state.sorting = action.payload;
    },
    setFiltering: (state, action: PayloadAction<GridFilterItem>) => {
      state.pagination.page = 1;
      state.filtering = action.payload;
    },
    setMetric: (state, action: PayloadAction<boolean>) => {
      state.metric = action.payload;
    },
  },
});
export const {
  setJobs,
  setRowCount,
  setPage,
  setLimit,
  setSorting,
  setFiltering,
  setMetric,
} = jobSlice.actions;
export const selectJobs = (state: RootState) => state.jobs.jobs;
export const selectRowCount = (state: RootState) => state.jobs.rowCount;
export const selectPagination = (state: RootState) => state.jobs.pagination;
export const selectSorting = (state: RootState) => state.jobs.sorting;
export const selectFiltering = (state: RootState) => state.jobs.filtering;
export const selectMetric = (state: RootState) => state.jobs.metric;
export const getJobs = createAsyncThunk<Job[], JobOption, RejectError>(
  "jobs/getJobs",
  async (jobOption: JobOption, { dispatch, rejectWithValue }) => {
    try {
      const res = await jobsApi.post(jobOption);
      const count: number = res.data.totalDocs;
      dispatch(setRowCount(count));
      const data: Job[] = res.data.docs.map(
        ({
          id,
          name,
          init_date,
          mode,
          status,
          error,
          error_code,
          is_accurate,
          reason,
          size,
          side,
          auto_measure,
          selected_slice,
          selected_model,
          metric_size,
        }: Job) => ({
          id,
          name,
          init_date,
          mode: convertAttributes.get(mode),
          status,
          error,
          error_code,
          is_accurate,
          reason: convertAttributes.get(reason),
          size,
          side: convertAttributes.get(side),
          auto_measure,
          selected_slice: convertAttributes.get(selected_slice),
          selected_model,
          auto_metric_size: metric_size,
        })
      );
      dispatch(setJobs(data));
      return res.data;
    } catch (err) {
      // const error: AxiosError<ValidationErrors> = err;
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else throw err;
    }
  }
);

export default jobSlice.reducer;
