import React from "react";
import styled from "@emotion/styled";

interface Props {
  icon:
    | "home"
    | "clients"
    | "bodyMeasures"
    | "captureDashboard"
    | "sizingTool"
    | "signOut"
    | "swaggerui";
}

function Icon({ icon }: Props) {
  return (
    <>
      {icon === "home" ? (
        <HomeIcon />
      ) : icon === "clients" ? (
        <ClientsIcon />
      ) : icon === "bodyMeasures" ? (
        <BodyMeasuresIcon />
      ) : icon === "captureDashboard" ? (
        <CaptureDashboardIcon />
      ) : icon === "signOut" ? (
        <SignOutIcon />
      ) : icon === "sizingTool" ? (
        <SizingToolIcon />
      ) : icon === "swaggerui" ? (
        <SwaggerUIIcon />
      ) : null}
    </>
  );
}

const SVG = styled.svg``;

const HomeIcon = () => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 22V12H15V22M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const ClientsIcon = () => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const BodyMeasuresIcon = () => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 20V10M12 20V4M6 20V14"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const CaptureDashboardIcon = () => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 17L12 22L22 17M2 12L12 17L22 12M12 2L2 7L12 12L22 7L12 2Z"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const SizingToolIcon = () => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79079 18.2194L18.2194 6.79079C18.4983 6.51184 18.4983 6.05958 18.2194 5.78064C17.9404 5.50169 17.4882 5.50169 17.2092 5.78064L5.78064 17.2092C5.50169 17.4882 5.50169 17.9404 5.78064 18.2194C6.05958 18.4983 6.51184 18.4983 6.79079 18.2194ZM2 15.5714C2 15.1769 2.3198 14.8571 2.71429 14.8571C3.10877 14.8571 3.42857 15.1769 3.42857 15.5714V19.8571C3.42857 20.2516 3.74837 20.5714 4.14286 20.5714H8.42857C8.82306 20.5714 9.14286 20.8912 9.14286 21.2857C9.14286 21.6802 8.82306 22 8.42857 22H4.14286C2.95939 22 2 21.0406 2 19.8571V15.5714ZM22 8.42857C22 8.82306 21.6802 9.14286 21.2857 9.14286C20.8912 9.14286 20.5714 8.82306 20.5714 8.42857V4.14286C20.5714 3.74837 20.2516 3.42857 19.8571 3.42857H15.5714C15.1769 3.42857 14.8571 3.10877 14.8571 2.71429C14.8571 2.3198 15.1769 2 15.5714 2H19.8571C21.0406 2 22 2.95939 22 4.14286V8.42857Z"
        fill="#515151"
      />
    </SVG>
  );
};

const SignOutIcon = () => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 20H5.66667C5.22464 20 4.80072 19.8244 4.48816 19.5118C4.17559 19.1993 4 18.7754 4 18.3333V6.66667C4 6.22464 4.17559 5.80072 4.48816 5.48816C4.80072 5.17559 5.22464 5 5.66667 5H9M14.8333 16.6667L19 12.5M19 12.5L14.8333 8.33333M19 12.5H9"
        stroke="#515151"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

const SwaggerUIIcon = () => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 17L10 11L4 5M12 19H20"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export default Icon;
