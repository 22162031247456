import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getParam } from "../../Services/Sizing/Sizing";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DropdownField from "./DropdownField";
import TextField from "./TextField";
import copy from "../../Helpers/copy";

// TODO:
// issue with the model skipping not automatically rendering
// next field (likely not setting the state early enough)

// measurements input

// final measure

interface FormItemProps {
  label?: string;
  formLabel?: string;
  setFunction: any;
  formData: any;
  dataIndex: string;
  options: any;
  value: any;
}

function FormItem({
  label,
  formLabel,
  setFunction,
  formData,
  dataIndex,
  options,
  value,
}: FormItemProps) {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Typography sx={{ flexBasis: "50%" }}>{label}</Typography>
      <Box component="div" sx={{ flexBasis: "50%" }}>
        <DropdownField
          labelText={formLabel}
          setFunction={setFunction}
          userData={formData}
          dataIndex={dataIndex}
          options={options}
          value={value}
        />
      </Box>
    </Box>
  );
}

interface FormInputItemProps {
  label?: string;
  formLabel?: string;
  setFunction: any;
  formData: any;
  dataIndex: string;
  value: any;
}

function FormInputItem({
  label,
  formLabel,
  setFunction,
  formData,
  dataIndex,
  value,
}: FormInputItemProps) {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Typography sx={{ flexBasis: "50%" }}>{label}</Typography>
      <Box component="div" sx={{ flexBasis: "50%" }}>
        <TextField
          value={value}
          labelText={formLabel}
          setFunction={setFunction}
          userData={formData}
          dataIndex={dataIndex}
        />
      </Box>
    </Box>
  );
}

function SizingTool() {
  const { t } = useTranslation("general");
  const [finalSize, setFinalSize] = useState<any>();
  const [sizingData, setSizingData] = useState<any>([
    { index: "brand", order: 0, option: null, values: [] },
    { index: "type", order: 1, option: null, values: [] },
    { index: "gender", order: 2, option: null, values: [] },
    { index: "model", order: 3, option: null, values: [] },
    { index: "standard", order: 4, option: null, values: [] },
    { index: "measure", order: 5, option: null, values: [] },
  ]);
  const [landmarkData, setLandmarkData] = useState<any>(null);

  interface UpdateSizingDataProps {
    index: "brand" | "type" | "gender" | "model" | "standard";
    // option selected from dropdown
    option: string;
  }

  const updateSizingData = async ({ index, option }: UpdateSizingDataProps) => {
    if (index !== "standard") {
      setFinalSize(null);
    }

    let stateCopy = copy(sizingData);
    let order: number = 0;
    // distinct: "shoes"
    let distinct: string = "";
    let nextDistinct: string = "";
    // set selected option
    // set order

    stateCopy.forEach((i: any) => {
      if (i.index === index) {
        order = i.order;
        i.option = option;
      }
    });

    stateCopy.forEach((i: any) => {
      if (i.order > order) {
        i.option = null;
        i.values = [];
      }
    });

    // set query
    // query: {"brand": "Adidas", "type": "shoes", ...}
    let query: any = {};
    stateCopy.forEach((i: any) => {
      if (i.order === order + 1) {
        distinct = i.index;
      }
      if (i.order === order + 2) {
        nextDistinct = i.index;
      }
      if (i.order <= order) {
        query[i.index] = i.option;
      }
    });

    // fetch the data
    await getParam({ query: query, distinct: distinct })
      .then((res: any) => {
        if (res[0]?.landmark) {
          setLandmarkData(res[0]);
        }
        if (res?.final_size) {
          setFinalSize(res.final_size);
        }
        res = res.filter((n: any) => n);
        // fallback
        if (!res.length) {
          getParam({ query: query, distinct: nextDistinct })
            .then((res: any) => {
              return stateCopy.map((i: any) => {
                if (i.index === nextDistinct) {
                  return (i.values = res);
                }
                setSizingData([...stateCopy]);
              });
            })
            .catch((err: any) => {
              // console.log(err);
            });
        } else {
          // end fallback
          return stateCopy.map((i: any) => {
            if (i.index === distinct) {
              return (i.values = res);
            }
          });
        }
      })
      .catch((err: any) => {
        // console.log(err);
      });

    setSizingData([...stateCopy]);
  };

  const handleDropdown = (value: any, index: any) => {
    let stateCopy = copy(sizingData);

    stateCopy.map((i: any) => {
      if (i.index === index) {
        return (i.option = value);
      }
    });
    setSizingData(stateCopy);

    updateSizingData({ index: index, option: value });
  };

  const parseLandmark = (landmark: any) => {
    let returnString = "";

    if (!landmark) {
      return "";
    }

    if (typeof landmark === "string") {
      return landmark;
    } else {
      landmark.forEach((i: any, index: number) => {
        if (index === landmark.length - 1) {
          returnString += `or ${i}`;
        } else {
          returnString += `${i}, `;
        }
      });
    }
    return returnString;
  };

  // initialize measures, get brands
  useEffect(() => {
    const distinct = "brand";
    const query = {};
    let stateCopy = copy(sizingData);

    getParam({ query: query, distinct: distinct }).then((res: any) => {
      return stateCopy.map((i: any) => {
        if (i.index === distinct) {
          res = res.filter((n: any) => n);
          return (i.values = res);
        }
        setSizingData([...stateCopy]);
      });
    });
  }, []);

  return (
    <Box component="div" sx={{ m: { xs: 2, md: 4 } }}>
      <Box component="div">
        <Typography variant="h1" sx={{ fontSize: "3rem" }}>
          {t("sizing_tool")}
        </Typography>
        <Box component="div" sx={{ m: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            {t("use_this_tool")}
          </Typography>
        </Box>
      </Box>
      <Box component="div">
        <Box component="form" sx={{ mx: { xs: 0, md: 8 } }}>
          {sizingData.map((i: any, index: number) => {
            if (i.values.length > 0 && i.index !== "measure") {
              return (
                <FormItem
                  key={index}
                  label={t(i.index)}
                  setFunction={handleDropdown}
                  formData={sizingData}
                  dataIndex={i.index}
                  options={i.values}
                  value={i.option}
                />
              );
            }
          })}
          {sizingData.map((i: any, index: number) => {
            if (i.index === "standard" && i.option) {
              return (
                <FormInputItem
                  key={index}
                  label={`${t("foot_length")} "${
                    // parseLandmark(
                    landmarkData?.landmark
                    // )
                  }"`}
                  formLabel=""
                  setFunction={handleDropdown}
                  formData={sizingData}
                  dataIndex={"measure"}
                  value={sizingData[5].option || ""}
                />
              );
            }
          })}
          {finalSize && (
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 8,
              }}
            >
              <Typography variant="h3">{t("your_size")}</Typography>
              <Box
                component="div"
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "4px",
                  flexBasis: "50%",
                  padding: "14px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontWeight: "500" }}>{finalSize}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default SizingTool;
