import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../Context/AuthContext";
import { signUp } from "../../../Services/Authentication/Authentication";
import { getClientBrands } from "../../../Services/Users/ClientBrands";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CESSuccess from "../CESSuccess";
import TextField from "../../../Components/TextField";
import Button from "../../../Components/Button2";
import Toast from "../../../Components/Toast";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import copy from "../../../Helpers/copy";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Brand } from "../../../Components/UserDashboard/UserDrawer/BrandSection";

interface Props {
  handleOpen: any;
}

function CESSignup({ handleOpen }: Props) {
  const { t }: { t: any } = useTranslation("signup");
  const { currentUser } = useAuth();
  const { setTmpUser } = useAuth();
  const [userData, setUserData] = useState<any>({
    first_name: null,
    last_name: null,
    email: null,
    brand: null,
  });
  const [otp, setOTP] = useState<any>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [toastLoading, setToastLoading] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<string | null>(null);
  const [activePanel, setActivePanel] = useState<number>(0);
  const [brands, setBrands] = useState<[]>([]);
  const [brand, setBrand] = useState<any>("");

  const onSubmit = async (e: React.SyntheticEvent) => {
    setToastLoading(true);
    e.preventDefault();
    await signUp(userData).then((res: any) => {
      if (res.status === 200) {
        setToastLoading(false);
        setTmpUser(userData.email);
        setOTP(res.otp);
        setActivePanel(1);
      } else {
        setToastStatus(res.error);
        setTimeout(() => {
          setToastLoading(false);
          setToastStatus(null);
        }, 2500);
      }
    });
  };

  useEffect(() => {
    const {brand, ...rest } = userData;
    if (Object.values(rest).every(Boolean)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [userData]);

  const fetchBrands = async () => {
    let token = await currentUser.getIdToken();
    getClientBrands(token).then((res: any) => {
      setBrands(res)
    });
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as Brand;
    setBrand(value);
    setUserData({...userData, brand: value._id ?? null})
  };


  return (
    <>
      <Toast
        warning={toastStatus !== null}
        loading={toastLoading}
        message={
          toastStatus === "auth/email-already-in-use"
            ? t("email_in_use")
            : toastStatus === "ALREADY_EXISTS"
            ? t("email_in_use")
            : toastStatus === "auth/invalid-email"
            ? t("invalid_email")
            : toastStatus === "auth/weak-password"
            ? t("weak_password")
            : toastStatus === "password_match"
            ? t("password_match")
            : toastStatus === "terms_warning"
            ? t("terms_warning")
            : toastStatus
            ? t("unknown_error")
            : t("loading")
        }
      />
      <Box
        component="div"
        sx={{ m: 1, display: "flex", justifyContent: "flex-end" }}
      >
        <IconButton onClick={() => handleOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      {activePanel === 1 ? (
        <CESSuccess
          username={`${userData.first_name} ${userData.last_name}`}
          handleOpen={handleOpen}
          setActivePanel={setActivePanel}
          otp={otp}
        />
      ) : (
        <>
          <Box component="div" sx={{ mx: 6, mb: 8 }}>
            <Typography
              variant="h1"
              sx={{
                mb: 4,
                fontSize: "3.438rem",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              {t("create_new_user")}
            </Typography>
            <Box
              component="form"
              id="signup"
              onSubmit={(e: any) => onSubmit(e)}
              sx={{ width: "100%", maxWidth: "680px" }}
            >
              <TextField
                placeholder={t("ces_firstName")}
                inputType="text"
                setFunction={setUserData}
                userData={userData}
                dataIndex="first_name"
              />
              <TextField
                placeholder={t("ces_lastName")}
                inputType="text"
                setFunction={setUserData}
                userData={userData}
                dataIndex="last_name"
              />
              <TextField
                placeholder={t("email")}
                inputType="email"
                setFunction={setUserData}
                userData={userData}
                dataIndex="email"
              />
              <FormControl fullWidth sx={{mb:2}}>
                <InputLabel id="brand-label">Brand</InputLabel>
                <Select
                  labelId="brand-label"
                  id="brand-select"
                  value={brand}
                  label="Brand"
                  onChange={handleChange}
                >
                  <MenuItem key="None" value=""></MenuItem>
                  {brands?.map((item: Brand, index) => (
                    <MenuItem key={index} value={item as any}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                fullWidth
                type="submit"
                form="signup"
                disabled={disabled}
                onClick={(e: React.SyntheticEvent) => onSubmit(e)}
              >
                {t("submit")}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default CESSignup;
