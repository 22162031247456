import React from "react";
import Groups from "./Groups";
import SVGF from "./SVGF";
import SVGM from "./SVGM";

import ConditionalWrapper from "../../Helpers/ConditionalWrapper";

import FemaleFront from "./FemaleSVG/FemaleFront";
import FemaleFrontC from "./FemaleSVG/FemaleFrontC";
import FemaleBack from "./FemaleSVG/FemaleBack";
import FemaleSide from "./FemaleSVG/FemaleSide";

import MaleFront from "./MaleSVG/MaleFront";
import MaleFrontC from "./MaleSVG/MaleFrontC";
import MaleBack from "./MaleSVG/MaleBack";
import MaleSide from "./MaleSVG/MaleSide";

interface Props {
  formData: any[];
  gender?: string;
  view: string;
}

const BodySVG = ({ formData, gender, view }: Props) => {
  return (
    <>
      {gender === "Female" &&
        (view === "Front" ? (
          <FemaleFront>
            {formData.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-f`}>
                  {i.data.map((e: any, index: number) => {
                    if (e.selected || e.checked) {
                      return (
                        <>
                          <Groups
                            key={`${index}-${e.id}-f`}
                            id={e.id}
                            gender="f"
                            view={view}
                          />
                        </>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </FemaleFront>
        ) : view === "Front C" ? (
          <FemaleFrontC>
            {formData.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-f`}>
                  {i.data.map((e: any, index: number) => {
                    if (e.selected || e.checked) {
                      return (
                        <>
                          <Groups
                            key={`${index}-${e.id}-f`}
                            id={e.id}
                            gender="f"
                            view={view}
                          />
                        </>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </FemaleFrontC>
        ) : view === "Side" ? (
          <FemaleSide>
            {formData.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-f`}>
                  {i.data.map((e: any, index: number) => {
                    if (e.selected || e.checked) {
                      return (
                        <>
                          <Groups
                            key={`${index}-${e.id}-f`}
                            id={e.id}
                            gender="f"
                            view={view}
                          />
                        </>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </FemaleSide>
        ) : view === "Back" ? (
          <FemaleBack>
            {formData.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-f`}>
                  {i.data.map((e: any, index: number) => {
                    if (e.selected || e.checked) {
                      return (
                        <>
                          <Groups
                            key={`${index}-${e.id}-f`}
                            id={e.id}
                            gender="f"
                            view={view}
                          />
                        </>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </FemaleBack>
        ) : null)}
      {gender === "Male" &&
        (view === "Front" ? (
          <MaleFront>
            {formData.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-m`}>
                  {i.data.map((e: any, index: number) => {
                    if (e.selected || e.checked) {
                      return (
                        <>
                          <Groups
                            key={`${index}-${e.id}-m`}
                            id={e.id}
                            gender="m"
                            view={view}
                          />
                        </>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </MaleFront>
        ) : view === "Front C" ? (
          <MaleFrontC>
            {formData.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-m`}>
                  {i.data.map((e: any, index: number) => {
                    if (e.selected || e.checked) {
                      return (
                        <>
                          <Groups
                            key={`${index}-${e.id}-m`}
                            id={e.id}
                            gender="m"
                            view={view}
                          />
                        </>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </MaleFrontC>
        ) : view === "Side" ? (
          <MaleSide>
            {formData.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-m`}>
                  {i.data.map((e: any, index: number) => {
                    if (e.selected || e.checked) {
                      return (
                        <>
                          <Groups
                            key={`${index}-${e.id}-m`}
                            id={e.id}
                            gender="m"
                            view={view}
                          />
                        </>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </MaleSide>
        ) : view === "Back" ? (
          <MaleBack>
            {formData.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-m`}>
                  {i.data.map((e: any, index: number) => {
                    if (e.selected || e.checked) {
                      return (
                        <>
                          <Groups
                            key={`${index}-${e.id}-m`}
                            id={e.id}
                            gender="m"
                            view={view}
                          />
                        </>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </MaleBack>
        ) : null)}
    </>
  );
};

export default BodySVG;
