// @ts-nocheck
import React, { Suspense, useRef, useEffect } from "react";
import { Canvas as FCanvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, useGLTF, Stage } from "@react-three/drei";
import { Vector3 } from "three";
import Meshes from "./Meshes";
import CircularProgress from "@mui/material/CircularProgress";
interface Props {
  formData: any[];
  reset: boolean;
  setReset: Dispatch<SetStateAction<boolean>>;
  gender: string;
}

const Camera = ({ reset, setReset }: any) => {
  const { camera, controls } = useThree();
  const controlRef = useRef();
  let cameraPos = new Vector3(-0.9, 117, 259);
  let controlPos = new Vector3(0, 66, -22);
  const cameraLastPosition = useRef({
    x: camera.position.x,
    y: camera.position.y,
  });

  useFrame(() => {
    if (reset) {
      camera.position.lerp(cameraPos, 0.1);
      controls.target.lerp(controlPos, 0.1);
      setTimeout(() => {
        setReset(false);
      }, 700);
    }
  });

  return (
    <OrbitControls
      ref={controlRef}
      makeDefault
      target={[0, 66, -22]}
      minDistance={45}
      maxDistance={300}
      onChange={(e) => {
        const maxX = 60;
        const minX = -60;
        const maxY = 180;
        const minY = -190;
        const x = e?.target.target.x;
        const y = e?.target.target.y;

        if (x < minX || x > maxX) {
          e?.target.target.setX(x < minX ? minX : maxX);
          camera.position.setX(cameraLastPosition.current.x);
        }
        if (y < minY || y > maxY) {
          e?.target.target.setY(y < minY ? minY : maxY);
          camera.position.setY(cameraLastPosition.current.y);
        }
        cameraLastPosition.current.x = camera.position.x;
        cameraLastPosition.current.y = camera.position.y;
      }}
    />
  );
};

const Body3d = ({ formData, reset, setReset, gender }: Props) => {
  const groupF = useRef();
  const groupM = useRef();
  const lightsRef = useRef();
  const { nodes, materials } = useGLTF(
    gender === "Female" ? "/Female.glb" : "/Male.glb"
  );
  let dataTest = formData.map((i: any) => {
    return i.data.map((e: any) => {
      return { id: e.id, visible: e.checked || e.selected };
    });
  });

  return (
    <>
      <div
        style={{
          position: "relative",
          minHeight: "70vh",
          display: "block",
          height: "70vh",
          width: "100%",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Suspense fallback={<CircularProgress />}>
          <FCanvas
            gl={{ preserveDrawingBuffer: true }}
            shadows
            dpr={[1, 1.5]}
            camera={{ position: [0, 0, 0], fov: 50 }}
            style={{
              background: "#F7F7F7",
            }}
            resize={{ debounce: 0 }}
          >
            <ambientLight ref={lightsRef} intensity={0} />
            <Camera reset={reset} setReset={setReset} />
            <Suspense fallback={null}>
              <Stage
                contactShadow={false}
                shadows={false}
                adjustCamera
                preset={"rembrandt"}
                intensity={gender === "Female" ? 0.7 : 0.4}
                style={{ background: "#F7F7F7", minHeight: "70vh" }}
              >
                <group
                  ref={gender === "Female" ? groupF : groupM}
                  dispose={null}
                  position={[Math.PI / 2, 0, 0]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={
                      nodes[gender === "Female" ? "Female_body" : "Male_Body"][
                        "geometry"
                      ]
                    }
                    material={
                      gender === "Female"
                        ? materials["Material.001"]
                        : materials.body
                    }
                  />

                  <Meshes
                    data={dataTest.flat()}
                    nodes={nodes}
                    lightsRef={lightsRef}
                    materials={materials}
                    gender={gender}
                  />
                </group>
              </Stage>
            </Suspense>
          </FCanvas>
        </Suspense>
      </div>
    </>
  );
};

export default Body3d;
