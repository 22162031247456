const navData = [
  {
    to: "/",
    text: "home",
    icon: "home",
    disabled: false,
  },
  {
    to: "/userdashboard",
    text: "clients",
    icon: "clients",
    disabled: false,
  },
  {
    to: "/brands",
    text: "brands",
    icon: "clients",
    disabled: false,
  },
  {
    to: "/bodymeasures",
    text: "body_measures",
    icon: "bodyMeasures",
    disabled: false,
  },
  {
    to: "/sizingtool",
    text: "sizing_tool",
    icon: "sizingTool",
  },
  {
    to: "/swaggerui",
    text: "Swagger UI",
    icon: "swaggerui",
    disabled: false,
  },
  {
    to: "/framework",
    text: "framework",
    icon: "swaggerui",
    disabled: false,
  },
  {
    to: "/capturedashboard",
    text: "capture_dashboard",
    icon: "captureDashboard",
    disabled: true,
  },
];

export default navData;
