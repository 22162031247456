import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

import AuthLayout from "../../../Layouts/AuthLayout";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MUIButton from "@mui/material/Button";
import Toast from "../../../Components/Toast";
import Button from "../../../Components/Button2";
import TextField from "../../../Components/TextField";

function FBResetPassword() {
  const { t } = useTranslation("auth_new");
  const [newPassword, setNewPassword] = useState<any>({
    password: null,
    confirmPassword: null,
  });
  const [toastLoading, setToastLoading] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<string | null | number>(null);
  const [status, setStatus] = useState<number | null>(null);
  const location = useLocation();
  const params = location && location.search;
  const { resetPasswordFromEmail } = useAuth();

  const getOobCode = (params: string) => {
    return params?.split("oobCode=")?.pop()?.split("&apiKey")[0];
  };

  const submitChange = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setToastLoading(true);

    if (newPassword.password !== newPassword.confirmPassword) {
      setToastStatus("password_mismatch");
      setTimeout(() => {
        setToastLoading(false);
        setToastStatus(null);
      }, 2500);
      return;
    }
    let oobCode = getOobCode(params);

    resetPasswordFromEmail(oobCode, newPassword.password).then((res: any) => {
      if (res === 200) {
        setToastLoading(false);
        setStatus(res);
      }
      setToastStatus(res);
      setTimeout(() => {
        setToastStatus(null);
        setToastLoading(false);
      }, 2500);
    });
  };

  return (
    <>
      <Toast
        warning={toastStatus !== null}
        loading={toastLoading}
        message={
          toastStatus === "auth/email-already-in-use"
            ? t("email_in_use")
            : toastStatus === "ALREADY_EXISTS"
            ? t("email_in_use")
            : toastStatus === "auth/invalid-email"
            ? t("invalid_email")
            : toastStatus === "auth/weak-password"
            ? t("weak_password")
            : toastStatus === "password_match"
            ? t("password_match")
            : toastStatus === "terms_warning"
            ? t("terms_warning")
            : toastStatus === 200
            ? t("loading")
            : toastStatus
            ? t("unknown_error")
            : t("loading")
        }
      />
      {status === 200 ? (
        <AuthLayout heading={t("update_password")}>
          <Typography paragraph sx={{ textAlign: "center", mt: 4, mx: 2 }}>
            {t("update_thank_you")}
          </Typography>
          <Typography paragraph sx={{ textAlign: "center", mx: 2 }}>
            {t("update_you_can")}
          </Typography>
          <Box
            component="div"
            sx={{ display: "flex", justifyContent: "center", pt: 4 }}
          >
            <MUIButton
              href="/"
              fullWidth
              variant="contained"
              sx={{ color: "white" }}
            >
              {t("go_to_login")}
            </MUIButton>
          </Box>
        </AuthLayout>
      ) : (
        <AuthLayout
          heading={t("update_password")}
          onSubmit={submitChange}
          id="reset"
        >
          <Typography paragraph sx={{ textAlign: "center", mb: 4 }}>
            {t("please_enter_pw")}
          </Typography>
          <TextField
            inputType="password"
            setFunction={setNewPassword}
            userData={newPassword}
            dataIndex="password"
            placeholder={t("new_password")}
            password
          />
          <TextField
            inputType="password"
            required
            setFunction={setNewPassword}
            userData={newPassword}
            dataIndex="confirmPassword"
            placeholder={t("confirm_password")}
            password
          />

          <MUIButton
            type="submit"
            form="reset"
            variant="contained"
            fullWidth
            disabled={
              newPassword.password == null ||
              newPassword.password !== newPassword.confirmPassword
            }
            sx={{ my: 2 }}
          >
            {t("submit")}
          </MUIButton>
          <Typography
            variant="caption"
            component="a"
            href="/"
            sx={{
              textAlign: "center",
              display: "block",
              cursor: "pointer",
              transition: "all var(--transition-animation)",
              "&:hover": {
                color: "var(--primary-color)",
              },
            }}
          >
            {t("back_to_login")}
          </Typography>
        </AuthLayout>
      )}
    </>
  );
}

export default FBResetPassword;
