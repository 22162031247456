const header = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER_URL}`
};

export const getAllFws = async (token: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
  };
  const URL = `${process.env.REACT_APP_SERVER_URL}/api/bucket/get-files`;
  try {
    const res = await fetch(URL, requestOptions);
    const data = await res.json();
    return data;
  } catch (err) {
    return 400;
  }
};

interface FwBodyProps {
  path: string;
  token: any;
}

export const getFwUrl = async ({ path, token }: FwBodyProps) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ path: path }),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/api/get-framework`;
  try {
    return await fetch(URL, requestOptions).then(async (res: any) => {
      let data = await res.json();
      return data;
    });
  } catch (err) {
    return 400;
  }
};
