import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Global } from "@emotion/react";
import theme from "./theme";
import colors from "./constants/colors";
import { I18nextProvider } from "react-i18next";
import { AuthProvider } from "./Context/AuthContext";
import i18n from "./i18n"; // initialized i18next instance
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
// import axe from "@axe-core/react";
// const ReactDOM = require("react-dom");

// if (process.env.NODE_ENV !== "production") {
//   axe(React, ReactDOM, 1000);
// }

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <Suspense fallback={""}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Global
          styles={{
            body: colors,
          }}
        />
        <CssBaseline />
        <AuthProvider>
          <I18nextProvider i18n={i18n}>
            <Provider store={store}>
              <App />
            </Provider>
          </I18nextProvider>
        </AuthProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </Suspense>
  // </React.StrictMode>
  // document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
