import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

interface RequireAuthProps {
  children: any;
  redirectTo: string;
  isAdmin?: boolean;
  hard?: boolean;
}

// @TODO:
// Possibility to rescrict access based on role here by getting 'role' from useAuth();

function RequireAuth({
  children,
  redirectTo,
  isAdmin,
  hard,
}: RequireAuthProps) {
  let { userData, admin, currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [go, setGo] = useState<boolean>(false);
  // let userToken = sessionStorage.getItem("auth-token");
  // if (isAdmin && !admin) {
  //   return <Navigate to={redirectTo} />;
  // } else

  const getAuthStatus = () => {
    if (userData && !currentUser) {
      setLoading(true);
      return false;
    } else if (userData && currentUser) {
      if (currentUser.first_login) {
        setGo(true);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    getAuthStatus();
  }, [userData, currentUser]);

  if (!currentUser) {
    return <Navigate to={redirectTo} />;
  } else if (!userData) {
    return null;
  } else {
    return userData?.first_login ? children : <Navigate to={redirectTo} />;
  }

  // return go ? children : <Navigate to={redirectTo} />;
}

export default RequireAuth;
