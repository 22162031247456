import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import NavLink from "./NavLink";
import Profile from "./Profile";
import Logo from "../Logo";
import MinimizeButton from "./MinimizeButton";
import navData from "../../mockData/navData2";

function NavDrawer() {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  // add a margin top to the nav body, then height as calc
  // margin should be height of the header
  const handleNavOpen = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Box
      component="nav"
      sx={{
        position: { xs: "fixed", md: "static" },
        display: { xs: "flex", md: "block" },
        top: "0",
        width: { xs: "100%", md: "auto" },
        background: {
          xs: "var(--primary-color)",
          md: "none",
        },
        zIndex: { xs: "1000", md: "auto" },
        height: { xs: "56px", md: "auto" },
        transition: "background 180ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      }}
    >
      <IconButton
        onClick={() => handleNavOpen()}
        sx={{
          position: "fixed",
          top: "8px",
          left: "8px",
          zIndex: "2000",
          display: { xs: "initial", md: "none" },
        }}
      >
        <MenuIcon />
      </IconButton>

      <Box
        component="div"
        sx={{
          width: {
            xs: mobileOpen ? "100vw" : "0px",
            md: open ? "30px" : "260px",
          },
          minHeight: {
            xs: mobileOpen ? "calc(100vh - 56px)" : null,
            md: "100vh",
          },
          mt: { xs: "56px", sm: "60px", md: "0px" },
          background: mobileOpen ? "white" : "var(--bg-grey)",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          visibility: { xs: mobileOpen ? null : "hidden", md: "visible" },
          // overflow: "hidden",
          borderRight: { xs: "none", md: "1px solid var(--grey-50)" },
          transition: "all 180ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          position: "sticky",
          top: "0",
        }}
      >
        <Box component="div">
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 4,
              height: "114px",
            }}
          >
            <Logo height={mobileOpen ? "50px" : !open ? "50px" : "1px"} />
          </Box>
          <Box
            component="div"
            sx={{
              display: { xs: "none", md: "block" },
              height: "42px",
              position: "relative",
            }}
          >
            <MinimizeButton handleOpen={handleOpen} open={open} />
          </Box>
          <Box
            component="div"
            sx={{
              width: mobileOpen ? "100%" : !open ? "100%" : "0px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {navData.map((i: any, index: number) => {
              return (
                <NavLink
                  key={index}
                  location={location.pathname}
                  to={i.to}
                  label={i.text}
                  icon={i.icon}
                  disabled={i.disabled}
                  mobileOpen={mobileOpen}
                />
              );
            })}
          </Box>
        </Box>
        <Profile mobileOpen={mobileOpen} />
      </Box>
    </Box>
  );
}

export default NavDrawer;
