const MaleSide: any[] = [
  <>
    <g
      id="WIl"
      data-name="Group 17738"
      transform="translate(4653.202 13576.775)"
    >
      <circle
        id="Ellipse_20"
        data-name="Ellipse 20"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4560 -12581)"
        fill="#5c76aa"
      />
      <circle
        id="Ellipse_21"
        data-name="Ellipse 21"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4615 -13218)"
        fill="#5c76aa"
      />
      <path
        id="Path_12124"
        data-name="Path 12124"
        d="M-6145.63-17993.664s-5.081,99.326,0,117.5,0,112.211,0,112.211,2.852,71.059,13.686,102.441,4.264,49.391,23.1,80.064,19.455,135.67,19.455,135.67,1.334,35.141.289,57.32-1.259,30.488-1.259,30.488"
        transform="translate(1538 4781.002)"
        fill="none"
        stroke="#5c76aa"
        stroke-linecap="round"
        strokeWidth="5"
      />
      <path
        id="Path_12281"
        data-name="Path 12281"
        d="M-3.78-12.98c-.04-.12-.06-.16-.16-.16H-6.26a.179.179,0,0,0-.2.16C-8-6.44-8.6-4.24-8.84-2.94h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-10.32-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12H-.5c.12,0,.16-.02.2-.12L3.08-12.96c.02-.12-.02-.18-.14-.18H.84a.182.182,0,0,0-.2.16C-.76-6.7-1.28-4.04-1.48-2.92H-1.5C-2.04-5.66-3-9.68-3.78-12.98Zm10.94,0c0-.12-.04-.16-.16-.16H4.9c-.1,0-.16.02-.16.16V-.18c0,.12.04.18.16.18H7c.12,0,.16-.04.16-.18ZM9.8-2.38C9.8-.28,10.84.2,12.12.2A2.916,2.916,0,0,0,13.26.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H9.98c-.12,0-.18.06-.18.16Z"
        transform="translate(-4551 -12545)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="HWl2"
      data-name="Group 17734"
      transform="translate(2886.664 13556.296)"
    >
      <g
        id="Group_17498"
        data-name="Group 17498"
        transform="translate(-2697 -13218)"
      >
        <circle
          id="Ellipse_20-2"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-3 654.479)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-2"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-9.077 20.479)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-2"
          data-name="Path 12124"
          d="M-6149.5-17344.445c.055-8-3.8-30.189-3.8-30.189s-13.969-48.338-9-70.391,11.526-53.584,12.8-89.186-27.453-73.727-18.864-107.52-.412-107.043,15.063-126.027,30.891-48.934,28.946-76.682-12.194-36.039-21.277-53.164a109.462,109.462,0,0,1-11.081-34.68s-5.065-15.523,3.917-41.395"
          transform="translate(6153 17999.002)"
          fill="none"
          stroke="#52a3b3"
          stroke-linecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12280"
        data-name="Path 12280"
        d="M11.74,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14H9.62a.126.126,0,0,0-.14.14v5.08H3.9v-5.06c0-.12-.04-.16-.16-.16H1.66c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18H3.74c.12,0,.16-.06.16-.18V-5.74H9.48V-.16c0,.12.04.16.16.16Zm11.8-12.98c-.04-.12-.06-.16-.16-.16H21.06a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14H13.78c-.1,0-.14.04-.12.16L17-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L30.4-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C25.28-5.66,24.32-9.68,23.54-12.98Zm8.3,10.6C31.84-.28,32.88.2,34.16.2A2.916,2.916,0,0,0,35.3.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H32.02c-.12,0-.18.06-.18.16ZM44.62,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14h-2.3c-1.42,0-2.18,0-2.94.06a23.064,23.064,0,0,0,2.48-2.16A6.132,6.132,0,0,0,44.58-8.8a3.916,3.916,0,0,0-4.3-4.06,7.391,7.391,0,0,0-3.52.86.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02a42.211,42.211,0,0,1-3.32,2.72c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(-2680 -12549.521)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="KWl"
      data-name="Group 17739"
      transform="translate(2886.697 13556.296)"
    >
      <g
        id="Group_17498-2"
        data-name="Group 17498"
        transform="translate(-2697 -13218)"
      >
        <circle
          id="Ellipse_20-3"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-20 392.02)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-3"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-9.075 20.479)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-3"
          data-name="Path 12124"
          d="M-6152.262-17974.719c-8.982,25.875-4.449,42.436-4.449,42.436a109.462,109.462,0,0,0,11.081,34.68c9.083,17.125,19.333,25.416,21.277,53.164s-13.472,57.7-28.946,76.682-6.474,92.234-15.062,126.027c-3.418,13.449-.925,27.586,3.314,42.248"
          transform="translate(6153 17999.002)"
          fill="none"
          stroke="#52a3b3"
          stroke-linecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12278"
        data-name="Path 12278"
        d="M6.34-6.76a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2H9.28a.262.262,0,0,0-.24.12L3.9-7.1v-5.88c0-.12-.04-.16-.16-.16H1.64c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18H3.74c.12,0,.16-.04.16-.18V-6.1L9.12-.16A.354.354,0,0,0,9.46,0H12a.129.129,0,0,0,.1-.22Zm15.4-6.22c-.04-.12-.06-.16-.16-.16H19.26a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14H11.98c-.1,0-.14.04-.12.16L15.2-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L28.6-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C23.48-5.66,22.52-9.68,21.74-12.98Zm8.3,10.6C30.04-.28,31.08.2,32.36.2A2.916,2.916,0,0,0,33.5.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H30.22c-.12,0-.18.06-.18.16Z"
        transform="translate(-2696 -12812.521)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="CWl"
      data-name="Group 17755"
      transform="translate(4372.793 13576.775)"
    >
      <path
        id="Path_12154"
        data-name="Path 12154"
        d="M-4593.941-13027.566s17.2,21.442,52.643,23.921"
        transform="translate(285.707 19)"
        fill="none"
        stroke="#5c76aa"
        stroke-linecap="round"
        strokeWidth="5"
        strokeDasharray="10"
      />
      <circle
        id="Ellipse_21-4"
        data-name="Ellipse 21"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4335.293 -13218)"
        fill="#5c76aa"
      />
      <path
        id="Path_12124-4"
        data-name="Path 12124"
        d="M-6145.63-17993.664s-5.081,99.326,0,117.5,1.7,66.568,11.218,79.438"
        transform="translate(1817.707 4781.002)"
        fill="none"
        stroke="#5c76aa"
        stroke-linecap="round"
        strokeWidth="5"
      />
      <path
        id="Path_12274"
        data-name="Path 12274"
        d="M-23.74-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-31-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9a.212.212,0,0,0-.12-.22,6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-33.5-1.98-30.34.2-26.64.2a8.624,8.624,0,0,0,2.76-.36.254.254,0,0,0,.14-.26Zm11.4-10.78c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14H-22.1c-.1,0-.14.04-.12.16L-18.88-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-9.32-6.7-9.84-4.04-10.04-2.92h-.02C-10.6-5.66-11.56-9.68-12.34-12.98Zm8.3,10.6C-4.04-.28-3,.2-1.72.2A2.916,2.916,0,0,0-.58.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H-3.86c-.12,0-.18.06-.18.16Z"
        transform="translate(-4339.293 -13204)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="WHl"
      data-name="Group 17754"
      transform="translate(4468.202 13576.775)"
    >
      <circle
        id="Ellipse_20-4"
        data-name="Ellipse 20"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4257.847 -13094.46)"
        fill="#52a3b3"
      />
      <path
        id="Path_12156"
        data-name="Path 12156"
        d="M7.5,0A7.5,7.5,0,1,1,0,7.5,7.5,7.5,0,0,1,7.5,0Z"
        transform="translate(-4287.847 -13218)"
        fill="#52a3b3"
      />
      <path
        id="Path_12124-5"
        data-name="Path 12124"
        d="M-6151.941-17975.369c-8.982,25.875-4.77,43.086-4.77,43.086a109.462,109.462,0,0,0,11.081,34.68c9.083,17.125,19.333,25.416,21.277,53.164"
        transform="translate(1874.153 4760.522)"
        fill="none"
        stroke="#52a3b3"
        stroke-linecap="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_22"
        data-name="Ellipse 22"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4428.847 -13094.46)"
        fill="#5c76aa"
      />
      <path
        id="Path_12277"
        data-name="Path 12277"
        d="M10.06-12.98c-.04-.12-.06-.16-.16-.16H7.58a.179.179,0,0,0-.2.16C5.84-6.44,5.24-4.24,5-2.94H4.98C4.82-4.04,4.46-5.86,2.76-13c-.02-.14-.1-.14-.2-.14H.3c-.1,0-.14.04-.12.16L3.52-.16c.06.1.1.16.22.16H5.86c.14,0,.18-.08.2-.18C6.7-2.62,8.26-8.4,8.62-10.42h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C11.8-5.66,10.84-9.68,10.06-12.98ZM28.84,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14H26.72a.126.126,0,0,0-.14.14v5.08H21v-5.06c0-.12-.04-.16-.16-.16H18.76c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16Zm2.78-2.38C31.62-.28,32.66.2,33.94.2A2.916,2.916,0,0,0,35.08.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H31.8c-.12,0-.18.06-.18.16Z"
        transform="translate(-4237.847 -13080)"
        fill="#52a3b3"
      />
      <circle
        id="Ellipse_21-5"
        data-name="Ellipse 21"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4430.293 -13218)"
        fill="#5c76aa"
      />
      <path
        id="Path_12124-6"
        data-name="Path 12124"
        d="M-6145.63-17993.664s-5.081,99.326,0,117.5,2.443,9.824,2.443,9.824"
        transform="translate(1722.707 4781.002)"
        fill="none"
        stroke="#5c76aa"
        stroke-linecap="round"
        strokeWidth="5"
      />
    </g>
    <g
      id="BCl"
      data-name="Group 17756"
      transform="translate(4658.202 13576.775)"
    >
      <circle
        id="Ellipse_21-6"
        data-name="Ellipse 21"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4477.141 -13218)"
        fill="#52a3b3"
      />
      <path
        id="Path_12124-7"
        data-name="Path 12124"
        d="M-6152.411-17975.051c-8.982,25.871-4.3,42.768-4.3,42.768a109.462,109.462,0,0,0,11.081,34.68c9.083,17.125,19.333,25.416,21.277,53.164s-15.347,72.092-41.735,86.795"
        transform="translate(1683.859 4760.522)"
        fill="none"
        stroke="#52a3b3"
        stroke-linecap="round"
        strokeWidth="5"
      />
      <path
        id="Path_12157"
        data-name="Path 12157"
        d="M-4535.937-13003.439c13.222.173,29.108-2.445,47.432-9.822"
        transform="translate(-0.293 19)"
        fill="none"
        stroke="#52a3b3"
        stroke-linecap="round"
        strokeWidth="5"
        strokeDasharray="10"
      />
      <path
        id="Path_12276"
        data-name="Path 12276"
        d="M1.48-.16A.152.152,0,0,0,1.62,0c.76.02,2.12.04,3.86.04,3.3,0,5.48-1.52,5.48-4.04A3.351,3.351,0,0,0,9.04-7.1,3.122,3.122,0,0,0,10.3-9.7c0-1.28-.64-3.48-4.82-3.48-1.34,0-3.16.04-3.88.06-.1.02-.12.08-.12.18ZM3.86-5.82H5.68a5.262,5.262,0,0,1,1.36.14A1.679,1.679,0,0,1,8.48-3.94c0,1.34-1.2,1.98-3.08,1.96-.6,0-1.1-.02-1.54-.04Zm0-5.28c.42,0,.94-.02,1.58-.02,1.64,0,2.52.58,2.52,1.76A1.893,1.893,0,0,1,7.2-7.78,12.321,12.321,0,0,0,5.38-7.9H3.86ZM22.38-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1,15.12-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9a.212.212,0,0,0-.12-.22,6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C12.62-1.98,15.78.2,19.48.2a8.624,8.624,0,0,0,2.76-.36.254.254,0,0,0,.14-.26Zm1.84-.18C24.22-.28,25.26.2,26.54.2A2.916,2.916,0,0,0,27.68.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H24.4c-.12,0-.18.06-.18.16Z"
        transform="translate(-4456.287 -13204)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="WKl"
      data-name="Group 17772"
      transform="translate(4653.202 13576.775)"
    >
      <circle
        id="Ellipse_20-5"
        data-name="Ellipse 20"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4592 -12844)"
        fill="#5c76aa"
      />
      <circle
        id="Ellipse_21-7"
        data-name="Ellipse 21"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4615 -13218)"
        fill="#5c76aa"
      />
      <path
        id="Path_12124-8"
        data-name="Path 12124"
        d="M-6145.63-17993.664s-5.081,99.326,0,117.5,0,112.211,0,112.211,2.852,71.059,13.686,102.441c5.883,17.041,6.634,30.139,9.154,43.707"
        transform="translate(1538 4781.002)"
        fill="none"
        stroke="#5c76aa"
        stroke-linecap="round"
        strokeWidth="5"
      />
      <path
        id="Path_12279"
        data-name="Path 12279"
        d="M-7.13-12.98c-.04-.12-.06-.16-.16-.16H-9.61a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-13.67-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L-.27-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-4.11-6.7-4.63-4.04-4.83-2.92h-.02C-5.39-5.66-6.35-9.68-7.13-12.98ZM6.25-6.76a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2H9.19a.262.262,0,0,0-.24.12L3.81-7.1v-5.88c0-.12-.04-.16-.16-.16H1.55c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18H3.65c.12,0,.16-.04.16-.18V-6.1L9.03-.16A.354.354,0,0,0,9.37,0h2.54a.129.129,0,0,0,.1-.22Zm6.9,4.38C13.15-.28,14.19.2,15.47.2A2.916,2.916,0,0,0,16.61.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.33c-.12,0-.18.06-.18.16Z"
        transform="translate(-4619 -12829)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="WAl"
      data-name="Group 17826"
      transform="translate(2734.792 13576.775)"
    >
      <g
        id="Group_17498-3"
        data-name="Group 17498"
        transform="translate(-2697 -13218)"
      >
        <circle
          id="Ellipse_20-6"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 51.016)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-8"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-9"
          data-name="Path 12124"
          d="M-6145.63-17993.662v53.352"
          transform="translate(6153 17999)"
          fill="none"
          stroke="#5c76aa"
          stroke-linecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12275"
        data-name="Path 12275"
        d="M-6.83-12.98c-.04-.12-.06-.16-.16-.16H-9.31a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-13.37-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L.03-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-3.81-6.7-4.33-4.04-4.53-2.92h-.02C-5.09-5.66-6.05-9.68-6.83-12.98ZM7.85-3.46,9.01-.12a.2.2,0,0,0,.2.12h2.24c.12,0,.14-.06.12-.18L6.95-13.02c-.02-.1-.04-.12-.14-.12H4.03c-.08,0-.12.06-.12.14a3.058,3.058,0,0,1-.18,1.08L-.39-.2c-.02.14.02.2.14.2h2a.2.2,0,0,0,.22-.16l1.1-3.3ZM3.71-5.64c.6-1.82,1.4-4.16,1.72-5.5h.02c.4,1.4,1.34,4.18,1.76,5.5Zm9.14,3.26C12.85-.28,13.89.2,15.17.2A2.916,2.916,0,0,0,16.31.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.03c-.12,0-.18.06-.18.16Z"
        transform="translate(-2718 -13154.984)"
        fill="#5c76aa"
      />
    </g>
  </>,
];

export default MaleSide;
