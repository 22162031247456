const serverURL = process.env.REACT_APP_DASHBOARD_URL;

const endpoints = {
  JOBS: `${serverURL}/api/jobs`,
  LOGIN: `${serverURL}/api/auth/login`,
  LOGOUT: `${serverURL}/api/auth/logout`,
  GET_USER: `${serverURL}/api/auth/getUser`,
  REFRESH_TOKEN: `${serverURL}/api/auth/refresh-token`,
};

export default endpoints;
