import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
import Logo from "../../Components/Logo";
import { Box } from "@mui/material";
interface Props {
  heading: string;
  children: React.ReactNode;
  onSubmit?: any;
  id?: string;
}

const AuthLayout = ({ heading, children, onSubmit, id }: Props) => {
  return (
    <Grid2
      container
      component={!onSubmit ? "div" : "form"}
      id={id}
      onSubmit={(e: any) => onSubmit(e)}
      sx={{ justifyContent: "center", mt: 8, mx: 2, mb: 4 }}
    >
      <Grid2 xs={12} sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Box component="a" href="/">
          <Logo height="43px" />
        </Box>
      </Grid2>
      <Grid2
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          fontSize="3.125rem"
          sx={{ mb: 2, textAlign: "center" }}
        >
          {heading}
        </Typography>
      </Grid2>
      <Grid2 xs={12} sm={6} md={4} sx={{ justifyContent: "center" }}>
        {children}
      </Grid2>
    </Grid2>
  );
};

export default AuthLayout;
