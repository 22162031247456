import React from "react";
import { useAuth } from "../../Context/AuthContext";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HomeCTA from "../../Components/HomeCTA";

function Home() {
  const { t } = useTranslation("general");
  const { userData } = useAuth();

  return (
    <>
      <Box
        component="div"
        sx={{
          minHeight: "100vh",
          pt: { xs: 4, sm: 0 },
          display: { xs: "block", md: "flex" },
        }}
      >
        <Box component="div" sx={{ m: 3, flexBasis: "60%" }}>
          <Box component="div">
            <Typography variant="h3" sx={{ mb: 1 }}>
              {t("hi")}{" "}
              <TextHighlight>
                {userData?.first_name ? userData.first_name : ""}
              </TextHighlight>
              !
            </Typography>
            <Typography variant="h3">
              {t("welcome_to")} <TextHighlight>MyVyoo</TextHighlight>{" "}
              {t("webplatform")}
            </Typography>
            <Typography variant="h3">{t("what_would_you")}</Typography>
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <HomeCTA cta="clients" />
            <HomeCTA cta="bodyMeasures" />
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: { xs: 0, md: 4 },
            }}
          >
            <HomeCTA cta="captureDashboard" />
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            borderLeft: { xs: "none", md: "1px solid black" },
            borderTop: { xs: "1px solid black", md: "none" },
            // display: { xs: "none", md: "block" },
            my: 4,
          }}
        ></Box>
        <Box component="div" sx={{ p: 3, flexBasis: "40%" }}>
          <Typography variant="h3">{t("latest_news")}</Typography>
        </Box>
      </Box>
    </>
  );
}

const TextHighlight = styled.span`
  color: var(--primary-color);
`;

export default Home;
