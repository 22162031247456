import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
}

function UserCell({ first_name, last_name, company, email }: Props) {
  return (
    <Box component="div">
      <Box component="div">
        <Typography>
          {first_name || "-"} {last_name || "-"}{" "}
          {company ? ` - ${company}` : null}
        </Typography>
      </Box>
      <Box component="div">
        <Typography variant="caption">{email || "-"}</Typography>
      </Box>
    </Box>
  );
}

export default UserCell;
