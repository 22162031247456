import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { TextField as MUIField } from "@mui/material";
interface TextFieldProps {
  showLabel?: boolean;
  labelText?: string;
  inputType?: string;
  className?: string;
  placeholder?: string;
  register?: any;
  pattern?: string;
  value?: any;
  setFunction?: any;
  dataIndex?: string;
  userData?: any;
  required?: boolean;
  noDefault?: boolean;
  variant?: string;
}

const TextField = ({
  showLabel = true,
  labelText,
  inputType = "text",
  placeholder = "",
  register,
  pattern,
  value,
  setFunction,
  dataIndex,
  userData,
  required,
  noDefault,
  variant,
}: TextFieldProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (dataIndex === "phoneNumber") {
      setFunction({ ...userData, [dataIndex]: formatToPhone(event) });
    } else if (setFunction && dataIndex) {
      setFunction(event.target.value, dataIndex);
    }
  };

  if (!value && dataIndex && userData[dataIndex]) {
    value = userData[dataIndex];
  }
  const isModifierKey = (event: any) => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  };

  const formatToPhone = (event: any) => {
    if (isModifierKey(event)) {
      return;
    }

    const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      event.target.value = `(${areaCode}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      event.target.value = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      event.target.value = `(${areaCode}`;
    }

    return event.target.value;
  };

  return (
    <Box
      component="div"
      sx={{ width: "100%", "&:not(:last-child)": { mb: 2 } }}
    >
      {showLabel && (
        <InputLabel
          sx={{
            whiteSpace: "break-spaces",
            fontSize: "0.9375rem",
            // color: "#C4C4C6",
            // mb: 1,
          }}
        >
          {labelText}
          {/* {required && (
            <Box component="span" sx={{ color: "red" }}>
              *
            </Box>
          )} */}
        </InputLabel>
      )}
      <MUIField
        placeholder={placeholder}
        type={inputType}
        onChange={handleChange}
        variant={variant ? variant : "outlined"}
        // defaultValue={noDefault ? "" : value}
        value={value}
        required={required}
        label={placeholder}
        inputProps={{
          pattern: pattern,
        }}
        sx={{
          width: "100%",
          color: "black",
          // borderColor: "transparent",
          // background: "white",
          borderRadius: "4px",
          ".MuiInputBase-root": {
            borderRadius: "4px",
          },
          // ".MuiOutlinedInput-notchedOutline": {
          // borderRadius: "30px",
          // },
        }}
        {...register}
      />
    </Box>
  );
};

export default TextField;
