const checkAllSelected = (heading: string, type: string, formData: any) => {
  let data = null;
  for (let i = 0; i <= formData.length; i++) {
    if (
      formData[i]?.heading.toLowerCase().replace(" ", "_") ===
      heading.toLowerCase().replace(" ", "_")
    ) {
      if (formData[i].enabled === false) {
        return false;
      } else {
        data = formData[i].data
          .map((i: any) => {
            if (i.type === type) {
              return i;
            }
          })
          .filter((e: any) => e !== undefined);
      }
    }
  }
  return data.every((obj: any) => obj.checked === true);
};

export default checkAllSelected;
