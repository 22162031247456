import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Carousel from "../Carousel/Carousel";
import Button from "../Button";

interface Props {
  data: any;
  heading: any;
  handleClick: any;
  handleAllItems: any;
  allSelectedCirc: boolean;
  allSelectedLength: boolean;
}

const BodySelections = ({
  data,
  heading,
  handleClick,
  handleAllItems,
  allSelectedCirc,
  allSelectedLength,
}: Props) => {
  const [visible, setVisible] = useState<any>(["circumference", "length"]);
  const [visibleData, setVisibleData] = useState(data);
  const { t } = useTranslation("body");

  const filterVisibleData = (data: any, visibility: any) => {
    let returnData: any[] = [];
    data.forEach(function (item: any) {
      for (let i = 0; i <= visibility.length; i++) {
        if (item.type === visibility[i]) {
          returnData.push(item);
        }
      }
    });

    return returnData;
  };

  const handleVisibility = (type: string) => {
    if (visible.includes(type)) {
      setVisible(visible.filter((item: any) => item !== type));
    } else {
      setVisible([...visible, type]);
    }
  };

  useEffect(() => {
    setVisibleData(filterVisibleData(data, visible));
  }, [visible]);

  return (
    <>
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "left", md: "center" },
          justifyContent: "space-between",
          mb: 1,
          width: "calc(100% - 80px)",
        }}
      >
        <Box component="div">
          <Button
            onClick={() => handleVisibility("circumference")}
            type="circumference"
            isActive={visible.includes("circumference")}
          >
            {t("circumference")}
          </Button>
          <Button
            onClick={() => handleVisibility("length")}
            type="length"
            isActive={visible.includes("length")}
          >
            {t("length")}
          </Button>
        </Box>
        <Box component="div">
          <Button
            type="circumference"
            small
            onClick={() => handleAllItems("circumference", heading)}
            isActive={allSelectedCirc}
          >
            {t("select_all")}
          </Button>
          <Button
            type="length"
            small
            onClick={() => handleAllItems("length", heading)}
            isActive={allSelectedLength}
          >
            {t("select_all")}
          </Button>
        </Box>
      </Box>
      <Carousel
        data={visibleData}
        handleClick={handleClick}
        heading={heading}
      />
    </>
  );
};

export default BodySelections;
