import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar, GridSelectionModel } from "@mui/x-data-grid";
import formatDate from "../../Helpers/formatDate";
import { getUsers, updateUserPermissions } from "../../Services/Users/Users";
import { useAuth } from "../../Context/AuthContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
// import UserDialog from "./UserDialog";
import UserDrawer from "./UserDrawer";
import styled from "@emotion/styled";
import UserCell from "./UserCell";
import Button2 from "../Button2";
import CESSignup from "../../Pages/Authentication/CESSignup";
import { getClientBrands } from "../../Services/Users/ClientBrands";

function UserDashboard() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const ModalButton = ({ params, fetchUsers }: any) => {
    const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
    return (
      <>
        <UserDrawer
          userModalOpen={userModalOpen}
          setUserModalOpen={setUserModalOpen}
          userData={params.row}
          fetchUsers={fetchUsers}
          brands={brands}
        />
        <Box component="div">
          <Button2
            icon="edit"
            muiIconButton
            onClick={() => setUserModalOpen(true)}
          ></Button2>
        </Box>
      </>
    );
  };

  function formatString(string: string) {
    let trimmed = string.replace(/_/g, " ");
    return trimmed.charAt(0).toUpperCase() + trimmed.slice(1);
  }

  const fetchUsers = async () => {
    setLoading(true);
    let token = await currentUser.getIdToken();
    getUsers(token).then((res: any) => {
      if (res instanceof Array) {
        setLoading(false);
        setRows(res);
      } else {
        setLoading(false);
      }
    });
  };

  const fetchBrands = async () => {
    let token = await currentUser.getIdToken();
    getClientBrands(token).then((res: any) => {
      setBrands(res)
    });
  };

  useEffect(() => {
    fetchUsers();
    fetchBrands();
  }, []);

  useEffect(() => {
    let columnData: any = [
      {
        field: "actions",
        headerName: "",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 48,
        renderCell: (params: any, index: number) => {
          return (
            <ModalButton key={index} params={params} fetchUsers={fetchUsers} />
          );
        },
      },
      {
        field: "user",
        headerName: "User",
        minWidth: sm ? 280 : null,
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return `${params.row.first_name || ""} ${
            params.row.last_name || ""
          } ${params.row.company || ""} ${params.row.email || ""}`;
        },
        renderCell: (params: any, index: number) => {
          return (
            <UserCell
              key={index}
              first_name={params.row?.first_name}
              last_name={params.row?.last_name}
              company={params.row?.company}
              email={params.row?.email}
            />
          );
        },
      },
      {
        field: "Trial App Status",
        width: 160,
        valueGetter: (params: any) => {
          return `${params.row.trial_app_status}`;
        },
        renderCell: (params: any) => {
          return (
            <>
              {params.row.trial_app_status && (
                <StatusCard status={params.row.trial_app_status}>
                  {params.row.trial_app_status}
                </StatusCard>
              )}
            </>
          );
        },
      },
      {
        field: "creation_date",
        headerName: "Creation Date",
        minWidth: sm ? 200 : null,
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return formatDate(params.row?.creation_date);
        },
      },
      {
        field: "first_login",
        headerName: "First Login Date",
        minWidth: sm ? 200 : null,
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return formatDate(params.row?.first_login);
        },
      },
      {
        field: "uid",
        headerName: "UID",
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return params.row.uid;
        },
      },
      {
        field: "signup_method",
        headerName: "Signup Method",
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return params.row?.signup_method;
        },
      },
      {
        field: "valid_captures",
        headerName: "Valid Captures",
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return params.row.valid_captures;
        },
      },
      {
        field: "invalid_captures",
        headerName: "Invalid Captures",
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return params.row.invalid_captures;
        },
      },
      {
        field: "brand",
        headerName: "Brand",
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return params.row.brand;
        },
      },
    ];

    setColumns([...columnData]);
  }, [rows, loading]);

  const handleUserStatus = async (users: any[]) => {
    const token = await currentUser.getIdToken();

    updateUserPermissions({ users, token }).then((res: any) => {
      fetchUsers();
    });
  };

  return (
    <>
      <Dialog fullWidth open={modalOpen} onClose={() => setModalOpen(false)}>
        <CESSignup handleOpen={setModalOpen} />
      </Dialog>
      <Box
        component="div"
        sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
      >
        <Box component="div">
          <Typography
            variant="h3"
            sx={{ fontWeight: "400", fontSize: "3.438rem" }}
          >
            Clients
          </Typography>
        </Box>
        <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
          <Button2
            onClick={() => setModalOpen(true)}
            iconButton
            icon="plus"
            iconAlignment="left"
          >
            Add User
          </Button2>
        </Box>
      </Box>
      <Box component="div" sx={{ mt: 2 }}>
        <div
          style={{
            height: "calc(100vh - 132px)",
            width: "100%",
            minHeight: "400px",
          }}
        >
          <DataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            rowHeight={72}
            getRowId={(row: any) => row.uid}
            components={{
              Toolbar: GridToolbar,
              LoadingOverlay: LinearProgress,
            }}
            disableSelectionOnClick
            sx={{
              border: "none",
              "& .MuiDataGrid-columnSeparator": { display: "none" },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  uid: false,
                  signup_method: false,
                  valid_captures: false,
                  invalid_captures: false,
                  brand: false
                },
              },
            }}
            // onSelectionModelChange={(newSelectionModel) => {
            //   setSelectionModel(newSelectionModel);
            // }}
            // selectionModel={selectionModel}
          />
        </div>
      </Box>
    </>
  );
}

interface StatusCardProps {
  status: string;
}

const StatusCard = styled(Box)<StatusCardProps>`
  color: ${(props) =>
    props.status === "active"
      ? "#7FC008"
      : props.status === "inactive"
      ? "#DB8C28"
      : props.status === "Ended"
      ? "#DB303F"
      : "#00072B"};

  border: 1px solid;
  border-color: ${(props) =>
    props.status === "active"
      ? "#7FC008"
      : props.status === "inactive"
      ? "#DB8C28"
      : props.status === "Ended"
      ? "#DB303F"
      : "#00072B"};
  border-radius: 4px;
  text-align: center;
  padding: 2px 16px;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export default UserDashboard;
