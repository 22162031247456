const MaleFrontC: any[] = [
  <>
    <g
      id="CWl"
      data-name="Group 17357"
      transform="translate(265.873 552.669) rotate(180)"
    >
      <path
        id="m-G-right-circ-base-front-3"
        d="M167.6-.343C84.092,4.826.588,14.156-.038,18.08"
        transform="translate(38.465 1.299) rotate(94)"
        fill="none"
        stroke="#5c76aa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
    <g
      id="BCl"
      data-name="Group 17357"
      transform="translate(265.873 552.669) rotate(180)"
    >
      <path
        id="m-G-right-circ-base-back-3"
        d="M.049,2.026c-.565,5.618-.174,19.383,183.028-2.4"
        transform="translate(24.326 1.114) rotate(94)"
        fill="none"
        stroke="#5c76aa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        strokeDasharray="10"
        opacity="0.25"
        style={{ isolation: "isolate" }}
      />
    </g>
    <g
      id="HEc"
      data-name="Group 17776"
      transform="translate(-796.055 -117.225)"
    >
      <g id="Group_17775" data-name="Group 17775">
        <g
          id="Group_17486"
          data-name="Group 17486"
          transform="translate(989.134 170.509) rotate(-90)"
        >
          <path
            id="m-G-right-circ-base-back-3-2"
            data-name="m-G-right-circ-base-back-3"
            d="M.023,6.945c-.31,4.209,23.107,5.977,52.3,4.147S105.393,4.461,105.7.321"
            transform="translate(25.497 1.105) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-2"
            data-name="m-G-right-circ-base-front-3"
            d="M105.843,3.356c.383-3.1-23.008-4.016-52.22-2.13S.392,7.249.03,10.187"
            transform="translate(27.329 0.681) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <circle
          id="Ellipse_23"
          data-name="Ellipse 23"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(991 145) rotate(90)"
          fill="#5c76aa"
        />
      </g>
      <path
        id="Path_12264"
        data-name="Path 12264"
        d="M-20.64,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM-8.9-2.02c.02-.1-.02-.16-.14-.16H-15.1V-5.62h5.2c.08,0,.14-.02.14-.12v-1.9c0-.12-.04-.14-.14-.14h-5.2v-3.18h5.72c.12,0,.16-.08.14-.18L-9.46-13c-.02-.12-.06-.14-.18-.14h-7.72c-.12,0-.16.06-.16.16V-.16c0,.1.04.16.18.16h7.96c.12,0,.16-.02.18-.16Zm8.4-.04c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(972.5 158.5)"
        fill="#5c76aa"
      />
    </g>
    <circle
      id="Ellipse_35"
      data-name="Ellipse 35"
      cx="7.5"
      cy="7.5"
      r="7.5"
      transform="translate(170.695 951.275) rotate(90)"
      fill="#5c76aa"
    />
    <g id="Nc" data-name="Group 17777" transform="translate(-796.055 -117.225)">
      <g
        id="Group_17485"
        data-name="Group 17485"
        transform="translate(1086.91 238.206) rotate(90)"
      >
        <path
          id="m-G-right-circ-base-back-3-3"
          data-name="m-G-right-circ-base-back-3"
          d="M-.039,4.823c-.218,4.2,19.652,6.218,44.377,4.7S89.259,3.463,89.473-.671"
          transform="translate(23.376 0.921) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-3"
          data-name="m-G-right-circ-base-front-3"
          d="M89.555,2.9C89.846-.2,70.007-1.361,45.265.213S.2,5.665-.072,8.6"
          transform="translate(25.735 0.672) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_24"
        data-name="Ellipse 24"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(997.75 248) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12263"
        data-name="Path 12263"
        d="M-18.56,0c.12,0,.16-.06.16-.18V-6.86c0-1.82-.02-2.7-.1-3.72h.02C-17.7-8.64-14.04-2.18-13-.14a.254.254,0,0,0,.26.14h2.54c.14,0,.2-.04.2-.18V-12.96c0-.12-.04-.18-.18-.18H-12.1c-.12,0-.18.06-.18.16v6.66c0,1.88.04,2.54.06,3.1h-.02c-.7-1.5-3.76-7.14-5.24-9.78a.212.212,0,0,0-.24-.14h-2.82c-.1,0-.14.04-.14.14V-.18c0,.16.06.18.18.18ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(979.25 261.5)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="CHc"
      data-name="Group 17778"
      transform="translate(-796.055 -117.225)"
    >
      <g
        id="Group_17487"
        data-name="Group 17487"
        transform="translate(1137.229 378.445) rotate(90)"
      >
        <path
          id="m-G-right-circ-base-back-3-4"
          data-name="m-G-right-circ-base-back-3"
          d="M-.077,10.852c-.708,5.2,40.713,6.5,92.5,3.135S186.7,3.8,187.4-1.316"
          transform="translate(31.715 1.977) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-4"
          data-name="m-G-right-circ-base-front-3"
          d="M187.618,1.966c.8-3.84-40.613-4.081-92.438-.65S.641,10.758-.112,14.395"
          transform="translate(33.52 1.116) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_25"
        data-name="Ellipse 25"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(955.75 391) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12261"
        data-name="Path 12261"
        d="M-22.54-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-29.8-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9a.212.212,0,0,0-.12-.22,6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-32.3-1.98-29.14.2-25.44.2a8.624,8.624,0,0,0,2.76-.36.254.254,0,0,0,.14-.26ZM-10.14,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(1052.49 396.5)"
        fill="#5c76aa"
      />
    </g>
    <g id="W1" data-name="Group 17779" transform="translate(-796.055 -117.225)">
      <g id="Group_17779" transform="translate(1137.229 467.198) rotate(90)">
        <path
          id="m-G-right-circ-base-back-3-5"
          data-name="m-G-right-circ-base-back-3"
          d="M.006,11.871c-.586,6.986,41.03,9.724,92.94,6.445S187.358,6.872,187.934,0"
          transform="translate(38.257 1.968) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-5"
          data-name="m-G-right-circ-base-front-3"
          d="M188.175,4.958c.706-5.151-40.872-6.471-92.82-3.1S.669,12.3,0,17.175"
          transform="translate(41.221 1.198) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_26"
        data-name="Ellipse 26"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1147.25 485) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12260"
        data-name="Path 12260"
        d="M-13.82-12.98c-.04-.12-.06-.16-.16-.16H-16.3a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-20.36-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18H-9.2a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C-12.08-5.66-13.04-9.68-13.82-12.98ZM-1.92,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H-3.28a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(1086 489.5)"
        fill="#5c76aa"
      />
    </g>
    <g id="W2" data-name="Group 17780" transform="translate(-796.055 -117.225)">
      <g id="Hicp" transform="translate(1145.324 533.198) rotate(90)">
        <path
          id="m-G-right-circ-base-back-3-6"
          data-name="m-G-right-circ-base-back-3"
          d="M.068,13.927c-.677,6.992,44.462,9.484,100.806,5.9S203.382,7.811,204.048.933"
          transform="translate(40.312 2.147) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-6"
          data-name="m-G-right-circ-base-front-3"
          d="M204.332,5.48c.8-5.157-44.309-6.231-100.7-2.548S.836,13.938.081,18.821"
          transform="translate(42.868 1.232) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_27"
        data-name="Ellipse 27"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1157.25 549) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12259"
        data-name="Path 12259"
        d="M-16.94-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16C-21.16-6.44-21.76-4.24-22-2.94h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14H-26.7c-.1,0-.14.04-.12.16L-23.48-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C-15.2-5.66-16.16-9.68-16.94-12.98ZM-1.16,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14H-3c-1.42,0-2.18,0-2.94.06A23.064,23.064,0,0,0-3.46-4.18,6.132,6.132,0,0,0-1.2-8.8a3.916,3.916,0,0,0-4.3-4.06A7.391,7.391,0,0,0-9.02-12a.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02A42.211,42.211,0,0,1-9.34-1.74c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(1091 556.5)"
        fill="#5c76aa"
      />
    </g>
    <g id="Hc" data-name="Group 17805" transform="translate(-796.055 -117.225)">
      <circle
        id="Ellipse_28"
        data-name="Ellipse 28"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1167.964 606.975) rotate(90)"
        fill="#5c76aa"
      />
      <g id="Group_17803" data-name="Group 17803">
        <g id="qqqqqqqqq" transform="translate(1158.553 593.911) rotate(90)">
          <path
            id="m-G-right-circ-base-back-3-7"
            data-name="m-G-right-circ-base-back-3"
            d="M-.083,13.768c-.933,5.644,50.328,6.6,114.471,2.394S231.212,4.1,232.13-1.45"
            transform="translate(36.03 2.459) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-7"
            data-name="m-G-right-circ-base-front-3"
            d="M232.41,1.776c1.027-4.17-50.231-3.982-114.424.3S.854,13.331-.118,17.279"
            transform="translate(37.661 1.324) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <path
          id="Path_12262"
          data-name="Path 12262"
          d="M-10.14,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
          transform="translate(1089 612)"
          fill="#5c76aa"
        />
      </g>
    </g>
    <g
      id="Wc3"
      data-name="Group 17789"
      transform="translate(-796.055 -117.225)"
    >
      <g id="WBc3" transform="translate(847.793 584.95)">
        <g
          id="Group_17241"
          data-name="Group 17241"
          transform="translate(386.327) rotate(72)"
        >
          <path
            id="m-G-right-circ-base-back-3-8"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.2C-.067.808,9.077-.5,20.425.176s20.59,3.106,20.657,5.462"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 2.866, 41.375)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-8"
            data-name="m-G-right-circ-base-front-3"
            d="M41.132,2.552c.11,1.763-9.014,2.58-20.369,1.875S.1,1.67,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 5.259, 41.401)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17358"
          data-name="Group 17358"
          transform="matrix(-0.309, 0.951, -0.951, -0.309, 42.443, 12.794)"
        >
          <path
            id="m-G-right-circ-base-back-3-9"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.436c-.067,2.395,9.077,3.7,20.424,3.026S41.015,2.356,41.081,0"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 8.49, 0.42)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-9"
            data-name="m-G-right-circ-base-front-3"
            d="M41.132,2.128C41.242.365,32.119-.452,20.763.252S.1,3.01,0,4.68"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 9.928, 0.326)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_38"
        data-name="Ellipse 38"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1249.25 579) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12251"
        data-name="Path 12251"
        d="M-25.2-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-31.74-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-22.18-6.7-22.7-4.04-22.9-2.92h-.02C-23.46-5.66-24.42-9.68-25.2-12.98ZM-10.54-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.86.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Zm4.6-3.48c1.72,0,2.6.6,2.6,1.78,0,1.02-.74,1.82-2.6,1.82a6.473,6.473,0,0,1-3.18-.84c-.08-.04-.18,0-.18.08V-.72c0,.1.02.22.12.26A7.033,7.033,0,0,0-5.86.2c2.74,0,5-1.38,5-3.9A3.152,3.152,0,0,0-3.28-6.82,2.9,2.9,0,0,0-1.46-9.48c0-2.02-1.58-3.38-4.16-3.38A7.152,7.152,0,0,0-9-12.08c-.12.06-.1.1-.1.2v1.84c0,.08.02.12.14.08a5.7,5.7,0,0,1,2.88-.76c1.52,0,2.26.62,2.26,1.56,0,1-.84,1.48-2.3,1.48h-1c-.1,0-.12.06-.12.18v1.82c0,.1.04.14.14.14Z"
        transform="translate(1287.275 592.5)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="Wc1"
      data-name="Group 17790"
      transform="translate(-796.055 -117.225)"
    >
      <g id="WBc1" transform="translate(842.476 599.828)">
        <g
          id="Group_17239"
          data-name="Group 17239"
          transform="translate(396.961) rotate(72)"
        >
          <path
            id="m-G-right-circ-base-back-3-10"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.2C-.067.808,9.077-.5,20.425.176s20.59,3.106,20.657,5.462"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 2.866, 41.375)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-10"
            data-name="m-G-right-circ-base-front-3"
            d="M41.132,2.552c.11,1.763-9.014,2.58-20.369,1.875S.1,1.67,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 5.259, 41.401)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17360"
          data-name="Group 17360"
          transform="matrix(-0.309, 0.951, -0.951, -0.309, 42.443, 12.794)"
        >
          <path
            id="m-G-right-circ-base-back-3-11"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.436c-.067,2.395,9.077,3.7,20.424,3.026S41.015,2.356,41.081,0"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 8.49, 0.42)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-11"
            data-name="m-G-right-circ-base-front-3"
            d="M41.132,2.128C41.242.365,32.119-.452,20.763.252S.1,3.01,0,4.68"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 9.928, 0.326)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_40"
        data-name="Ellipse 40"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1254.25 597) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12250"
        data-name="Path 12250"
        d="M-21.94-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16C-26.16-6.44-26.76-4.24-27-2.94h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14H-31.7c-.1,0-.14.04-.12.16L-28.48-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C-20.2-5.66-21.16-9.68-21.94-12.98ZM-7.28-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-9.6.2,6.092,6.092,0,0,0-7.38-.12c.08-.02.1-.06.1-.16ZM-1.92,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H-3.28a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(1289.75 610.5)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="Fc5"
      data-name="Group 17792"
      transform="translate(-796.055 -117.225)"
    >
      <g id="THc" transform="translate(815.205 636.313)">
        <g
          id="Group_17238"
          data-name="Group 17238"
          transform="translate(444.271 15.682) rotate(-135)"
        >
          <path
            id="m-G-right-circ-base-back-3-12"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.958C.086,1.574,3.321-.143,7.231.009s7.008,2.156,6.924,4.5"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.987, 14.566)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-12"
            data-name="m-G-right-circ-base-front-3"
            d="M14.168.667c-.042,1.752-3.248,2.983-7.159,2.8S-.04,1.66,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 4.135, 14.376)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17361"
          data-name="Group 17361"
          transform="translate(0 5.382) rotate(-45)"
        >
          <path
            id="m-G-right-circ-base-back-3-13"
            data-name="m-G-right-circ-base-back-3"
            d="M0,.553C.086,2.937,3.321,4.654,7.231,4.5S14.239,2.345,14.155,0"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 5.487, 0.315)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-13"
            data-name="m-G-right-circ-base-front-3"
            d="M14.168,2.817C14.125,1.065,10.92-.166,7.009.018S-.04,1.824,0,3.484"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 7.611, 0.433)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_41"
        data-name="Ellipse 41"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1279.75 628.475) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12249"
        data-name="Path 12249"
        d="M-24.7-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16h-7.72c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16Zm14.16,3.24c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.86.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16ZM-2-10.54c.12,0,.16-.04.18-.14l.52-1.82c.04-.12,0-.16-.1-.16H-7.46c-.12,0-.14.02-.18.14-.04.1-1.24,4.4-1.36,4.92v.98c0,.12.08.14.18.14C-4.9-6.16-3.34-5.4-3.34-3.8c0,1.02-.66,1.88-2.48,1.88A6.9,6.9,0,0,1-8.98-2.7a.086.086,0,0,0-.14.08V-.66c0,.14.04.2.12.24A7.349,7.349,0,0,0-5.8.2C-2.84.2-.9-1.48-.9-3.96c0-2.44-1.66-3.96-5.78-4.4.12-.5.4-1.62.54-2.18Z"
        transform="translate(1311.75 641.975)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="Fc1"
      data-name="Group 17793"
      transform="translate(-796.055 -117.225)"
    >
      <g id="IFc" transform="translate(823.045 668.589)">
        <g
          id="Group_17237"
          data-name="Group 17237"
          transform="matrix(-0.259, -0.966, 0.966, -0.259, 428.629, 8.11)"
        >
          <path
            id="m-G-right-circ-base-back-3-14"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.887C.063,1.148,2.422-.1,5.273.007s5.111,1.573,5.05,3.283"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.72, 10.623)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-14"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332.486C10.3,1.764,7.964,2.662,5.111,2.528S-.029,1.21,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 3.016, 10.484)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17362"
          data-name="Group 17362"
          transform="matrix(0.259, -0.966, 0.966, 0.259, 0, 5.361)"
        >
          <path
            id="m-G-right-circ-base-back-3-15"
            data-name="m-G-right-circ-base-back-3"
            d="M0,.4c.063,1.738,2.422,2.991,5.273,2.88S10.384,1.71,10.323,0"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 4.002, 0.229)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-15"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332,2.055C10.3.777,7.964-.121,5.111.013S-.029,1.331,0,2.541"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 5.55, 0.316)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_42"
        data-name="Ellipse 42"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1272.75 662.5) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12248"
        data-name="Path 12248"
        d="M-21.44-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16H-23.7c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16ZM-7.28-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-9.6.2,6.092,6.092,0,0,0-7.38-.12c.08-.02.1-.06.1-.16ZM-1.92,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H-3.28a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(1301.75 676)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="Fc2"
      data-name="Group 17796"
      transform="translate(-796.055 -117.225)"
    >
      <g id="MFc" transform="translate(835.679 672.492)">
        <g
          id="Group_17236"
          data-name="Group 17236"
          transform="translate(403.464 8.414) rotate(-107)"
        >
          <path
            id="m-G-right-circ-base-back-3-16"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.887C.063,1.148,2.422-.1,5.273.007s5.111,1.573,5.05,3.283"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.72, 10.623)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-16"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332.486C10.3,1.764,7.964,2.662,5.111,2.528S-.029,1.21,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 3.016, 10.484)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17363"
          data-name="Group 17363"
          transform="translate(0 5.309) rotate(-73)"
        >
          <path
            id="m-G-right-circ-base-back-3-17"
            data-name="m-G-right-circ-base-back-3"
            d="M0,.4c.063,1.738,2.422,2.991,5.273,2.88S10.384,1.71,10.323,0"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 4.002, 0.229)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-17"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332,2.055C10.3.777,7.964-.121,5.111.013S-.029,1.331,0,2.541"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 5.55, 0.316)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_43"
        data-name="Ellipse 43"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(839 704) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12245"
        data-name="Path 12245"
        d="M-24.56-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16h-7.72c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16ZM-10.4-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-15.4-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.72.2,6.092,6.092,0,0,0-10.5-.12c.08-.02.1-.06.1-.16ZM-1.16,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14H-3c-1.42,0-2.18,0-2.94.06A23.064,23.064,0,0,0-3.46-4.18,6.132,6.132,0,0,0-1.2-8.8a3.916,3.916,0,0,0-4.3-4.06A7.391,7.391,0,0,0-9.02-12a.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02A42.211,42.211,0,0,1-9.34-1.74c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(845 735)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="Fc4"
      data-name="Group 17795"
      transform="translate(-796.055 -117.225)"
    >
      <g id="LFc" transform="translate(859.713 671.31)">
        <g
          id="Group_17234"
          data-name="Group 17234"
          transform="matrix(0.326, -0.946, 0.946, 0.326, 353.703, 5.248)"
        >
          <path
            id="m-G-right-circ-base-back-3-18"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.887C.063,1.148,2.422-.1,5.273.007s5.111,1.573,5.05,3.283"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.72, 10.623)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-18"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332.486C10.3,1.764,7.964,2.662,5.111,2.528S-.029,1.21,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 3.016, 10.484)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17365"
          data-name="Group 17365"
          transform="translate(1.807 8.706) rotate(-109)"
        >
          <path
            id="m-G-right-circ-base-back-3-19"
            data-name="m-G-right-circ-base-back-3"
            d="M0,.4c.063,1.738,2.422,2.991,5.273,2.88S10.384,1.71,10.323,0"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 4.002, 0.229)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-19"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332,2.055C10.3.777,7.964-.121,5.111.013S-.029,1.331,0,2.541"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 5.55, 0.315)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_44"
        data-name="Ellipse 44"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(875 696) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12244"
        data-name="Path 12244"
        d="M-25.28-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16h-7.72c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16Zm14.16,3.24c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-13.44.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Zm6.84-.96V-.16A.147.147,0,0,0-4.12,0h2c.08,0,.14-.04.14-.16V-3.02H-.76c.1,0,.14-.04.16-.1l.26-1.72c.02-.08,0-.14-.12-.14H-1.98v-7.54c0-.1-.06-.14-.16-.14h-2.5a.289.289,0,0,0-.28.12L-10-4.98a.374.374,0,0,0-.1.28v1.56c0,.08.04.12.16.12Zm0-1.96H-7.62c.6-.9,2.94-4.48,3.44-5.48h.02c-.04.7-.12,1.96-.12,3.7Z"
        transform="translate(885 727)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="Fc3"
      data-name="Group 17794"
      transform="translate(-796.055 -117.225)"
    >
      <g id="RFc" transform="translate(848.397 673.737)">
        <g
          id="Group_17235"
          data-name="Group 17235"
          transform="matrix(0.07, -0.998, 0.998, 0.07, 377.203, 5.537)"
        >
          <path
            id="m-G-right-circ-base-back-3-20"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.887C.063,1.148,2.422-.1,5.273.007s5.111,1.573,5.05,3.283"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.72, 10.623)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-20"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332.486C10.3,1.764,7.964,2.662,5.111,2.528S-.029,1.21,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 3.016, 10.484)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17364"
          data-name="Group 17364"
          transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.387, 6.278)"
        >
          <path
            id="m-G-right-circ-base-back-3-21"
            data-name="m-G-right-circ-base-back-3"
            d="M0,.4c.063,1.738,2.422,2.991,5.273,2.88S10.384,1.71,10.323,0"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 4.002, 0.229)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-21"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332,2.055C10.3.777,7.964-.121,5.111.013S-.029,1.331,0,2.541"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 5.55, 0.315)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_45"
        data-name="Ellipse 45"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1239 705) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12246"
        data-name="Path 12246"
        d="M-24.7-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16h-7.72c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16Zm14.16,3.24c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.86.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Zm4.6-3.48c1.72,0,2.6.6,2.6,1.78,0,1.02-.74,1.82-2.6,1.82a6.473,6.473,0,0,1-3.18-.84c-.08-.04-.18,0-.18.08V-.72c0,.1.02.22.12.26A7.033,7.033,0,0,0-5.86.2c2.74,0,5-1.38,5-3.9A3.152,3.152,0,0,0-3.28-6.82,2.9,2.9,0,0,0-1.46-9.48c0-2.02-1.58-3.38-4.16-3.38A7.152,7.152,0,0,0-9-12.08c-.12.06-.1.1-.1.2v1.84c0,.08.02.12.14.08a5.7,5.7,0,0,1,2.88-.76c1.52,0,2.26.62,2.26,1.56,0,1-.84,1.48-2.3,1.48h-1c-.1,0-.12.06-.12.18v1.82c0,.1.04.14.14.14Z"
        transform="translate(1245 736)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="Wc2"
      data-name="Group 17791"
      transform="translate(-796.055 -117.225)"
    >
      <g id="WBc2" transform="translate(845.104 592.745)">
        <g
          id="Group_17240"
          data-name="Group 17240"
          transform="translate(391.706) rotate(72)"
        >
          <path
            id="m-G-right-circ-base-back-3-22"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.2C-.067.808,9.077-.5,20.425.176s20.59,3.106,20.657,5.462"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 2.866, 41.375)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-22"
            data-name="m-G-right-circ-base-front-3"
            d="M41.132,2.552c.11,1.763-9.014,2.58-20.369,1.875S.1,1.67,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 5.259, 41.401)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17359"
          data-name="Group 17359"
          transform="matrix(-0.309, 0.951, -0.951, -0.309, 42.443, 12.794)"
        >
          <path
            id="m-G-right-circ-base-back-3-23"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.436c-.067,2.395,9.077,3.7,20.424,3.026S41.015,2.356,41.081,0"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 8.49, 0.42)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-23"
            data-name="m-G-right-circ-base-front-3"
            d="M41.132,2.128C41.242.365,32.119-.452,20.763.252S.1,3.01,0,4.68"
            transform="matrix(-0.07, 0.998, -0.998, -0.07, 9.928, 0.326)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_39"
        data-name="Ellipse 39"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(847.5 587) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12243"
        data-name="Path 12243"
        d="M-25.06-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-31.6-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C-23.32-5.66-24.28-9.68-25.06-12.98ZM-10.4-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-15.4-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.72.2,6.092,6.092,0,0,0-10.5-.12c.08-.02.1-.06.1-.16ZM-1.16,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14H-3c-1.42,0-2.18,0-2.94.06A23.064,23.064,0,0,0-3.46-4.18,6.132,6.132,0,0,0-1.2-8.8a3.916,3.916,0,0,0-4.3-4.06A7.391,7.391,0,0,0-9.02-12a.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02A42.211,42.211,0,0,1-9.34-1.74c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(831 600.5)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="uTc"
      data-name="Group 17781"
      transform="translate(-796.055 -117.225)"
    >
      <g id="UTHcp" transform="translate(929.622 679.317)">
        <g
          id="Group_17342"
          data-name="Group 17342"
          transform="translate(223.737 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-24"
            data-name="m-G-right-circ-base-back-3"
            d="M0,7.133c-.372,3.838,24.408,5.207,55.339,3.236S111.617,3.776,111.982,0"
            transform="translate(23.726 1.176) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-24"
            data-name="m-G-right-circ-base-front-3"
            d="M112.127,2.6C112.565-.236,87.8-.825,56.848,1.2S.414,7.238,0,9.919"
            transform="translate(25.227 0.692) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17343"
          data-name="Group 17343"
          transform="translate(112.886 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-25"
            data-name="m-G-right-circ-base-back-3"
            d="M0,7.133c-.372,3.838,24.408,5.207,55.339,3.236S111.617,3.776,111.982,0"
            transform="translate(23.726 1.176) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-25"
            data-name="m-G-right-circ-base-front-3"
            d="M112.127,2.6C112.565-.236,87.8-.825,56.848,1.2S.414,7.238,0,9.919"
            transform="translate(25.227 0.692) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_29"
        data-name="Ellipse 29"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(923.25 684)"
        fill="#5c76aa"
      />
      <path
        id="Path_12252"
        data-name="Path 12252"
        d="M-17.98-9.72c0-.1-.02-.14-.14-.14h-2.1c-.12,0-.16.04-.16.14v7.1a2.536,2.536,0,0,1-1.82.72c-1.2,0-1.74-.76-1.74-2V-9.72c0-.1-.02-.14-.14-.14h-2.1c-.12,0-.16.02-.16.14v6.1c0,2.8,1.46,3.82,3.36,3.82A3.952,3.952,0,0,0-20.2-.82a4.338,4.338,0,0,0,.14.7c.02.08.06.12.2.12h1.84c.12,0,.18-.04.16-.14a17.756,17.756,0,0,1-.12-2.06Zm4.52,9.54c0,.14.04.2.18.18h2.06c.1,0,.16-.06.16-.18V-10.96h4.04c.1,0,.16-.06.14-.18l-.18-1.82c-.02-.14-.08-.18-.2-.18H-17.2c-.12,0-.16.04-.18.16l-.26,1.86c-.02.1.02.16.12.16h4.06ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(1007.06 685)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="mTc"
      data-name="Group 17782"
      transform="translate(-796.055 -117.225)"
    >
      <g id="MTHcp" transform="translate(929.622 735.324)">
        <g
          id="Group_17345"
          data-name="Group 17345"
          transform="translate(223.737 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-26"
            data-name="m-G-right-circ-base-back-3"
            d="M0,7.133c-.372,3.838,24.408,5.207,55.339,3.236S111.617,3.776,111.982,0"
            transform="translate(23.726 1.176) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-26"
            data-name="m-G-right-circ-base-front-3"
            d="M112.127,2.6C112.565-.236,87.8-.825,56.848,1.2S.414,7.238,0,9.919"
            transform="translate(25.227 0.692) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17344"
          data-name="Group 17344"
          transform="translate(112.886 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-27"
            data-name="m-G-right-circ-base-back-3"
            d="M0,7.133c-.372,3.838,24.408,5.207,55.339,3.236S111.617,3.776,111.982,0"
            transform="translate(23.726 1.176) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-27"
            data-name="m-G-right-circ-base-front-3"
            d="M112.127,2.6C112.565-.236,87.8-.825,56.848,1.2S.414,7.238,0,9.919"
            transform="translate(25.227 0.692) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_30"
        data-name="Ellipse 30"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1167.25 738) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12247"
        data-name="Path 12247"
        d="M-29.24,0c.12,0,.16-.04.16-.16V-7.22a2.814,2.814,0,0,1,1.9-.74c1.08,0,1.68.64,1.68,1.96V-.18c0,.14.06.18.18.18h2.08c.12,0,.14-.06.14-.16V-5.98a9.15,9.15,0,0,0-.06-1.18,2.833,2.833,0,0,1,1.96-.82c1.22,0,1.68.9,1.68,2.04V-.18c0,.12.04.18.18.18h2.06c.1,0,.18-.04.18-.16v-6c0-3.02-1.72-3.9-3.38-3.9a4.929,4.929,0,0,0-3.28,1.32,2.8,2.8,0,0,0-2.58-1.32,4.269,4.269,0,0,0-2.92,1.12,3.39,3.39,0,0,0-.12-.8.19.19,0,0,0-.2-.12h-1.84c-.12,0-.18.04-.16.14.04.38.08,1.2.08,1.98V-.18c0,.12.04.18.16.18Zm15.78-.18c0,.14.04.2.18.18h2.06c.1,0,.16-.06.16-.18V-10.96h4.04c.1,0,.16-.06.14-.18l-.18-1.82c-.02-.14-.08-.18-.2-.18H-17.2c-.12,0-.16.04-.18.16l-.26,1.86c-.02.1.02.16.12.16h4.06ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(1116.5 736)"
        fill="#5c76aa"
      />
    </g>
    <g id="Kc" data-name="Group 17783" transform="translate(-796.055 -117.225)">
      <g id="Kcp" transform="translate(943.706 837.16)">
        <g
          id="Group_17346"
          data-name="Group 17346"
          transform="translate(92.717 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-28"
            data-name="m-G-right-circ-base-back-3"
            d="M0,5.859C-.3,9.011,20.051,10.135,45.455,8.517S91.675,3.1,91.975,0"
            transform="translate(20.323 0.966) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-28"
            data-name="m-G-right-circ-base-front-3"
            d="M92.094,2.131C92.453-.194,72.116-.678,46.692.983S.34,5.945,0,8.147"
            transform="translate(21.556 0.568) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17347"
          data-name="Group 17347"
          transform="translate(196.717 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-29"
            data-name="m-G-right-circ-base-back-3"
            d="M0,5.859C-.3,9.011,20.051,10.135,45.455,8.517S91.675,3.1,91.975,0"
            transform="translate(20.323 0.966) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-29"
            data-name="m-G-right-circ-base-front-3"
            d="M92.094,2.131C92.453-.194,72.116-.678,46.692.983S.34,5.945,0,8.147"
            transform="translate(21.556 0.568) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_31"
        data-name="Ellipse 31"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(945.75 838) rotate(90)"
        fill="#5c76aa"
      />
      <text
        id="Kc"
        transform="translate(927.25 849)"
        fill="#5c76aa"
        fontSize="20"
        fontFamily="AdobeClean-Bold, Adobe Clean"
        fontWeight="700"
      >
        <tspan x="-20.24" y="0">
          Kc
        </tspan>
      </text>
    </g>
    <g id="Fc" data-name="Group 17784" transform="translate(-796.055 -117.225)">
      <g id="Fihcp" transform="translate(943.706 889.888)">
        <g
          id="Group_17348"
          data-name="Group 17348"
          transform="translate(84.999 -5.069) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-30"
            data-name="m-G-right-circ-base-back-3"
            d="M.037,5.856c-.262,3.15,18.411,4.391,41.7,2.92S84.1,3.631,84.356.533"
            transform="translate(20.323 0.886) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-30"
            data-name="m-G-right-circ-base-front-3"
            d="M84.477,2.664C84.793.342,66.137-.26,42.829,1.253S.347,5.944.047,8.143"
            transform="translate(21.556 0.521) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17351"
          data-name="Group 17351"
          transform="translate(196.717 -5.069) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-31"
            data-name="m-G-right-circ-base-back-3"
            d="M.037,5.856c-.262,3.15,18.411,4.391,41.7,2.92S84.1,3.631,84.356.533"
            transform="translate(20.323 0.886) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-31"
            data-name="m-G-right-circ-base-front-3"
            d="M84.477,2.664C84.793.342,66.137-.26,42.829,1.253S.347,5.944.047,8.143"
            transform="translate(21.556 0.521) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_32"
        data-name="Ellipse 32"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1154.25 890.5) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12253"
        data-name="Path 12253"
        d="M3.9-5.3H9c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14H3.9v-3.5H9.58c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16H1.64c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16H3.74c.12,0,.16-.04.16-.16ZM18.06-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0,15.74.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Z"
        transform="translate(1156.75 904.5)"
        fill="#5c76aa"
      />
    </g>
    <g id="Cc" data-name="Group 17828" transform="translate(-796.055 -117.225)">
      <g id="Ccp" transform="translate(943.706 942.532)">
        <g
          id="Group_17350"
          data-name="Group 17350"
          transform="translate(84.999 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-32"
            data-name="m-G-right-circ-base-back-3"
            d="M0,5.371c-.28,2.89,18.378,3.92,41.668,2.437S84.043,2.843,84.318,0"
            transform="translate(19.02 0.886) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-32"
            data-name="m-G-right-circ-base-front-3"
            d="M84.427,1.954C84.757-.178,66.112-.621,42.8.9S.312,5.45,0,7.468"
            transform="translate(20.15 0.521) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17352"
          data-name="Group 17352"
          transform="translate(196.717 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-33"
            data-name="m-G-right-circ-base-back-3"
            d="M0,5.371c-.28,2.89,18.378,3.92,41.668,2.437S84.043,2.843,84.318,0"
            transform="translate(19.02 0.886) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-33"
            data-name="m-G-right-circ-base-front-3"
            d="M84.427,1.954C84.757-.178,66.112-.621,42.8.9S.312,5.45,0,7.468"
            transform="translate(20.15 0.521) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_33"
        data-name="Ellipse 33"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(945.75 942.5) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12254"
        data-name="Path 12254"
        d="M-9.16-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-16.42-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0-9.16-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8,0,4.56,3.16,6.74,6.86,6.74A8.624,8.624,0,0,0-9.3-.16a.254.254,0,0,0,.14-.26Zm8.66.14c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(927.25 954.5)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="UCc"
      data-name="Group 17785"
      transform="translate(-796.055 -117.225)"
    >
      <g id="UCcp" transform="translate(961.913 1041.896)">
        <g
          id="Group_17353"
          data-name="Group 17353"
          transform="translate(56.374 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-34"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.562c-.186,1.917,12.189,2.6,27.636,1.616S55.74,1.886,55.922,0"
            transform="translate(14.19 0.587) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-34"
            data-name="m-G-right-circ-base-front-3"
            d="M55.995,1.3C56.213-.118,43.847-.412,28.389.6S.207,3.614,0,4.953"
            transform="translate(14.939 0.346) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17354"
          data-name="Group 17354"
          transform="translate(159.338 -4.677) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-35"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.562c-.186,1.917,12.189,2.6,27.636,1.616S55.74,1.886,55.922,0"
            transform="translate(14.19 0.587) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-35"
            data-name="m-G-right-circ-base-front-3"
            d="M55.995,1.3C56.213-.118,43.847-.412,28.389.6S.207,3.614,0,4.953"
            transform="translate(14.939 0.346) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_34"
        data-name="Ellipse 34"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1134.25 1039.5) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12256"
        data-name="Path 12256"
        d="M-28.96-12.98c0-.12-.04-.16-.16-.16h-2.1c-.14,0-.16.06-.16.18v8.04C-31.38-1.48-29.36.2-26.2.2c2.96,0,5.26-1.48,5.26-5.22v-7.96c0-.1-.04-.16-.16-.16h-2.1c-.12,0-.16.06-.16.18v7.82c0,2.44-1.18,3.2-2.76,3.2-1.92,0-2.84-1.26-2.84-3.16ZM-9.16-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-16.42-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0-9.16-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8,0,4.56,3.16,6.74,6.86,6.74A8.624,8.624,0,0,0-9.3-.16a.254.254,0,0,0,.14-.26Zm8.66.14c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(1171.75 1053)"
        fill="#5c76aa"
      />
    </g>
    <g id="Ac" data-name="Group 17786" transform="translate(-796.055 -117.225)">
      <g id="ANcp" transform="translate(965.178 1072.326)">
        <g
          id="Group_17355"
          data-name="Group 17355"
          transform="translate(47.843 -5.106) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-36"
            data-name="m-G-right-circ-base-back-3"
            d="M.039,3.56C-.1,5.473,10.42,6.286,23.53,5.466S47.366,2.471,47.5.589"
            transform="translate(14.19 0.498) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-36"
            data-name="m-G-right-circ-base-front-3"
            d="M47.576,1.885C47.747.474,37.24.05,24.121.9S.214,3.614.052,4.95"
            transform="translate(14.939 0.293) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17356"
          data-name="Group 17356"
          transform="translate(153.808 -5.106) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-37"
            data-name="m-G-right-circ-base-back-3"
            d="M.039,3.56C-.1,5.473,10.42,6.286,23.53,5.466S47.366,2.471,47.5.589"
            transform="translate(14.19 0.498) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-37"
            data-name="m-G-right-circ-base-front-3"
            d="M47.576,1.885C47.747.474,37.24.05,24.121.9S.214,3.614.052,4.95"
            transform="translate(14.939 0.293) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <path
        id="Path_12255"
        data-name="Path 12255"
        d="M-12.42-3.46l1.16,3.34a.2.2,0,0,0,.2.12h2.24c.12,0,.14-.06.12-.18l-4.62-12.84c-.02-.1-.04-.12-.14-.12h-2.78c-.08,0-.12.06-.12.14a3.058,3.058,0,0,1-.18,1.08L-20.66-.2c-.02.14.02.2.14.2h2a.2.2,0,0,0,.22-.16l1.1-3.3Zm-4.14-2.18c.6-1.82,1.4-4.16,1.72-5.5h.02c.4,1.4,1.34,4.18,1.76,5.5ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(948.75 1080)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="HIc"
      data-name="Group 17787"
      transform="translate(-796.055 -117.225)"
    >
      <g id="HLIc" transform="translate(953.25 1085.63)">
        <g
          id="Group_17386"
          data-name="Group 17386"
          transform="translate(70.94 14.787) rotate(110)"
        >
          <path
            id="m-G-right-circ-base-back-3-38"
            data-name="m-G-right-circ-base-back-3"
            d="M.019,7.4C0,2.345,14.531-.746,32.477.253S64.971,6.047,64.988,11.017"
            transform="matrix(0.07, 0.998, -0.998, 0.07, 22.667, -0.029)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-38"
            data-name="m-G-right-circ-base-front-3"
            d="M65.046,3.864c.109,3.717-14.37,5.78-32.327,4.713S.1,3.52,0,0"
            transform="matrix(0.07, 0.998, -0.998, 0.07, 16.982, 0.111)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17388"
          data-name="Group 17388"
          transform="translate(170.604) rotate(70)"
        >
          <path
            id="m-G-right-circ-base-back-3-39"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.62c-.018,5.052,14.512,8.144,32.458,7.144S64.951,4.97,64.969,0"
            transform="matrix(0.07, -0.998, 0.998, 0.07, 3.532, 64.857)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-39"
            data-name="m-G-right-circ-base-front-3"
            d="M65.046,4.994c.109-3.717-14.37-5.78-32.327-4.713S.1,5.337,0,8.857"
            transform="matrix(0.07, -0.998, 0.998, 0.07, 0, 64.888)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_36"
        data-name="Ellipse 36"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1141.75 1085.848) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12257"
        data-name="Path 12257"
        d="M-15.54,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM-10-12.98c0-.12-.04-.16-.16-.16h-2.1c-.1,0-.16.02-.16.16V-.18c0,.12.04.18.16.18h2.1c.12,0,.16-.04.16-.18ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(1175.75 1098.348)"
        fill="#5c76aa"
      />
    </g>
    <g id="Mc" data-name="Group 17788" transform="translate(-796.055 -117.225)">
      <g id="MEhc" transform="translate(914.19 1106.748)">
        <g
          id="Group_17387"
          data-name="Group 17387"
          transform="matrix(-0.309, 0.951, -0.951, -0.309, 70.79, 12.529)"
        >
          <path
            id="m-G-right-circ-base-back-3-40"
            data-name="m-G-right-circ-base-back-3"
            d="M.019,7.4C0,2.345,14.531-.746,32.477.253S64.971,6.047,64.988,11.017"
            transform="matrix(0.07, 0.998, -0.998, 0.07, 22.667, -0.029)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-40"
            data-name="m-G-right-circ-base-front-3"
            d="M65.046,3.864c.109,3.717-14.37,5.78-32.327,4.713S.1,3.52,0,0"
            transform="matrix(0.07, 0.998, -0.998, 0.07, 16.982, 0.111)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17389"
          data-name="Group 17389"
          transform="matrix(0.309, 0.951, -0.951, 0.309, 249.35, 0.001)"
        >
          <path
            id="m-G-right-circ-base-back-3-41"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.62c-.018,5.052,14.512,8.144,32.458,7.144S64.951,4.97,64.969,0"
            transform="matrix(0.07, -0.998, 0.998, 0.07, 3.532, 64.857)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-41"
            data-name="m-G-right-circ-base-front-3"
            d="M65.046,4.994c.109-3.717-14.37-5.78-32.327-4.713S.1,5.337,0,8.857"
            transform="matrix(0.07, -0.998, 0.998, 0.07, 0, 64.888)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_37"
        data-name="Ellipse 37"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(919.75 1105.412) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12258"
        data-name="Path 12258"
        d="M-23.06-13.02-23.82-.16c-.02.14.02.16.14.16h2c.12,0,.16-.02.16-.14.2-4.2.36-9.28.38-11.3h.04c.56,2.44,2.16,8.26,3.02,11.3.02.12.06.14.2.14h2a.159.159,0,0,0,.18-.16c.8-2.66,2.86-9.34,3.32-11.26h.02c-.02,1.26.02,7.02.12,11.28,0,.12.06.14.16.14h2.22c.1,0,.16-.04.14-.16l-.54-12.86c-.02-.1-.06-.12-.16-.12h-3.12c-.12,0-.14.06-.18.14-.62,2.06-2.6,8.12-3.02,9.82h-.02c-.4-1.86-2.14-7.8-2.74-9.82a.179.179,0,0,0-.2-.14h-3.2C-23-13.14-23.04-13.14-23.06-13.02ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(902.75 1117.912)"
        fill="#5c76aa"
      />
    </g>
  </>,
];

export default MaleFrontC;
