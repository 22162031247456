import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar, GridSelectionModel } from "@mui/x-data-grid";
import { getClientBrands } from "../../Services/Users/ClientBrands";
import { useAuth } from "../../Context/AuthContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import styled from "@emotion/styled";
import Button2 from "../Button2";
import BrandCreate from "../../Pages/Brands/BrandCreate";

function BrandDashboard() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  
  const fetchBrands = async () => {
    setLoading(true);
    let token = await currentUser.getIdToken();
    getClientBrands(token).then((res: any) => {
      if (res instanceof Array) {
        setLoading(false);
        setRows(res);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    let columnData: any = [
      {
        field: "brand",
        headerName: "Brand",
        minWidth: sm ? 280 : null,
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return `${params?.row?.name}`;
        },
        renderCell: (params: any, index: number) => {
          return (
            <Box component="div">
              <Typography variant="caption">{params?.row?.name}</Typography>
            </Box>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: sm ? 280 : null,
        flex: md ? 1 : null,
        valueGetter: (params: any) => {
          return `${params?.row?.email}`;
        },
        renderCell: (params: any) => {
          return (
            <Box component="div">
              <Typography variant="caption">{params?.row?.email}</Typography>
            </Box>
          );
        },
      }
    ];

    setColumns([...columnData]);
  }, [rows, loading]);


  return (
    <>
      <Dialog fullWidth open={modalOpen} onClose={() => setModalOpen(false)}>
        <BrandCreate handleOpen={setModalOpen} />
      </Dialog>
      <Box
        component="div"
        sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
      >
        <Box component="div">
          <Typography
            variant="h3"
            sx={{ fontWeight: "400", fontSize: "3.438rem" }}
          >
            Brands
          </Typography>
        </Box>
        <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
          <Button2
            onClick={() => setModalOpen(true)}
            iconButton
            icon="plus"
            iconAlignment="left"
          >
            Add Brand
          </Button2>
        </Box>
      </Box>
      <Box component="div" sx={{ mt: 2 }}>
        <div
          style={{
            height: "calc(100vh - 132px)",
            width: "100%",
            minHeight: "400px",
          }}
        >
          <DataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            rowHeight={72}
            getRowId={(row: any) => JSON.stringify(row?._id)}
            components={{
              Toolbar: GridToolbar,
              LoadingOverlay: LinearProgress,
            }}
            disableSelectionOnClick
            sx={{
              border: "none",
              "& .MuiDataGrid-columnSeparator": { display: "none" },
            }}
          />
        </div>
      </Box>
    </>
  );
}

interface StatusCardProps {
  status: string;
}

const StatusCard = styled(Box)<StatusCardProps>`
  color: ${(props) =>
    props.status === "active"
      ? "#7FC008"
      : props.status === "inactive"
      ? "#DB8C28"
      : props.status === "Ended"
      ? "#DB303F"
      : "#00072B"};

  border: 1px solid;
  border-color: ${(props) =>
    props.status === "active"
      ? "#7FC008"
      : props.status === "inactive"
      ? "#DB8C28"
      : props.status === "Ended"
      ? "#DB303F"
      : "#00072B"};
  border-radius: 4px;
  text-align: center;
  padding: 2px 16px;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export default BrandDashboard;
