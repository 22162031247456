import React from "react";
import { useTranslation } from "react-i18next";

import AuthLayout from "../../../Layouts/AuthLayout";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "../../../Components/Button2";

function OTPUpdateSuccess() {
  const { t }: { t: any } = useTranslation("auth_new");

  return (
    <>
      <AuthLayout heading={t("first_login")} onSubmit={null} id="first_login">
        <Typography paragraph sx={{ textAlign: "center" }}>
          {t("thank_you_your")}
        </Typography>
        <Typography paragraph sx={{ textAlign: "center" }}>
          {t("you_can_now")}
        </Typography>
        <Box component="div" sx={{ my: 2 }}>
          <Button fullWidth link to="/signin">
            {t("go_to_login")}
          </Button>
        </Box>
      </AuthLayout>
    </>
  );
}

export default OTPUpdateSuccess;
