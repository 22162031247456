import React, { useState, Suspense } from "react";
import styled from "@emotion/styled";

import Box from "@mui/material/Box";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";

import BodySVG from "../BodySVG";
import Body3d from "../Body3d";

interface Props {
  formData: any[];
  dimensions: string;
  gender: string;
}

const Body = ({ formData, dimensions, gender }: Props) => {
  const [reset, setReset] = useState(false);

  const viewsArray = ["Front", "Front C", "Back", "Side"];

  return (
    <Box
      component="div"
      sx={{
        borderRadius: "16px",
        flex: "1",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        position: "relative",
      }}
    >
      <Box
        component="div"
        sx={{
          display: dimensions === "3D" ? "block" : "none",
          position: "absolute",
          top: "8px",
          right: "8px",
          zIndex: "1000",
        }}
      >
        <IconButton onClick={() => setReset(true)} disableRipple>
          <RefreshIcon />
        </IconButton>
      </Box>
      <Box
        component="div"
        sx={{
          py: 2,
        }}
      >
        <Box
          component="div"
          sx={{
            display: dimensions === "2D" ? "flex" : "none",
            gap: { xs: "0px", md: "16px" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <GridContainer>
            {formData && (
              <>
                {viewsArray.map((i: any, index: number) => {
                  return (
                    <GridItem key={index}>
                      <BodySVG formData={formData} gender={gender} view={i} />
                    </GridItem>
                  );
                })}
              </>
            )}
          </GridContainer>
        </Box>

        <Box
          component="div"
          sx={{
            display: dimensions === "3D" ? "block" : "none",
          }}
        >
          <Suspense fallback="">
            <Box
              component="div"
              sx={{
                display: gender === "Female" ? "block" : "none",
                minHeight: "70vh",
              }}
            >
              <Body3d
                formData={formData}
                reset={reset}
                setReset={setReset}
                gender={"Female"}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: gender === "Male" ? "block" : "none",
                minHeight: "70vh",
              }}
            >
              <Body3d
                formData={formData}
                reset={reset}
                setReset={setReset}
                gender={"Male"}
              />
            </Box>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  width: 100%;
`;

const GridItem = styled.div`
  grid-column: span 12;
  max-height: 50vh;
  background: #f7f7f7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;

  @media screen and (min-width: 600px) {
    grid-column: span 6;
  }

  @media screen and (min-width: 1200px) {
    grid-column: span 3;
  }
`;

export default Body;
