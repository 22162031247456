import React from "react";
import MUIButton from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import Icon from "./Icon";

interface Props {
  children?: React.ReactNode;
  onClick?: any;
  type?: "button" | "submit" | "reset";
  form?: string;
  disabled?: boolean;
  link?: boolean;
  to?: string;
  iconButton?: boolean;
  icon?:
    | "plus"
    | "filter"
    | "x"
    | "edit"
    | "delete"
    | "upload"
    | "minus"
    | "refresh";
  iconAlignment?: "left" | "right";
  muiIconButton?: boolean;
  variant?: "secondary" | "warning";
  fullWidth?: boolean;
}

function Button2({
  children,
  onClick,
  type,
  form,
  disabled,
  link,
  to,
  iconButton,
  icon,
  iconAlignment,
  muiIconButton,
  variant,
  fullWidth,
}: Props) {
  return (
    <>
      {link ? (
        <StyledLink to={to ? to : "/"}>
          <MUIButton
            onClick={onClick}
            type={type}
            form={form}
            disabled={disabled}
            fullWidth={fullWidth}
            sx={{
              height: "42px",
              borderRadius: "10px",
              background: "var(--primary-color)",
              // py: 2,
              px: 2,
              textTransform: "none",
              "&:hover": {
                background: "var(--primary-20)",
              },
            }}
          >
            <Typography
              fontSize="1.25rem"
              sx={{
                fontWeight: "700",
                color: "var(--main-white)",
              }}
            >
              {children}
            </Typography>
          </MUIButton>
        </StyledLink>
      ) : iconButton && icon ? (
        <MUIButton
          disabled={disabled}
          onClick={onClick}
          endIcon={iconAlignment === "right" ? <Icon icon={icon} /> : null}
          startIcon={iconAlignment === "left" ? <Icon icon={icon} /> : null}
          sx={{
            height: "42px",
            background:
              variant === "warning"
                ? "var(--error-red)"
                : "var(--primary-color)",
            border: "1px solid",
            borderColor:
              variant === "warning"
                ? "var(--error-secondary)"
                : "var(--secondary-color)",
            color: "white",
            textTransform: "none",
            px: 2,
            "&:hover": {
              background:
                variant === "warning"
                  ? "var(--error-secondary)"
                  : "var(--secondary-color)",
            },
            "&:disabled": {
              background: "var(--grey-disabled)",
              color: "var(--grey-inactive)",
              borderColor: "var(--grey-inactive)",
              svg: {
                path: {
                  stroke: "var(--grey-inactive)",
                },
              },
            },
          }}
        >
          {children}
        </MUIButton>
      ) : muiIconButton && icon ? (
        <IconButton
          disabled={disabled}
          onClick={onClick}
          sx={{
            "&:disabled": {
              path: {
                fill: "var(--grey-inactive)",
              },
            },
          }}
        >
          <Icon icon={icon} />
        </IconButton>
      ) : (
        <MUIButton
          onClick={onClick}
          type={type}
          form={form}
          disabled={disabled}
          fullWidth={fullWidth}
          sx={{
            height: "42px",
            background:
              variant === "secondary"
                ? "white"
                : variant === "warning"
                ? "var(--error-red)"
                : "var(--primary-color)",
            px: 2,
            border:
              variant === "secondary"
                ? "1px solid var(--grey-inactive)"
                : variant === "warning"
                ? "1px solid var(--error-secondary)"
                : null,
            textTransform: "none",
            "&:hover": {
              background:
                variant === "secondary"
                  ? "var(--primary-20)"
                  : "var(--secondary-color)",
              borderColor: "var(--primary-color)",
            },
            "&:disabled": {
              background: "var(--grey-disabled)",
              color: "var(--grey-inactive)",
              borderColor: "var(--grey-inactive)",
            },
          }}
        >
          <Typography
            fontSize="1rem"
            sx={{
              fontWeight: variant === "secondary" ? "400" : "500",
              color: variant === "secondary" ? "var(--hover-grey)" : "white",
            }}
          >
            {children}
          </Typography>
        </MUIButton>
      )}
    </>
  );
}

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
`;

export default Button2;
