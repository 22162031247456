import React, { createContext, useContext, useState, useEffect } from "react";
import { signUp, login } from "../Services/Authentication/Authentication";
import {
  getUser,
  editUser,
  getAPIKey,
  regenerateAPIKey,
} from "../Services/Users/Users";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  setPersistence,
  inMemoryPersistence,
  verifyPasswordResetCode,
  confirmPasswordReset,
  updatePassword,
} from "firebase/auth";
import { auth } from "../firebase";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";

// To get the current auth level call .getIdTokenResult(); on currentUser

const AuthContext = createContext<any>(null);

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<any>();
  const [userData, setUserData] = useState<any>(null);
  const [admin, setAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [tmpUser, setTmpUser] = useState(null);
  const [hardCodedUser, setHardCodedUser] = useState<any>(null);
  const [firstLogin, setFirstLogin] = useState<string | null>(null);

  const resetPasswordFromEmail = async (code: string, password: string) => {
    return verifyPasswordResetCode(auth, code)
      .then((res: any) => {
        // res here is email
        // console.log(res);
        return confirmPasswordReset(auth, code, password)
          .then((res: any) => {
            // console.log(res);
            return 200;
          })
          .catch((err: any) => {
            return err.code;
          });
      })
      .catch((err: any) => {
        return err.code;
      });
  };

  const hardCodedLogin = async (email: string, password: string) => {
    sessionStorage.clear();
    let hardEmail = process.env.REACT_APP_HARD_EMAIL;
    let hardPass = process.env.REACT_APP_HARD_PASSWORD;

    if (email === hardEmail && password === hardPass) {
      setHardCodedUser(email);
      let token = (Math.random() + 1).toString(36).substring(7);
      sessionStorage.setItem("auth-token", token);

      return 200;
    } else {
      return null;
    }
  };

  const signIn = async (email: string, password: string, persist: boolean) => {
    if (!persist) {
      return setPersistence(auth, inMemoryPersistence).then(() => {
        return login({ email }).then((res: any) => {
          if (res) {
            setUserData(res);
            return signInWithEmailAndPassword(auth, email, password)
              .then(() => {
                return { status: 200, data: res };
              })
              .catch(() => {
                try {
                  // fallback to default tenant
                  // auth.tenantId = null;
                  return signInWithEmailAndPassword(auth, email, password)
                    .then(() => {
                      return 200;
                    })
                    .catch((err: any) => {
                      return err.code;
                    });
                } catch (error) {
                  return error;
                }
              });
          } else {
            setUserData(null);
            return res.error;
          }
        });
      });
    } else {
      return login({ email }).then((res: any) => {
        if (res) {
          setUserData(res);
          return signInWithEmailAndPassword(auth, email, password)
            .then(() => {
              return { status: 200, data: res };
            })
            .catch(() => {
              try {
                // fallback to default tenant
                // auth.tenantId = null;
                return signInWithEmailAndPassword(auth, email, password)
                  .then(() => {
                    return 200;
                  })
                  .catch((err: any) => {
                    return err.code;
                  });
              } catch (error) {
                return error;
              }
            });
        } else {
          setUserData(null);

          return res.error;
        }
      });
    }
  };

  const signUpWithEmail = async (userData: any) => {
    return createUserWithEmailAndPassword(
      auth,
      userData.email,
      userData.password
    )
      .then((res: any) => {
        let uid = res.user.uid;
        return signUp({
          userData,
          uid,
        })
          .then(() => {
            return 200;
          })
          .catch((err) => {
            return err;
          });
      })
      .catch((err) => {
        return err.code;
      });
  };

  const resetPassword = async (email: string) => {
    return sendPasswordResetEmail(auth, email)
      .then((res: any) => {
        return 200;
      })
      .catch((err) => {
        return err.code;
      });
  };
  interface UpdatePasswordProps {
    user: any;
    newPassword: string;
  }

  const updateUserPasswordOTP = async ({
    user,
    newPassword,
  }: UpdatePasswordProps) => {
    return updatePassword(user, newPassword)
      .then(async () => {
        let token = await currentUser.getIdToken();
        let now: any = new Date();

        return editUser({
          token: token,
          uid: currentUser.uid,
          tmpUser: { first_login: now.toISOString() },
        })
          .then((res: any) => {
            return 200;
          })
          .catch((error: any) => {
            return error;
          });
      })
      .catch((error: any) => {
        return error;
      });
  };

  const updateUserPassword = async ({
    user,
    newPassword,
  }: UpdatePasswordProps) => {
    return updatePassword(user, newPassword)
      .then((res: any) => {
        return res;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const logout = () => {
    signOut(auth);
    // setHardCodedUser(null);
    sessionStorage.clear();
  };

  const uploadImage = async (
    file: any,
    folder: string | "logos" | "avatars"
  ) => {
    if (!file) {
      return;
    }

    const storage = getStorage();
    const storageRef = ref(storage, `${folder}/${currentUser.uid}.jpg`);
    const metadata = {
      contentType: "image/*",
    };
    return uploadBytes(storageRef, file, metadata)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref).then((res: any) => {
          return res;
        });
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      // @TODO:
      // Hard coded user role
      if (user?.email === "testing@test.com") {
        setAdmin(true);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // right here set session storage and then use that
  // for the top level thing instead of currentUser in RequireAuth
  // base it on res.first_login

  const fetchUserData = async () => {
    if (!currentUser) {
      setUserData(null);
      return false;
    } else {
      let token = await currentUser.getIdToken();
      let uid = currentUser.uid;
      await getUser({ token, uid }).then((res: any) => {
        setUserData(res);
      });
      return true;
    }
  };

  interface FetchAPIKeyProps {
    bundleId: string;
    uid: string;
  }

  const fetchAPIKey = async ({ uid, bundleId }: FetchAPIKeyProps) => {
    let token = await currentUser.getIdToken();

    return await getAPIKey({ token: token, uid: uid, bundleId: bundleId })
      .then((res: any) => {
        return res;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const regenAPIKey = async ({ uid, bundleId }: FetchAPIKeyProps) => {
    let token = await currentUser.getIdToken();

    return await regenerateAPIKey({
      token: token,
      uid: uid,
      bundleId: bundleId,
    })
      .then((res: any) => {
        return res;
      })
      .catch((err: any) => {
        return err;
      });
  };

  useEffect(() => {
    fetchUserData();
  }, [currentUser]);

  const value = {
    signIn,
    signUpWithEmail,
    resetPassword,
    logout,
    uploadImage,
    currentUser,
    admin,
    tmpUser,
    setTmpUser,
    hardCodedLogin,
    hardCodedUser,
    resetPasswordFromEmail,
    userData,
    updateUserPasswordOTP,
    updateUserPassword,
    fetchAPIKey,
    regenAPIKey,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
