import React from "react";
import BrandDashboard from "../../Components/BrandDashboard";

const Brands = () => {
  return (
    <>
      <BrandDashboard />
    </>
  );
};

export default Brands;
