const MaleBack: any[] = [
  <>
    <g
      id="HWl2"
      data-name="Group 17723"
      transform="translate(2990.964 13565.296)"
    >
      <g
        id="Group_17498"
        data-name="Group 17498"
        transform="translate(-2697 -13217.521)"
      >
        <circle
          id="Ellipse_20"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 654)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124"
          data-name="Path 12124"
          d="M-6145.63-17993.664v656.814"
          transform="translate(6153 17998.523)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12273"
        data-name="Path 12273"
        d="M-11.15,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM.65-12.98c-.04-.12-.06-.16-.16-.16H-1.83a.179.179,0,0,0-.2.16C-3.57-6.44-4.17-4.24-4.41-2.94h-.02C-4.59-4.04-4.95-5.86-6.65-13c-.02-.14-.1-.14-.2-.14H-9.11c-.1,0-.14.04-.12.16L-5.89-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02C-.37-8.44.97-2.72,1.57-.12c.04.08.06.12.2.12H3.93c.12,0,.16-.02.2-.12L7.51-12.96c.02-.12-.02-.18-.14-.18H5.27a.182.182,0,0,0-.2.16C3.67-6.7,3.15-4.04,2.95-2.92H2.93C2.39-5.66,1.43-9.68.65-12.98Zm8.3,10.6C8.95-.28,9.99.2,11.27.2A2.916,2.916,0,0,0,12.41.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H9.13c-.12,0-.18.06-.18.16ZM21.73,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14h-2.3c-1.42,0-2.18,0-2.94.06a23.065,23.065,0,0,0,2.48-2.16A6.132,6.132,0,0,0,21.69-8.8a3.916,3.916,0,0,0-4.3-4.06,7.391,7.391,0,0,0-3.52.86.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02a42.211,42.211,0,0,1-3.32,2.72c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(-2653 -12531.521)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="Bw"
      data-name="Group 17718"
      transform="translate(-12713.28 2938.775) rotate(90)"
    >
      <g
        id="Group_17498-2"
        data-name="Group 17498"
        transform="translate(-2699 -13069.691)"
      >
        <circle
          id="Ellipse_20-2"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 202.447)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-2"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 0.447)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-2"
          data-name="Path 12124"
          d="M-6145.63-17993.666v203.631"
          transform="translate(6153 17998.203)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12266"
        data-name="Path 12266"
        d="M-24.88-.16a.152.152,0,0,0,.14.16c.76.02,2.12.04,3.86.04,3.3,0,5.48-1.52,5.48-4.04a3.351,3.351,0,0,0-1.92-3.1,3.122,3.122,0,0,0,1.26-2.6c0-1.28-.64-3.48-4.82-3.48-1.34,0-3.16.04-3.88.06-.1.02-.12.08-.12.18Zm2.38-5.66h1.82a5.262,5.262,0,0,1,1.36.14,1.679,1.679,0,0,1,1.44,1.74c0,1.34-1.2,1.98-3.08,1.96-.6,0-1.1-.02-1.54-.04Zm0-5.28c.42,0,.94-.02,1.58-.02,1.64,0,2.52.58,2.52,1.76a1.893,1.893,0,0,1-.76,1.58,12.321,12.321,0,0,0-1.82-.12H-22.5ZM-3.28,0c.14,0,.16-.04.22-.16L-.2-9.72c.04-.1-.04-.14-.12-.14H-2.34a.161.161,0,0,0-.18.12c-1.16,4.26-1.46,6.26-1.7,7.46h-.04c-.16-.8-.44-2.58-1.68-7.46a.14.14,0,0,0-.16-.12H-8.4c-.14,0-.16.04-.18.16C-9.9-4.86-10.24-3.04-10.4-2.34h-.04c-.14-.8-.44-2.7-1.64-7.4-.02-.1-.06-.12-.16-.12h-2.2c-.12,0-.18.06-.14.16l2.6,9.54c.04.12.06.16.26.16h2.18a.2.2,0,0,0,.22-.16c.94-3.32,1.44-5.24,1.9-7.24h.02c.2.98.54,2.76,1.7,7.22.04.12.08.18.22.18Z"
        transform="translate(-2685.5 -12850.296) rotate(-90)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="Sl"
      data-name="Group 17719"
      transform="translate(-12580.202 2843.775) rotate(90)"
    >
      <g
        id="Group_17498-3"
        data-name="Group 17498"
        transform="translate(-1081 147.508)"
      >
        <circle
          id="Ellipse_20-3"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618.131 -12982.508)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-3"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1271.292 -13190.332)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-3"
          data-name="Path 12124"
          d="M-5799.261-17963.834c-77.705,1.736-131.676,33.188-131.676,33.188l-92.028,21.436s-42-6.883-75.294,12.922-40.74,67.621-40.74,67.621"
          transform="translate(4535 4781.002)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12265"
        data-name="Path 12265"
        d="M-10.7.2c3.02,0,4.86-1.52,4.86-3.86,0-1.58-.74-2.84-3.34-3.92l-.76-.32c-1.58-.66-2.04-1.16-2.04-1.88,0-.7.54-1.42,2.26-1.42a6.248,6.248,0,0,1,3.02.66c.12.08.18,0,.18-.08v-1.94c0-.14-.02-.2-.12-.26a7.332,7.332,0,0,0-3.06-.52c-3.02,0-4.64,1.64-4.64,3.78,0,1.5.64,2.74,3.1,3.78l.96.4c1.52.64,2,1.2,2,1.94,0,.86-.7,1.5-2.34,1.5a6.73,6.73,0,0,1-3.56-1.04c-.08-.04-.16,0-.16.08V-.82a.3.3,0,0,0,.14.3A7.612,7.612,0,0,0-10.7.2Zm6.66-2.58C-4.04-.28-3,.2-1.72.2A2.916,2.916,0,0,0-.58.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H-3.86c-.12,0-.18.06-.18.16Z"
        transform="translate(-2684.5 -12815.5) rotate(-90)"
        fill="#52a3b3"
      />
    </g>
    <g id="Bl2" data-name="Group 17721" transform="translate(202.304 144.553)">
      <g id="Group_17498-4" data-name="Group 17498" transform="translate(37 0)">
        <circle
          id="Ellipse_20-4"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 434.936)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-4"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-4"
          data-name="Path 12124"
          d="M-6145.63-17993.668v437.273"
          transform="translate(6153 17999.006)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12270"
        data-name="Path 12270"
        d="M-25.26-.16a.152.152,0,0,0,.14.16c.76.02,2.12.04,3.86.04,3.3,0,5.48-1.52,5.48-4.04A3.351,3.351,0,0,0-17.7-7.1a3.122,3.122,0,0,0,1.26-2.6c0-1.28-.64-3.48-4.82-3.48-1.34,0-3.16.04-3.88.06-.1.02-.12.08-.12.18Zm2.38-5.66h1.82a5.262,5.262,0,0,1,1.36.14,1.679,1.679,0,0,1,1.44,1.74c0,1.34-1.2,1.98-3.08,1.96-.6,0-1.1-.02-1.54-.04Zm0-5.28c.42,0,.94-.02,1.58-.02,1.64,0,2.52.58,2.52,1.76a1.893,1.893,0,0,1-.76,1.58,12.321,12.321,0,0,0-1.82-.12h-1.52Zm8.94,8.72C-13.94-.28-12.9.2-11.62.2A2.916,2.916,0,0,0-10.48.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16h-2.06c-.12,0-.18.06-.18.16ZM-1.16,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14H-3c-1.42,0-2.18,0-2.94.06A23.064,23.064,0,0,0-3.46-4.18,6.132,6.132,0,0,0-1.2-8.8a3.916,3.916,0,0,0-4.3-4.06A7.391,7.391,0,0,0-9.02-12a.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02A42.211,42.211,0,0,1-9.34-1.74c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(34 447.936)"
        fill="#52a3b3"
      />
    </g>
    <g id="WCl" data-name="Group 17722" transform="translate(204.304 347.424)">
      <g id="Group_17498-5" data-name="Group 17498" transform="translate(35)">
        <circle
          id="Ellipse_20-5"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 189.378)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-5"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-5"
          data-name="Path 12124"
          d="M-6145.63-17993.666v191.717"
          transform="translate(6153 17999.004)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12269"
        data-name="Path 12269"
        d="M-23.4-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02C-28.64-4.04-29-5.86-30.7-13c-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-29.94-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-20.38-6.7-20.9-4.04-21.1-2.92h-.02C-21.66-5.66-22.62-9.68-23.4-12.98ZM-5.88-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-13.14-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0-5.88-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-15.64-1.98-12.48.2-8.78.2A8.624,8.624,0,0,0-6.02-.16a.254.254,0,0,0,.14-.26Zm1.84-.18C-4.04-.28-3,.2-1.72.2A2.916,2.916,0,0,0-.58.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H-3.86c-.12,0-.18.06-.18.16Z"
        transform="translate(31 202.378)"
        fill="#52a3b3"
      />
    </g>
    <g id="Bl1" data-name="Group 17720" transform="translate(202.304 144.553)">
      <g id="Group_17498-6" data-name="Group 17498" transform="translate(37)">
        <circle
          id="Ellipse_20-6"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 203.591)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-6"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-6"
          data-name="Path 12124"
          d="M-6145.63-17993.666v205.928"
          transform="translate(6153 17999.004)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12267"
        data-name="Path 12267"
        d="M-22.14-.16A.152.152,0,0,0-22,0c.76.02,2.12.04,3.86.04,3.3,0,5.48-1.52,5.48-4.04a3.351,3.351,0,0,0-1.92-3.1,3.122,3.122,0,0,0,1.26-2.6c0-1.28-.64-3.48-4.82-3.48-1.34,0-3.16.04-3.88.06-.1.02-.12.08-.12.18Zm2.38-5.66h1.82a5.262,5.262,0,0,1,1.36.14,1.679,1.679,0,0,1,1.44,1.74c0,1.34-1.2,1.98-3.08,1.96-.6,0-1.1-.02-1.54-.04Zm0-5.28c.42,0,.94-.02,1.58-.02,1.64,0,2.52.58,2.52,1.76a1.893,1.893,0,0,1-.76,1.58,12.321,12.321,0,0,0-1.82-.12h-1.52Zm8.94,8.72C-10.82-.28-9.78.2-8.5.2A2.916,2.916,0,0,0-7.36.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16h-2.06c-.12,0-.18.06-.18.16ZM-1.92,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H-3.28a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(33 216.591)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="KWl"
      data-name="Group 17724"
      transform="translate(2990.964 13565.296)"
    >
      <g
        id="Group_17498-7"
        data-name="Group 17498"
        transform="translate(-2697 -13217.521)"
      >
        <circle
          id="Ellipse_20-7"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 386)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-7"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-7"
          data-name="Path 12124"
          d="M-6145.63-17993.664v389.191"
          transform="translate(6153 17998.523)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12271"
        data-name="Path 12271"
        d="M6.34-6.76a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2H9.28a.262.262,0,0,0-.24.12L3.9-7.1v-5.88c0-.12-.04-.16-.16-.16H1.64c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18H3.74c.12,0,.16-.04.16-.18V-6.1L9.12-.16A.354.354,0,0,0,9.46,0H12a.129.129,0,0,0,.1-.22Zm15.4-6.22c-.04-.12-.06-.16-.16-.16H19.26a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14H11.98c-.1,0-.14.04-.12.16L15.2-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L28.6-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C23.48-5.66,22.52-9.68,21.74-12.98Zm8.3,10.6C30.04-.28,31.08.2,32.36.2A2.916,2.916,0,0,0,33.5.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H30.22c-.12,0-.18.06-.18.16Z"
        transform="translate(-2650 -12817.521)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="WHl"
      data-name="Group 17725"
      transform="translate(2935.964 13564.944)"
    >
      <g
        id="Group_17498-8"
        data-name="Group 17498"
        transform="translate(-2697 -13217.169)"
      >
        <circle
          id="Ellipse_20-8"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 142)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-8"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-8"
          data-name="Path 12124"
          d="M-6145.63-17993.664v144.865"
          transform="translate(6153 17998.172)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12268"
        data-name="Path 12268"
        d="M-25.6-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-32.14-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-22.58-6.7-23.1-4.04-23.3-2.92h-.02C-23.86-5.66-24.82-9.68-25.6-12.98ZM-6.82,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14H-8.94a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16H-16.9c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16Zm2.78-2.38C-4.04-.28-3,.2-1.72.2A2.916,2.916,0,0,0-.58.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H-3.86c-.12,0-.18.06-.18.16Z"
        transform="translate(-2645 -13061.169)"
        fill="#52a3b3"
      />
    </g>
    <g
      id="Ol"
      data-name="Group 17757"
      transform="translate(4752.964 13565.424)"
    >
      <circle
        id="Ellipse_20-9"
        data-name="Ellipse 20"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4597 -12560.648)"
        fill="#5c76aa"
      />
      <circle
        id="Ellipse_21-9"
        data-name="Ellipse 21"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-4609 -13217.648)"
        fill="#5c76aa"
      />
      <path
        id="Path_12124-9"
        data-name="Path 12124"
        d="M-6139.931-17991.016s-14.421,99.926-21.95,136.814-6.115,156.67,0,180.07,8.627,74.129,4.711,117.215,22.576,166.072,29.726,218.746"
        transform="translate(1538 4781.002)"
        fill="none"
        stroke="#5c76aa"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <path
        id="Path_12272"
        data-name="Path 12272"
        d="M-2.65-13.34c-3.68,0-6.2,2.92-6.2,6.76C-8.85-2.68-6.53.2-2.65.2.99.2,3.55-2.5,3.53-6.64,3.51-10.34,1.27-13.34-2.65-13.34Zm-3.7,6.76c0-2.96,1.54-4.62,3.66-4.62,2.34,0,3.72,1.82,3.72,4.62C1.05-3.8-.31-1.94-2.57-1.94-5.03-1.94-6.35-4-6.35-6.58ZM5.53-2.38C5.53-.28,6.57.2,7.85.2A2.916,2.916,0,0,0,8.99.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H5.71c-.12,0-.18.06-.18.16Z"
        transform="translate(-4591 -12528.648)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="Sl"
      data-name="Group 17774"
      transform="translate(-12727.71 1762.775) rotate(90)"
    >
      <circle
        id="Ellipse_20-10"
        data-name="Ellipse 20"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-1618.131 -12982.508)"
        fill="#52a3b3"
      />
      <circle
        id="Ellipse_21-10"
        data-name="Ellipse 21"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-1271.292 -13190.332)"
        fill="#52a3b3"
      />
      <path
        id="Path_12124-10"
        data-name="Path 12124"
        d="M-6146.766-17750.707l7.529-77.74"
        transform="translate(4535 4781.002)"
        fill="none"
        stroke="#52a3b3"
        strokeLinecap="round"
        strokeWidth="5"
        strokeDasharray="10"
      />
    </g>
  </>,
];

export default MaleBack;
