import React from "react";

import FemaleFront from "../../../mockData/svgGroups/FemaleFront";
import FemaleFrontC from "../../../mockData/svgGroups/FemaleFrontC";
import FemaleBack from "../../../mockData/svgGroups/FemaleBack";
import FemaleSide from "../../../mockData/svgGroups/FemaleSide";

import MaleFront from "../../../mockData/svgGroups/MaleFront";
import MaleFrontC from "../../../mockData/svgGroups/MaleFrontC";
import MaleBack from "../../../mockData/svgGroups/MaleBack";
import MaleSide from "../../../mockData/svgGroups/MaleSide";

interface Props {
  id: string;
  gender: string;
  view: string;
}

const Groups = ({ id, gender, view }: Props) => {
  return (
    <>
      {gender === "f" &&
        (view === "Front"
          ? FemaleFront.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-${id}-f`}>
                  {i.props?.children.map((e: any, index: number) => {
                    if (e.props.id === id) {
                      return (
                        <React.Fragment key={`${index}-f`}>{e}</React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })
          : view === "Front C"
          ? FemaleFrontC.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-${id}-f`}>
                  {i.props?.children.map((e: any, index: number) => {
                    if (e.props.id === id) {
                      return (
                        <React.Fragment key={`${index}-f`}>{e}</React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })
          : view === "Back"
          ? FemaleBack.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-${id}-f`}>
                  {i.props?.children.map((e: any, index: number) => {
                    if (e.props.id === id) {
                      return (
                        <React.Fragment key={`${index}-f`}>{e}</React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })
          : view === "Side"
          ? FemaleSide.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-${id}-f`}>
                  {i.props?.children.map((e: any, index: number) => {
                    if (e.props.id === id) {
                      return (
                        <React.Fragment key={`${index}-f`}>{e}</React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })
          : null)}
      {gender === "m" &&
        (view === "Front"
          ? MaleFront.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-${id}-m`}>
                  {i.props?.children.map((e: any, index: number) => {
                    if (e.props.id === id) {
                      return (
                        <React.Fragment key={`${index}-m`}>{e}</React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })
          : view === "Front C"
          ? MaleFrontC.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-${id}-m`}>
                  {i.props?.children.map((e: any, index: number) => {
                    if (e.props.id === id) {
                      return (
                        <React.Fragment key={`${index}-m`}>{e}</React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })
          : view === "Back"
          ? MaleBack.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-${id}-m`}>
                  {i.props?.children.map((e: any, index: number) => {
                    if (e.props.id === id) {
                      return (
                        <React.Fragment key={`${index}-m`}>{e}</React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })
          : view === "Side"
          ? MaleSide.map((i: any, index: number) => {
              return (
                <React.Fragment key={`${index}-${id}-m`}>
                  {i.props?.children.map((e: any, index: number) => {
                    if (e.props.id === id) {
                      return (
                        <React.Fragment key={`${index}-m`}>{e}</React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })
          : null)}
    </>
  );
};

export default Groups;
