import React from "react";
import { Carousel as RRCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styled from "@emotion/styled";
import CarouselItem from "./CarouselItem";

interface Props {
  data: any;
  handleClick: any;
  heading: any;
}

const Carousel = ({ data, handleClick, heading }: Props) => {
  const pages = [];

  let pageSize = 9;

  for (let i = 0; i <= data.length; i += pageSize) {
    pages.push(data.slice(i, i + pageSize));
  }

  return (
    <StyledDot>
      <StyledArrows>
        <RRCarousel showStatus={false} infiniteLoop={true}>
          {pages
            .filter((element: any) => element.length > 0)
            .map((i: any, index: number) => {
              return (
                <CarouselItem
                  key={index}
                  data={i}
                  handleClick={handleClick}
                  heading={heading}
                />
              );
            })}
        </RRCarousel>
      </StyledArrows>
    </StyledDot>
  );
};

const StyledArrows = styled.span`
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
    background: #f7f7f7;
    border-radius: 100%;
    border: 1px solid #5c76aa;
    height: 32px;
    width: 32px;
    top: -40px;
    display: block !important;
    &:hover {
      background: #dfe0e2;
    }
  }

  .carousel .control-disabled.control-arrow {
    cursor: auto;
    opacity: 1;
    background: #dfe0e2;
    border-color: #696969;

    &.control-next:before {
      opacity: 0.4;
    }

    &.control-prev:before {
      opacity: 0.4;
    }
  }

  .carousel .control-next.control-arrow {
    padding: 0 0 0 2px;
  }

  .carousel .control-prev.control-arrow {
    right: 40px;
    left: auto;
    padding: 0 2px 0 0;
  }

  .carousel .control-prev.control-arrow:before {
    border: none;
    background-image: url("/assets/chevronleft.svg");
    background-size: 10px 18px;
    background-repeat: no-repeat;
    height: 18px;
    width: 10px;
    display: inline-block;
  }

  .carousel .control-next.control-arrow:before {
    border: none;
    background-image: url("/assets/chevronright.svg");
    background-size: 10px 18px;
    background-repeat: no-repeat;
    height: 18px;
    width: 10px;
    display: inline-block;
  }
`;

const StyledDot = styled.span`
  .carousel.carousel-slider {
    overflow: visible;
  }

  .control-dots {
    bottom: -40px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .carousel .control-dots .dot {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: #52a3b3;
    box-shadow: none;
    opacity: 1;
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots li {
    height: 12px;
    width: 12px;
    background: #5c76aa;
  }
`;

export default Carousel;
