import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import AuthCard from "../../Components/AuthCard";

const FourOhFour = () => {
  const { t } = useTranslation("translations");

  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
      }}
    >
      <AuthCard heading={"4 0 4"} comingSoon>
        <Box component="div" sx={{ m: 2 }}>
          <Typography align="center" paragraph>
            {t("not_found")}
          </Typography>
        </Box>
      </AuthCard>
    </Grid>
  );
};

export default FourOhFour;
