const measures = [
  {
    id: 1,
    heading: "head",
    enabled: true,
    data: [
      {
        id: "HEc",
        title: "head_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
    ],
  },
  {
    id: 2,
    heading: "upper_body",
    enabled: true,
    data: [
      {
        id: "W2",
        title: "waist_circumference_2",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "W1",
        title: "waist_circumference_1",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "CHc",
        title: "chest_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Nc",
        title: "neck_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Bl2",
        title: "back_height_long_(length)",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "Bl1",
        title: "back_height_short_(length)",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "Bw",
        title: "back_width",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "Sw",
        title: "shoulder_width",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "Sl",
        title: "sleeve_length",
        selected: true,
        checked: true,
        type: "length",
      },
    ],
  },
  {
    id: 3,
    heading: "hand",
    enabled: true,
    data: [
      {
        id: "Fc5",
        title: "thumb",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Fc4",
        title: "little_finger",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Fc3",
        title: "ring_finger",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Fc2",
        title: "middle_finger",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Fc1",
        title: "index",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Wc3",
        title: "wristbone_3",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Wc2",
        title: "wristbone_2",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Wc1",
        title: "wristbone_1",
        selected: true,
        checked: true,
        type: "circumference",
      },
    ],
  },
  {
    id: 4,
    heading: "lower_body",
    enabled: true,
    data: [
      {
        id: "HIc",
        title: "heel_instep_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Ac",
        title: "ankle_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "UCc",
        title: "upper_calf_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Cc",
        title: "calf_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Fc",
        title: "fibula_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Kc",
        title: "knee_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "mTc",
        title: "mid_thigh",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "uTc",
        title: "upper_thigh_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "Hc",
        title: "hip_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "BCl",
        title: "lower_buttocks_to_crotch",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "CWl",
        title: "crotch_to_waist_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "CAl",
        title: "crotch_to_ankle_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "CCl",
        title: "crotch_to_calf_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "CKl",
        title: "crotch_to_knee_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "HTl2",
        title: "heel_to_thigh-high_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "HTl1",
        title: "heel_to_mid_thigh_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "HKl",
        title: "heel_to_knee_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "HFl",
        title: "heel_to_fibula_head_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "HCl2",
        title: "heel_to_calf_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "HCl",
        title: "heel_to_lower_calf_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "HWl1",
        title: "heel_to_waist_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "WKl",
        title: "waist_to_knee_front_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "KWl",
        title: "knee_back_to_waist_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "WIl",
        title: "waist_to_instep_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "WAl",
        title: "waist_to_abdomen_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "Il",
        title: "inseam_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "Ol",
        title: "outseam_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "HWl2",
        title: "heel_back_to_waist_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "WCl",
        title: "waist_to_crotch_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "WHl",
        title: "waist_to_hips_length",
        selected: true,
        checked: true,
        type: "length",
      },
    ],
  },
  {
    id: 5,
    heading: "foot",
    enabled: true,
    data: [
      {
        id: "Mc",
        title: "metatarsus_head_circumference",
        selected: true,
        checked: true,
        type: "circumference",
      },
      {
        id: "HAl",
        title: "heel_to_ankle_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "THl",
        title: "tip-toe_to_heel_length",
        selected: true,
        checked: true,
        type: "length",
      },
      {
        id: "MHl",
        title: "metatarsus_to_heel_length",
        selected: true,
        checked: true,
        type: "length",
      },
    ],
  },
  {
    id: 6,
    heading: "other",
    enabled: true,
    data: [
      {
        id: "H",
        title: "height",
        selected: true,
        checked: true,
        type: "length",
      },
    ],
  },
];
export default measures;
