import { css } from "@emotion/react";

const colors = css({
  "--primary-color": "#4AB9B6",
  "--primary-20": "rgb(74 185 182 / 20%)",
  "--secondary-color": "#348384",
  "--secondary-20": "rgb(113 181 181 / 20%)",
  "--button-stroke": "#9898984D",
  "--text-grey": "#3D3D3D",
  "--hover-grey": "#515151",
  "--light-grey": "#C4C4C6",
  "--main-grey": "#999999",
  "--dark-grey": "#5E5E5E",
  "--grey-60": "rgba(0, 0, 0, 60%)",
  "--bg-grey": "#F0F0F0",
  "--gen-background": "rgba(196, 196, 196, 0.2)",
  "--grey-inactive": "rgba(0, 0, 0, 0.35)",
  "--grey-disabled": "rgba(196, 196, 196, 0.4)",
  "--grey-50": "rgba(172, 172, 172, 0.5)",
  "--grey-20": "rgba(172, 172, 172, 0.2)",
  "--icon-grey": "#D8D8D8",
  "--main-white": "#FFFFFF",
  "--error-red": "#C90000",
  "--error-secondary": "#8B0000",
  "--link-color": "#0071C2",
  "--body-white": "#fff",
  "--transition-animation": "250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
});

export default colors;
