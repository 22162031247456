import React from "react";
// https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2

interface Props {
  condition: boolean;
  wrapper: (arg0: any) => any;
  children: React.ReactNode;
}

const ConditionalWrapper = ({ condition, wrapper, children }: Props) =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
