import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

interface DropdownFieldProps {
  showLabel?: boolean;
  labelText?: string;
  options?: any;
  setFunction?: any;
  dataIndex?: string;
  userData?: any;
  register?: any;
  value?: any;
  noDefault?: boolean;
}

const DropdownField = ({
  showLabel = true,
  labelText = "",
  options,
  setFunction,
  dataIndex,
  userData,
  register,
  value,
  noDefault,
}: DropdownFieldProps) => {
  if (!options) {
    return null;
  }
  if (!value) {
    value = "";
  }
  const handleChange = (event: any) => {
    if (setFunction && dataIndex) {
      setFunction(event.target.value, dataIndex);
    }
  };

  if (!value && dataIndex && userData[dataIndex]) {
    value = userData[dataIndex];
  }

  return (
    <Box
      component="div"
      sx={{ width: "100%", "&:not(:last-child)": { mb: 2 } }}
    >
      {showLabel && (
        <InputLabel
          sx={{
            whiteSpace: "break-spaces",
            fontSize: "15px",
            color: "#C4C4C6",
            mb: 1,
          }}
        >
          {labelText}
        </InputLabel>
      )}
      <Select
        id={dataIndex}
        name={dataIndex}
        defaultValue=""
        // defaultValue={value ? value : noDefault ? "" : options[0]}
        onChange={handleChange}
        value={value}
        // displayEmpty
        {...register}
        sx={{
          width: "100%",
          minWidth: "160px",
          // borderRadius: "50px",
          // borderColor: "transparent",
          // background: "white",
          // boxShadow:
          // "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        }}
      >
        {options &&
          options?.map((option: string) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
};

export default DropdownField;
