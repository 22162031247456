import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function ProfileIcon({ userData }: any) {
  let name = userData ? `${userData.first_name} ${userData.last_name}` : "";

  const getInitials = (string: string) => {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  let initials = name ? getInitials(name) : "";
  return (
    <>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "100%",
          background: "var(--light-grey)",
          height: "48px",
          width: "48px",
        }}
      >
        <Typography sx={{ fontWeight: "500", color: "white" }}>
          {initials}
        </Typography>
      </Box>
    </>
  );
}

export default ProfileIcon;
