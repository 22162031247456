import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { getSwaggerDoc } from "../../Services/SwaggerUI/SwaggerUI";
function SwaggerUIPage() {
  const [swaggerDoc, setSwaggerDoc] = useState(null);
  const { currentUser } = useAuth();

  const fetchDocs = async () => {
    const token = await currentUser.getIdToken();
    getSwaggerDoc(token).then((res: any) => {
      setSwaggerDoc(res);
    });
  };

  useEffect(() => {
    fetchDocs();
  }, []);

  const reqInterceptor = async (req: any) => {
    const token = await currentUser.getIdToken();
    return {
      ...req,
      headers: { ...req.headers, Authorization: `Bearer ${token}` },
    };
  };

  return (
    <>
      {swaggerDoc !== null && (
        <SwaggerUI spec={swaggerDoc} requestInterceptor={reqInterceptor} />
      )}
    </>
  );
}

export default SwaggerUIPage;
