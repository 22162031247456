import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import DefaultLayout from "./Layouts/DefaultLayout";
import AuthOutlet from "./Layouts/AuthOutlet";

import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import SignIn from "./Pages/Authentication/SignIn";
import FBResetPassword from "./Pages/Authentication/FBResetPassword";
import OTPUpdate from "./Pages/Authentication/OTPUpdate/OTPUpdate";
import OTPUpdateSuccess from "./Pages/Authentication/OTPUpdateSuccess/OTPUpdateSuccess";

import BodyMeasures from "./Pages/BodyMeasures";
import FourOhFour from "./Pages/FourOhFour";
import Home from "./Pages/Home";
import UserDashboard from "./Components/UserDashboard";

import SwaggerUI from "./Pages/SwaggerUI";
import Sizing from "./Pages/Sizing";
import Sandbox from "./Pages/Sandbox";

import RequireAuth from "./Helpers/RequireAuth";
import Framework from "./Components/Framework";
import Brands from "./Pages/Brands";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/sandbox2" element={<Sandbox2 />} /> */}
        {/* <Route path="/sandbox" element={<Sandbox />} /> */}
        <Route path="*" element={<FourOhFour />} />
        <Route element={<AuthOutlet />}>
          <Route
            path="/signin"
            element={
              // <RequireAuth redirectTo="/">
              <SignIn />
              // </RequireAuth>
            }
          />
          <Route path="/resetpassword" element={<ForgotPassword />} />
          <Route path="/reset" element={<FBResetPassword />} />
          <Route path="/otpupdate" element={<OTPUpdate />} />
          <Route path="/otpupdatesuccess" element={<OTPUpdateSuccess />} />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route
            path="/"
            element={
              <RequireAuth redirectTo="/signin">
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/swaggerui"
            element={
              <RequireAuth redirectTo="/signin">
                <SwaggerUI />
              </RequireAuth>
            }
          />
          <Route
            path="/userdashboard"
            element={
              <RequireAuth redirectTo="/signin">
                <UserDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/brands"
            element={
              <RequireAuth redirectTo="/brands">
                <Brands />
              </RequireAuth>
            }
          />
          <Route
            path="/bodymeasures"
            element={
              <RequireAuth redirectTo="/signin">
                <BodyMeasures />
              </RequireAuth>
            }
          />
          <Route
            path="/sizingtool"
            element={
              <RequireAuth redirectTo="/signin">
                <Sizing />
              </RequireAuth>
            }
          />
          <Route
            path="/framework"
            element={
              <RequireAuth redirectTo="/framework">
                <Framework />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
