// @ts-nocheck
import React from "react";
import { Button as MUIButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import ConditionalWrapper from "../../Helpers/ConditionalWrapper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface Props {
  children: React.ReactNode;
  onClick?: any;
  type?: "button" | "reset" | "submit" | "length" | "circumference" | undefined;
  invert?: boolean;
  input?: boolean;
  value?: string;
  to?: string;
  fullWidth?: boolean;
  form?: string;
  circle?: boolean;
  circleSize?: "small" | "large" | undefined;
  isActive?: boolean;
  squared?: boolean;
  small?: boolean;
  disabled?: boolean;
  lowPadding?: boolean;
  ces?: boolean;
}

const Button = ({
  children,
  onClick,
  type,
  invert,
  input,
  value,
  to,
  fullWidth,
  form,
  circle,
  circleSize,
  isActive,
  squared,
  small,
  disabled,
  lowPadding,
  ces,
}: Props) => {
  return (
    <>
      {invert ? (
        <ConditionalWrapper
          condition={Boolean(to)}
          wrapper={(children) => <StyledLink to={to}>{children}</StyledLink>}
        >
          <MUIButton
            onClick={onClick}
            type={type}
            value={value}
            form={form}
            disabled={disabled}
            disableRipple
            sx={{
              width: fullWidth ? "100%" : null,
              borderRadius: "100px",
              color: "white",
              backgroundColor: "primary.main",
              textTransform: "none",
              px: 3,
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
                boxShadow:
                  "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px",
              },
            }}
          >
            {children}
          </MUIButton>
        </ConditionalWrapper>
      ) : ces ? (
        <MUIButton
          onClick={onClick}
          type={type}
          value={value}
          form={form}
          disabled={disabled}
          disableRipple
          sx={{
            fontSize: "20px",
            width: fullWidth ? "100%" : null,
            borderRadius: "18px",
            color: disabled ? "#999999" : "white",
            backgroundColor: disabled ? "rgba(172, 172, 172, 0.2)" : "#4AB9B6",
            textTransform: "none",
            border: disabled ? "1px solid #999999" : null,
            px: 3,
            "&:hover": {
              backgroundColor: disabled ? null : "primary.main",
              color: disabled ? null : "white",
              boxShadow: disabled
                ? null
                : "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px",
            },
          }}
        >
          {children}
        </MUIButton>
      ) : input ? (
        <Label htmlFor="contained-button-file">
          <Input
            onInput={onClick}
            accept="image/*"
            id="contained-button-file"
            type="file"
          />
          <MUIButton
            component="span"
            disableRipple
            disabled={disabled}
            sx={{
              width: fullWidth ? "100%" : null,
              border: "1px solid #DFE0E2",
              borderRadius: "100px",
              color: "#343434",
              backgroundColor: "white",
              textTransform: "none",
              px: 3,
              fontWeight: "normal",
              height: small ? "26px" : null,
              fontSize: small ? "0.75rem" : null,
              "&:hover": {
                backgroundColor: "#DFE0E2",
              },
              "&:active": {
                color: "white",
                backgroundColor: "primary.main",
              },
            }}
          >
            {children}
          </MUIButton>
        </Label>
      ) : circle ? (
        <ConditionalWrapper
          condition={Boolean(to)}
          wrapper={(children) => <StyledLink to={to}>{children}</StyledLink>}
        >
          <CircularButton
            onClick={onClick}
            type={type}
            form={form}
            isActive={isActive}
            circleSize={circleSize}
          >
            {children}
          </CircularButton>
        </ConditionalWrapper>
      ) : squared ? (
        <MUIButton
          onClick={onClick}
          disabled={disabled}
          variant="outlined"
          disableRipple
          sx={{
            borderColor: "#DFE0E2",
            borderRadius: "8px",
            backgroundColor: isActive ? "primary.main" : null,
            "&:hover": {
              backgroundColor: isActive ? "primary.main" : "#DFE0E2",
              borderColor: "#DFE0E2",
            },
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: isActive ? "white" : "primary.main",
              textTransform: "none",
            }}
          >
            {children}
          </Typography>
        </MUIButton>
      ) : type === "length" ? (
        <MUIButton
          onClick={onClick}
          disabled={disabled}
          variant="outlined"
          disableRipple
          sx={{
            borderColor: "#DFE0E2",
            borderRadius: "8px",
            backgroundColor: isActive ? "primary.main" : null,
            p: "4px",
            ml: 1,
            "&:hover": {
              backgroundColor: isActive ? "primary.main" : "#DFE0E2",
              borderColor: "#DFE0E2",
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: isActive ? "white" : "black",
              textTransform: "none",
              fontSize: small ? "0.75rem" : null,
              lineHeight: small ? "1" : null,
              mr: small ? 0 : 1,
            }}
          >
            {children}
          </Typography>
          {small ? null : isActive ? (
            <VisibilityIcon fontSize="small" sx={{ color: "white" }} />
          ) : (
            <VisibilityOffIcon
              fontSize="small"
              sx={{ color: "primary.main" }}
            />
          )}
        </MUIButton>
      ) : type === "circumference" ? (
        <MUIButton
          onClick={onClick}
          disabled={disabled}
          variant="outlined"
          disableRipple
          sx={{
            borderColor: "#52A3B3",
            borderRadius: "8px",
            backgroundColor: isActive ? "#52A3B3" : "#F7F7F7",
            p: "4px",
            "&:hover": {
              backgroundColor: isActive ? "#52A3B3" : "#DFE0E2",
              borderColor: "#52A3B3",
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: isActive ? "white" : "black",
              textTransform: "none",
              fontSize: small ? "0.75rem" : null,
              lineHeight: small ? "1" : null,
              mr: small ? 0 : 1,
            }}
          >
            {children}
          </Typography>
          {small ? null : isActive ? (
            <VisibilityIcon fontSize="small" sx={{ color: "white" }} />
          ) : (
            <VisibilityOffIcon fontSize="small" sx={{ color: "#52A3B3" }} />
          )}
        </MUIButton>
      ) : (
        <ConditionalWrapper
          condition={Boolean(to)}
          wrapper={(children) => <StyledLink to={to}>{children}</StyledLink>}
        >
          <MUIButton
            onClick={onClick}
            type={type}
            form={form}
            disabled={disabled}
            variant="contained"
            disableRipple
            value={value}
            disableElevation
            sx={{
              width: fullWidth ? "100%" : null,
              border: "1px solid #DFE0E2",
              borderRadius: "100px",
              color: "#343434",
              backgroundColor: "white",
              textTransform: "none",
              px: lowPadding ? "12px" : 3,
              py: lowPadding ? "0px" : null,
              fontWeight: "normal",
              "&:hover": {
                backgroundColor: "#DFE0E2",
              },
              "&:active": {
                color: "white",
                backgroundColor: "primary.main",
              },
            }}
          >
            {children}
          </MUIButton>
        </ConditionalWrapper>
      )}
    </>
  );
};

interface CircularButtonProps {
  isActive?: boolean;
  circleSize?: string;
}

const CircularButton = styled.button<CircularButtonProps>`
  cursor: pointer;
  border: 2px solid;
  color: ${(props) => (props.isActive ? "white" : "#5C76AA")};
  width: ${(props) =>
    props.circleSize === "large"
      ? "42px"
      : props.circleSize === "small"
      ? "21px"
      : "42px"};
  height: ${(props) =>
    props.circleSize === "large"
      ? "42px"
      : props.circleSize === "small"
      ? "21px"
      : "42px"};
  border-color: ${(props) => (props.isActive ? "white" : "#C5C5C5")};
  background: ${(props) => (props.isActive ? "#5C76AA" : "white")};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 180ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  &:hover {
    border-color: white;
    background: ${(props) => (props.isActive ? "#5C76AA" : "#D3D3D3")};
  }

  svg {
    path {
      fill: ${(props) => (props.isActive ? "white" : null)};
    }
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: flex;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default Button;
