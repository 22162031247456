import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../Context/AuthContext";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import WarningIcon from "@mui/icons-material/Warning";
import Fade from "@mui/material/Fade";

interface Props {
  uid: string;
  key_status: string | null;
  userModalOpen: boolean;
}

function APIKey({ uid, key_status, userModalOpen }: Props) {
  const { fetchAPIKey, regenAPIKey } = useAuth();
  const [bundleId, setBundleId] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [key, setKey] = useState<string | null>(null);
  const [copied, setCopied] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (!userModalOpen) {
      setBundleId(null);
      setLoading(false);
      setKey(null);
      setError(null);
    }
  }, [userModalOpen]);

  const handleCopy = (data: string | null) => {
    if (!data) {
      return;
    }
    navigator.clipboard.writeText(data);

    setCopied(true);

    const timer = setTimeout(() => {
      setCopied(false);
    }, 1200);

    return () => clearTimeout(timer);
  };

  const getAPIKey = async (event: any, regenerate: boolean) => {
    event.preventDefault();
    setLoading(true);

    if (regenerate) {
      try {
        await regenAPIKey({
          uid: uid,
          bundleId: bundleId,
        }).then((res: any) => {
          if (res.error) {
            setError(res.error);
            setLoading(false);
          } else {
            setKey(res);
            setLoading(false);
          }
        });
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    } else {
      try {
        await fetchAPIKey({
          uid: uid,
          bundleId: bundleId,
        }).then((res: any) => {
          if (res.error) {
            setError(res.error);
            setLoading(false);
          } else {
            setKey(res);
            setLoading(false);
          }
        });
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
  };

  const getButtonStatus = () => {
    if (!bundleId) {
      return true;
    } else if (key) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {key_status === "enabled" ? (
        <>
          <Box component="div" sx={{ maxWidth: "743px" }}>
            <Typography variant="h2" sx={{ fontSize: "2rem", mb: 1 }}>
              Regenerate API Key
            </Typography>
            <Typography sx={{ fontWeight: "700" }}>
              <WarningIcon fontSize="small" color="error" /> Warning:{" "}
              <WarningIcon fontSize="small" color="error" /> This user already
              has an API key registered.
            </Typography>
            <Typography paragraph sx={{ fontWeight: "700" }}>
              Creating another will disable their previous key.
            </Typography>
            <Typography paragraph>
              Once you navigate away from this drawer the key will be cleared
              and unretrievable.
            </Typography>
            {error && (
              <Box
                component="div"
                sx={{
                  p: 2,
                  mb: 2,
                  borderRadius: 2,
                  background: "var(--error-red)",
                }}
              >
                <Typography sx={{ mb: 2, color: "white" }}>
                  <Typography
                    component="span"
                    sx={{ fontWeight: "700", color: "white" }}
                  >
                    Error:{" "}
                  </Typography>
                  There was an error generating the api key. Message from
                  server:
                  <Typography
                    component="span"
                    sx={{ fontWeight: "700", color: "white" }}
                  >
                    {" " + error}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            component="form"
            id="apikey"
            onSubmit={(event) => {
              getAPIKey(event, true);
            }}
            sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}
          >
            <TextField fullWidth label="UID" value={uid} disabled />
            <TextField
              fullWidth
              label="Bundle ID"
              value={bundleId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBundleId(event.target.value);
              }}
            />
          </Box>
          <Box component="div" sx={{ position: "relative" }}>
            <TextField
              InputProps={{ readOnly: true }}
              multiline
              minRows={3}
              fullWidth
              variant="outlined"
              value={key}
            />
            {key && (
              <IconButton
                size="small"
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => handleCopy(key)}
              >
                <Fade in={copied}>
                  <LibraryAddCheckIcon
                    sx={{ position: "absolute", top: "4px", right: "4px" }}
                    fontSize="small"
                  />
                </Fade>
                <Fade in={!copied}>
                  <ContentCopyIcon
                    sx={{
                      transform: "scaleY(-1)",
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                    }}
                    fontSize="small"
                  />
                </Fade>
              </IconButton>
            )}
          </Box>
          <Box
            component="div"
            sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              type="submit"
              form="apikey"
              // onClick={getAPIKey}
              variant="contained"
              disabled={getButtonStatus()}
              sx={{ color: "white" }}
              endIcon={
                loading ? (
                  <CircularProgress size={16} />
                ) : (
                  <Box
                    component="span"
                    sx={{ height: "16px", width: "16px" }}
                  />
                )
              }
            >
              <Box component="span" sx={{ height: "16px", width: "16px" }} />
              Regenerate Key
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box component="div">
            <Typography variant="h2" sx={{ fontSize: "2rem" }}>
              Generate API Key
            </Typography>
            <Typography paragraph>
              <WarningIcon fontSize="small" />
              <Typography component="span" sx={{ fontWeight: "700" }}>
                Warning: <WarningIcon fontSize="small" />
              </Typography>{" "}
              Once you navigate away from this drawer the key will be cleared
              and unretrievable.
            </Typography>
            {error && (
              <Box
                component="div"
                sx={{
                  p: 2,
                  mb: 2,
                  borderRadius: 2,
                  background: "var(--error-red)",
                }}
              >
                <Typography sx={{ mb: 2, color: "white" }}>
                  <Typography
                    component="span"
                    sx={{ fontWeight: "700", color: "white" }}
                  >
                    Error:{" "}
                  </Typography>
                  There was an error generating the api key. Message from
                  server:
                  <Typography
                    component="span"
                    sx={{ fontWeight: "700", color: "white" }}
                  >
                    {" " + error}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            component="form"
            id="apikey"
            onSubmit={(event) => {
              getAPIKey(event, false);
            }}
            sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}
          >
            <TextField fullWidth label="UID" value={uid} disabled />
            <TextField
              fullWidth
              label="Bundle ID"
              value={bundleId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBundleId(event.target.value);
              }}
            />
          </Box>
          <Box component="div" sx={{ position: "relative" }}>
            <TextField
              InputProps={{ readOnly: true }}
              multiline
              minRows={3}
              fullWidth
              variant="outlined"
              value={key}
            />
            {key && (
              <IconButton
                size="small"
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => handleCopy(key)}
              >
                <Fade in={copied}>
                  <LibraryAddCheckIcon
                    sx={{ position: "absolute", top: "4px", right: "4px" }}
                    fontSize="small"
                  />
                </Fade>
                <Fade in={!copied}>
                  <ContentCopyIcon
                    sx={{
                      transform: "scaleY(-1)",
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                    }}
                    fontSize="small"
                  />
                </Fade>
              </IconButton>
            )}
          </Box>
          <Box
            component="div"
            sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              type="submit"
              form="apikey"
              // onClick={getAPIKey}
              variant="contained"
              disabled={getButtonStatus()}
              sx={{ color: "white" }}
              endIcon={
                loading ? (
                  <CircularProgress size={16} />
                ) : (
                  <Box
                    component="span"
                    sx={{ height: "16px", width: "16px" }}
                  />
                )
              }
            >
              <Box component="span" sx={{ height: "16px", width: "16px" }} />
              Generate Key
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

export default APIKey;
