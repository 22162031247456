const FemaleSide: any[] = [
  <>
    <g id="WAl" data-name="Group 17825" transform="translate(2736.715 13581)">
      <g
        id="Group_17498"
        data-name="Group 17498"
        transform="translate(-2697 -13218)"
      >
        <circle
          id="Ellipse_20"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 43.822)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="#5c76aa"
        />
        <path
          id="Path_12124"
          data-name="Path 12124"
          d="M-6145.63-17993.664v46.158"
          transform="translate(6153 17999.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12215"
        data-name="Path 12215"
        d="M-6.83-12.98c-.04-.12-.06-.16-.16-.16H-9.31a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-13.37-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L.03-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-3.81-6.7-4.33-4.04-4.53-2.92h-.02C-5.09-5.66-6.05-9.68-6.83-12.98ZM7.85-3.46,9.01-.12a.2.2,0,0,0,.2.12h2.24c.12,0,.14-.06.12-.18L6.95-13.02c-.02-.1-.04-.12-.14-.12H4.03c-.08,0-.12.06-.12.14a3.058,3.058,0,0,1-.18,1.08L-.39-.2c-.02.14.02.2.14.2h2a.2.2,0,0,0,.22-.16l1.1-3.3ZM3.71-5.64c.6-1.82,1.4-4.16,1.72-5.5h.02c.4,1.4,1.34,4.18,1.76,5.5Zm9.14,3.26C12.85-.28,13.89.2,15.17.2A2.916,2.916,0,0,0,16.31.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.03c-.12,0-.18.06-.18.16Z"
        transform="translate(-2720 -13160.178)"
        fill="#5c76aa"
      />
    </g>
    <g id="WIl" data-name="Group 17674" transform="translate(2736.715 13581)">
      <g
        id="Group_17498-2"
        data-name="Group 17498"
        transform="translate(-1079)"
      >
        <circle
          id="Ellipse_20-2"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1552 -12575)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-2"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -13218)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-2"
          data-name="Path 12124"
          d="M-6145.63-17993.664a439.878,439.878,0,0,1-3.213,60.832c-4.084,32.643,3.748,68.787,7.146,87.838s4.764,77.814,11.438,102.77,11,67.549,15.2,88.4,8.643,47.049,18.271,73.219,22.211,92.02,22.211,92.02,7.482,47.773,3.854,83.143-8.439,53.588-8.439,53.588"
          transform="translate(4535 4781.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12221"
        data-name="Path 12221"
        d="M-3.78-12.98c-.04-.12-.06-.16-.16-.16H-6.26a.179.179,0,0,0-.2.16C-8-6.44-8.6-4.24-8.84-2.94h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-10.32-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12H-.5c.12,0,.16-.02.2-.12L3.08-12.96c.02-.12-.02-.18-.14-.18H.84a.182.182,0,0,0-.2.16C-.76-6.7-1.28-4.04-1.48-2.92H-1.5C-2.04-5.66-3-9.68-3.78-12.98Zm10.94,0c0-.12-.04-.16-.16-.16H4.9c-.1,0-.16.02-.16.16V-.18c0,.12.04.18.16.18H7c.12,0,.16-.04.16-.18ZM9.8-2.38C9.8-.28,10.84.2,12.12.2A2.916,2.916,0,0,0,13.26.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H9.98c-.12,0-.18.06-.18.16Z"
        transform="translate(-2624 -12543)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="HWl2"
      data-name="Group 17678"
      transform="translate(2856.715 13560.521)"
    >
      <g
        id="Group_17498-3"
        data-name="Group 17498"
        transform="translate(-2697 -13218)"
      >
        <circle
          id="Ellipse_20-3"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(26 663.479)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-3"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 20.479)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-3"
          data-name="Path 12124"
          d="M-6119.524-17328.191c-.388-8.5-8.848-21.846-12.636-41.793s2.882-53.66,2.882-53.66,14.58-80.211,14.042-114.094-9.989-48.371-20.4-74.436-7.327-33.514-7.327-33.514,2.813-34.492,7.327-70.461-2.7-58.309,17.971-87.57,20.069-52.738,14.874-73.77-18.17-39.336-33.436-65.945a78.892,78.892,0,0,1-9.855-28.8"
          transform="translate(6153 17999.002)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12220"
        data-name="Path 12220"
        d="M-11.15,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM.65-12.98c-.04-.12-.06-.16-.16-.16H-1.83a.179.179,0,0,0-.2.16C-3.57-6.44-4.17-4.24-4.41-2.94h-.02C-4.59-4.04-4.95-5.86-6.65-13c-.02-.14-.1-.14-.2-.14H-9.11c-.1,0-.14.04-.12.16L-5.89-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02C-.37-8.44.97-2.72,1.57-.12c.04.08.06.12.2.12H3.93c.12,0,.16-.02.2-.12L7.51-12.96c.02-.12-.02-.18-.14-.18H5.27a.182.182,0,0,0-.2.16C3.67-6.7,3.15-4.04,2.95-2.92H2.93C2.39-5.66,1.43-9.68.65-12.98Zm8.3,10.6C8.95-.28,9.99.2,11.27.2A2.916,2.916,0,0,0,12.41.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H9.13c-.12,0-.18.06-.18.16ZM21.73,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14h-2.3c-1.42,0-2.18,0-2.94.06a23.065,23.065,0,0,0,2.48-2.16A6.132,6.132,0,0,0,21.69-8.8a3.916,3.916,0,0,0-4.3-4.06,7.391,7.391,0,0,0-3.52.86.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02a42.211,42.211,0,0,1-3.32,2.72c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(-2626 -12540.521)"
        fill="#52a3b3"
      />
    </g>
    <g id="WKl" data-name="Group 17675" transform="translate(2736.715 13581)">
      <g
        id="Group_17498-4"
        data-name="Group 17498"
        transform="translate(-1079)"
      >
        <circle
          id="Ellipse_20-4"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1581 -12848)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-4"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -13218)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-4"
          data-name="Path 12124"
          d="M-6145.63-17993.664a439.878,439.878,0,0,1-3.213,60.832c-4.084,32.643,3.748,68.787,7.146,87.838s4.764,77.814,11.438,102.77,11,67.549,15.2,88.4,7.382,31.291,7.382,31.291"
          transform="translate(4535 4781.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12219"
        data-name="Path 12219"
        d="M-7.13-12.98c-.04-.12-.06-.16-.16-.16H-9.61a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-13.67-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L-.27-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-4.11-6.7-4.63-4.04-4.83-2.92h-.02C-5.39-5.66-6.35-9.68-7.13-12.98ZM6.25-6.76a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2H9.19a.262.262,0,0,0-.24.12L3.81-7.1v-5.88c0-.12-.04-.16-.16-.16H1.55c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18H3.65c.12,0,.16-.04.16-.18V-6.1L9.03-.16A.354.354,0,0,0,9.37,0h2.54a.129.129,0,0,0,.1-.22Zm6.9,4.38C13.15-.28,14.19.2,15.47.2A2.916,2.916,0,0,0,16.61.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.33c-.12,0-.18.06-.18.16Z"
        transform="translate(-2683 -12835)"
        fill="#5c76aa"
      />
    </g>
    <g
      id="KWl"
      data-name="Group 17679"
      transform="translate(2856.715 13560.521)"
    >
      <g
        id="Group_17498-5"
        data-name="Group 17498"
        transform="translate(-2697 -13218)"
      >
        <circle
          id="Ellipse_20-5"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(20 403.479)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_21-5"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 20.479)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-5"
          data-name="Path 12124"
          d="M-6125.014-17587.965s-3.1-4.215-10.623-24.209-7.327-33.514-7.327-33.514,2.813-34.492,7.327-70.461-2.7-58.309,17.971-87.57,20.069-52.738,14.874-73.77-18.17-39.336-33.436-65.945a78.978,78.978,0,0,1-9.845-28.725"
          transform="translate(6153 17999.002)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12218"
        data-name="Path 12218"
        d="M-10.7-6.76a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2h-2.3a.262.262,0,0,0-.24.12L-13.14-7.1v-5.88c0-.12-.04-.16-.16-.16h-2.1c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18h2.08c.12,0,.16-.04.16-.18V-6.1L-7.92-.16A.354.354,0,0,0-7.58,0h2.54a.129.129,0,0,0,.1-.22ZM4.7-12.98c-.04-.12-.06-.16-.16-.16H2.22a.179.179,0,0,0-.2.16C.48-6.44-.12-4.24-.36-2.94H-.38C-.54-4.04-.9-5.86-2.6-13c-.02-.14-.1-.14-.2-.14H-5.06c-.1,0-.14.04-.12.16L-1.84-.16c.06.1.1.16.22.16H.5C.64,0,.68-.08.7-.18,1.34-2.62,2.9-8.4,3.26-10.42h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12H7.98c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18H9.32a.182.182,0,0,0-.2.16C7.72-6.7,7.2-4.04,7-2.92H6.98C6.44-5.66,5.48-9.68,4.7-12.98ZM13-2.38C13-.28,14.04.2,15.32.2A2.916,2.916,0,0,0,16.46.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.18c-.12,0-.18.06-.18.16Z"
        transform="translate(-2640 -12801.521)"
        fill="#52a3b3"
      />
    </g>
    <g id="BCl" data-name="Group 17770" transform="translate(4635.285 12373)">
      <g
        id="Group_17750"
        data-name="Group 17750"
        transform="translate(-1778.57 1187.521)"
      >
        <g
          id="Group_17498-6"
          data-name="Group 17498"
          transform="translate(-2697 -13218)"
        >
          <circle
            id="Ellipse_21-6"
            data-name="Ellipse 21"
            cx="7.5"
            cy="7.5"
            r="7.5"
            transform="translate(0 20.479)"
            fill="#52a3b3"
          />
          <path
            id="Path_12124-6"
            data-name="Path 12124"
            d="M-6140.088-17774.047c41.4-33.7,39.71-77.262,39.71-77.262s.551-7.27-2.415-26.18-18.17-39.336-33.436-65.945a79.1,79.1,0,0,1-9.688-27.617"
            transform="translate(6153 17999.002)"
            fill="none"
            stroke="#52a3b3"
            strokeLinecap="round"
            strokeWidth="5"
          />
        </g>
        <path
          id="Path_12216"
          data-name="Path 12216"
          d="M-12.65-.16a.152.152,0,0,0,.14.16c.76.02,2.12.04,3.86.04C-5.35.04-3.17-1.48-3.17-4A3.351,3.351,0,0,0-5.09-7.1,3.122,3.122,0,0,0-3.83-9.7c0-1.28-.64-3.48-4.82-3.48-1.34,0-3.16.04-3.88.06-.1.02-.12.08-.12.18Zm2.38-5.66h1.82a5.262,5.262,0,0,1,1.36.14A1.679,1.679,0,0,1-5.65-3.94c0,1.34-1.2,1.98-3.08,1.96-.6,0-1.1-.02-1.54-.04Zm0-5.28c.42,0,.94-.02,1.58-.02,1.64,0,2.52.58,2.52,1.76a1.893,1.893,0,0,1-.76,1.58A12.321,12.321,0,0,0-8.75-7.9h-1.52ZM8.25-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1,.99-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0,8.25-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-1.51-1.98,1.65.2,5.35.2A8.624,8.624,0,0,0,8.11-.16a.254.254,0,0,0,.14-.26Zm1.84-.18C10.09-.28,11.13.2,12.41.2A2.916,2.916,0,0,0,13.55.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H10.27c-.12,0-.18.06-.18.16Z"
          transform="translate(-2661 -13182.521)"
          fill="#52a3b3"
        />
      </g>
      <path
        id="Path_12155"
        data-name="Path 12155"
        d="M-1694.215-13001.3c21.336,1.929,42.881-2.714,54.276-11.441"
        transform="translate(-2824.57 1208)"
        fill="none"
        stroke="#52a3b3"
        strokeLinecap="round"
        strokeWidth="5"
        strokeDasharray="10"
      />
    </g>
    <g
      id="WHl"
      data-name="Group 17680"
      transform="translate(2856.715 13560.521)"
    >
      <g
        id="Group_17498-7"
        data-name="Group 17498"
        transform="translate(-2697 -13218)"
      >
        <circle
          id="Ellipse_20-6"
          data-name="Ellipse 20"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(46 140.479)"
          fill="#52a3b3"
        />
        <circle
          id="Ellipse_46"
          data-name="Ellipse 46"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-121 140.479)"
          fill="#5c76aa"
        />
        <circle
          id="Ellipse_21-7"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0 20.479)"
          fill="#52a3b3"
        />
        <path
          id="Path_12124-7"
          data-name="Path 12124"
          d="M-6100.377-17851.309s2.781-5.148-2.415-26.18-18.17-39.336-33.436-65.945a78.939,78.939,0,0,1-9.87-28.914"
          transform="translate(6153 17999.002)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <path
          id="Path_12159"
          data-name="Path 12159"
          d="M-6145.63-17993.664a439.878,439.878,0,0,1-3.213,60.832c-2.536,20.271-.477,41.893,2.3,60.168"
          transform="translate(6033 18019.48)"
          fill="none"
          stroke="#52a3b3"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_12217"
        data-name="Path 12217"
        d="M-7.77-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-14.31-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12L-.91-12.96c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-4.75-6.7-5.27-4.04-5.47-2.92h-.02C-6.03-5.66-6.99-9.68-7.77-12.98ZM11.01,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14H8.89a.126.126,0,0,0-.14.14v5.08H3.17v-5.06c0-.12-.04-.16-.16-.16H.93c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18H3.01c.12,0,.16-.06.16-.18V-5.74H8.75V-.16c0,.12.04.16.16.16Zm2.78-2.38C13.79-.28,14.83.2,16.11.2A2.916,2.916,0,0,0,17.25.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.97c-.12,0-.18.06-.18.16Z"
        transform="translate(-2612 -13063.521)"
        fill="#52a3b3"
      />
      <g
        id="Group_17771"
        data-name="Group 17771"
        transform="translate(-1199 20.479)"
      >
        <circle
          id="Ellipse_21-8"
          data-name="Ellipse 21"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(-1618 -13218)"
          fill="#5c76aa"
        />
        <path
          id="Path_12124-8"
          data-name="Path 12124"
          d="M-6145.63-17993.664a439.878,439.878,0,0,1-3.213,60.832c-2.569,20.531-.423,42.449,2.405,60.871"
          transform="translate(4535 4781.002)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
        />
      </g>
    </g>
    <g
      id="CWl"
      data-name="Group 17827"
      transform="translate(-1924.621 -81.846)"
    >
      <g
        id="Group_17751"
        data-name="Group 17751"
        transform="translate(4661.43 13662.846)"
      >
        <g
          id="Group_17498-8"
          data-name="Group 17498"
          transform="translate(-1079)"
        >
          <circle
            id="Ellipse_21-9"
            data-name="Ellipse 21"
            cx="7.5"
            cy="7.5"
            r="7.5"
            transform="translate(-1618 -13218)"
            fill="#5c76aa"
          />
        </g>
        <path
          id="Path_12214"
          data-name="Path 12214"
          d="M-6.63-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-13.89-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0-6.63-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-16.39-1.98-13.23.2-9.53.2A8.624,8.624,0,0,0-6.77-.16a.254.254,0,0,0,.14-.26ZM4.77-12.98c-.04-.12-.06-.16-.16-.16H2.29a.179.179,0,0,0-.2.16C.55-6.44-.05-4.24-.29-2.94H-.31C-.47-4.04-.83-5.86-2.53-13c-.02-.14-.1-.14-.2-.14H-4.99c-.1,0-.14.04-.12.16L-1.77-.16c.06.1.1.16.22.16H.57c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12H8.05c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18H9.39a.182.182,0,0,0-.2.16C7.79-6.7,7.27-4.04,7.07-2.92H7.05C6.51-5.66,5.55-9.68,4.77-12.98Zm8.3,10.6C13.07-.28,14.11.2,15.39.2A2.916,2.916,0,0,0,16.53.04c.1-.06.12-.08.12-.22v-1.5c0-.1-.02-.14-.08-.12a2.55,2.55,0,0,1-.38.02c-.48,0-.7-.2-.7-.82V-14.08c0-.1-.06-.16-.18-.16H13.25c-.12,0-.18.06-.18.16Z"
          transform="translate(-2720 -13203)"
          fill="#5c76aa"
        />
        <path
          id="Path_12158"
          data-name="Path 12158"
          d="M-1752.57-13035.095c10.457,21.479,34.214,31.6,58.262,33.769"
          transform="translate(-926)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeWidth="5"
          strokeDasharray="10"
        />
      </g>
      <path
        id="Path_12161"
        data-name="Path 12161"
        d="M-6145.63-17993.664a439.878,439.878,0,0,1-3.213,60.832c-4.084,32.643,3.748,68.787,7.146,87.838.921,5.162,1.612,14.09,3.637,22.012"
        transform="translate(8117.43 18443.848)"
        fill="none"
        stroke="#5c76aa"
        strokeLinecap="round"
        strokeWidth="5"
      />
    </g>
  </>,
];

export default FemaleSide;
