import React, { useState, useEffect } from "react";
import { useAuth } from "../../../Context/AuthContext";
import { getUser } from "../../../Services/Users/Users";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ProfileIcon from "./ProfileIcon";
import Icon from "../Icon";

interface Props {
  mobileOpen: boolean;
}

function Profile({ mobileOpen }: Props) {
  // const [userData, setUserData] = useState<any>(null);
  const { currentUser, logout, userData } = useAuth();
  let navigate = useNavigate();

  // const getUserData = async () => {
  //   const token = await currentUser?.getIdToken();

  //   await getUser({ token: token, uid: currentUser?.uid }).then((res: any) => {
  //     setUserData(res);
  //   });
  // };

  // useEffect(() => {
  //   getUserData();
  // }, []);
  // const getInitials = (string: string) => {
  //   var names = string.split(" "),
  //     initials = names[0].substring(0, 1).toUpperCase();

  //   if (names.length > 1) {
  //     initials += names[names.length - 1].substring(0, 1).toUpperCase();
  //   }
  //   return initials;
  // };

  // let initials = userData ? getInitials(userData?.name) : "";

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        width: { xs: mobileOpen ? "100%" : "0px", md: "100%" },
        overflow: "hidden",
        justifyContent: "space-between",
      }}
    >
      <Box component="div" sx={{ display: "flex" }}>
        <ProfileIcon userData={userData} />
        <Box component="div" sx={{ ml: 2 }}>
          <Typography sx={{ fontWeight: "500" }}>
            {userData?.first_name} {userData?.last_name}
          </Typography>
          <Typography variant="caption">{userData?.email}</Typography>
        </Box>
      </Box>
      <LogoutButton onClick={() => handleLogout()}>
        <Icon icon="signOut" />
      </LogoutButton>
    </Box>
  );
}

const LogoutButton = styled.button`
  cursor: pointer;
  border-radius: 100%;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 48px;
  width: 48px;
  &:hover {
    background: var(--bg-grey);
  }
`;

export default Profile;
