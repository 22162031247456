const FemaleFrontC: any[] = [
  <>
    <g
      id="BCl"
      data-name="Group 17242"
      transform="translate(253.343 535.916) rotate(180)"
    >
      <path
        id="m-G-right-circ-base-back-3"
        d="M-.212,6.825C-.748,12.441-.341,26.2,177.462,4.8"
        transform="translate(29.094 1.678) rotate(94)"
        fill="none"
        stroke="#5c76aa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        strokeDasharray="10"
        opacity="0.25"
        style={{ isolation: "isolate" }}
      />
    </g>
    <g
      id="CWl"
      data-name="Group 17242"
      transform="translate(253.343 535.916) rotate(180)"
    >
      <path
        id="m-G-right-circ-base-front-3"
        d="M162.335-6.324C81.275-1.327.23,7.831-.369,11.753"
        transform="translate(32.131 1.188) rotate(94)"
        fill="none"
        stroke="#5c76aa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
    <g id="HEc" data-name="Group 17802" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17394"
        data-name="Group 17394"
        transform="translate(976.696 131.354) rotate(-90)"
      >
        <path
          id="m-G-right-circ-base-back-3-2"
          data-name="m-G-right-circ-base-back-3"
          d="M-.154,4.488C-.5,8.7,24.346,10.367,55.335,8.412s56.359-6.861,56.7-11"
          transform="translate(23.034 1.15) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-2"
          data-name="m-G-right-circ-base-front-3"
          d="M112.048,1.08c.42-3.1-24.4-3.918-55.415-1.906S.109,5.427-.289,8.367"
          transform="translate(25.491 0.871) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_47"
        data-name="Ellipse 47"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(979.615 105.455) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12183"
        data-name="Path 12183"
        d="M-20.64,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM-8.9-2.02c.02-.1-.02-.16-.14-.16H-15.1V-5.62h5.2c.08,0,.14-.02.14-.12v-1.9c0-.12-.04-.14-.14-.14h-5.2v-3.18h5.72c.12,0,.16-.08.14-.18L-9.46-13c-.02-.12-.06-.14-.18-.14h-7.72c-.12,0-.16.06-.16.16V-.16c0,.1.04.16.18.16h7.96c.12,0,.16-.02.18-.16Zm8.4-.04c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(961.115 118.955)"
        fill="#5c76aa"
      />
    </g>
    <g id="Nc" data-name="Group 17801" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17392"
        data-name="Group 17392"
        transform="translate(1073.023 208.052) rotate(90)"
      >
        <path
          id="m-G-right-circ-base-back-3-3"
          data-name="m-G-right-circ-base-back-3"
          d="M0,4.417c-.135,4.2,16.544,6.436,37.254,5.2S74.84,4.068,74.972-.06"
          transform="translate(22.974 0.768) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-3"
          data-name="m-G-right-circ-base-front-3"
          d="M75.063,3.623C75.272.533,58.628-.852,37.9.44S.191,5.379-.007,8.3"
          transform="translate(25.449 0.586) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_48"
        data-name="Ellipse 48"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1001.615 217.455) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12184"
        data-name="Path 12184"
        d="M-18.56,0c.12,0,.16-.06.16-.18V-6.86c0-1.82-.02-2.7-.1-3.72h.02C-17.7-8.64-14.04-2.18-13-.14a.254.254,0,0,0,.26.14h2.54c.14,0,.2-.04.2-.18V-12.96c0-.12-.04-.18-.18-.18H-12.1c-.12,0-.18.06-.18.16v6.66c0,1.88.04,2.54.06,3.1h-.02c-.7-1.5-3.76-7.14-5.24-9.78a.212.212,0,0,0-.24-.14h-2.82c-.1,0-.14.04-.14.14V-.18c0,.16.06.18.18.18ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(983.115 230.955)"
        fill="#5c76aa"
      />
    </g>
    {/* TODO: Here's the wrong measure */}
    <g id="CHc" data-name="Group 17800" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17391"
        data-name="Group 17391"
        transform="translate(1120.216 350.291) rotate(90)"
      >
        <path
          id="m-G-right-circ-base-back-3-4"
          data-name="m-G-right-circ-base-back-3"
          d="M.066,11.752c-.6,5.194,36.672,6.779,83.236,3.783S168.05,6.016,168.64.906"
          transform="translate(32.623 1.781) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-4"
          data-name="m-G-right-circ-base-front-3"
          d="M168.875,4.034C169.564.2,132.31-.33,85.711,2.735S.728,11.509.076,15.139"
          transform="translate(34.276 0.981) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_49"
        data-name="Ellipse 49"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(953.365 360.455) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12261"
        data-name="Path 12261"
        d="M-22.54-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-29.8-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9a.212.212,0,0,0-.12-.22,6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C-32.3-1.98-29.14.2-25.44.2a8.624,8.624,0,0,0,2.76-.36.254.254,0,0,0,.14-.26ZM-10.14,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(1052.49 396.5)"
        fill="#5c76aa"
      />
    </g>
    <g id="W1" data-name="Group 17799" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17206"
        data-name="Group 17206"
        transform="translate(1116.787 422.332) rotate(90)"
      >
        <path
          id="m-G-right-circ-base-back-3-5"
          data-name="m-G-right-circ-base-back-3"
          d="M-.042,9.54c-.532,5.616,35.272,7.65,79.959,4.807s81.3-9.575,81.819-15.1"
          transform="translate(31.815 1.694) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-5"
          data-name="m-G-right-circ-base-front-3"
          d="M161.924,3.231c.629-4.142-35.148-5.036-79.869-2.118S.526,9.88-.069,13.8"
          transform="translate(34.195 1.031) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_50"
        data-name="Ellipse 50"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1125.865 435.455) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12186"
        data-name="Path 12186"
        d="M-1.88-12.98c-.04-.12-.06-.16-.16-.16H-4.36a.179.179,0,0,0-.2.16C-6.1-6.44-6.7-4.24-6.94-2.94h-.02C-7.12-4.04-7.48-5.86-9.18-13c-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-8.42-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12H1.4c.12,0,.16-.02.2-.12L4.98-12.96c.02-.12-.02-.18-.14-.18H2.74a.182.182,0,0,0-.2.16C1.14-6.7.62-4.04.42-2.92H.4C-.14-5.66-1.1-9.68-1.88-12.98ZM10.02,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H8.66a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(1063.615 440.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="W2" data-name="Group 17804" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17207"
        data-name="Group 17207"
        transform="translate(1141.801 496.332) rotate(90)"
      >
        <path
          id="m-G-right-circ-base-back-3-6"
          data-name="m-G-right-circ-base-back-3"
          d="M-.145,11.359C-.96,17,45.73,18.268,104.119,14.467S210.436,3.142,211.237-2.4"
          transform="translate(33.623 2.226) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-6"
          data-name="m-G-right-circ-base-front-3"
          d="M211.462,1.223c.909-4.161-45.77-4.294-104.2-.416S.654,11.327-.207,15.268"
          transform="translate(35.648 1.272) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_51"
        data-name="Ellipse 51"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1149.079 509.455) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12187"
        data-name="Path 12187"
        d="M10.06-12.98c-.04-.12-.06-.16-.16-.16H7.58a.179.179,0,0,0-.2.16C5.84-6.44,5.24-4.24,5-2.94H4.98C4.82-4.04,4.46-5.86,2.76-13c-.02-.14-.1-.14-.2-.14H.3c-.1,0-.14.04-.12.16L3.52-.16c.06.1.1.16.22.16H5.86c.14,0,.18-.08.2-.18C6.7-2.62,8.26-8.4,8.62-10.42h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C11.8-5.66,10.84-9.68,10.06-12.98ZM25.84,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14H24c-1.42,0-2.18,0-2.94.06a23.065,23.065,0,0,0,2.48-2.16A6.132,6.132,0,0,0,25.8-8.8a3.916,3.916,0,0,0-4.3-4.06,7.391,7.391,0,0,0-3.52.86.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02a42.21,42.21,0,0,1-3.32,2.72c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(1070.615 514.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="Hc" data-name="Group 17810" transform="translate(-802.67 -77.283)">
      <g id="qqqqqqqqq" transform="translate(1152.305 545.757) rotate(90)">
        <path
          id="m-G-right-circ-base-back-3-7"
          data-name="m-G-right-circ-base-back-3"
          d="M-.083,13.768c-.933,5.644,50.328,6.6,114.471,2.394S231.212,4.1,232.13-1.45"
          transform="translate(36.03 2.459) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          strokeDasharray="10"
          opacity="0.25"
          style={{ isolation: "isolate" }}
        />
        <path
          id="m-G-right-circ-base-front-3-7"
          data-name="m-G-right-circ-base-front-3"
          d="M232.41,1.776c1.027-4.17-50.231-3.982-114.424.3S.854,13.331-.118,17.279"
          transform="translate(37.661 1.324) rotate(94)"
          fill="none"
          stroke="#5c76aa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_52"
        data-name="Ellipse 52"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1163.365 559.43) rotate(90)"
        fill="#5c76aa"
      />
      <text
        id="Hc"
        transform="translate(1073.865 564.955)"
        fill="#5c76aa"
        fontSize="20"
        fontFamily="AdobeClean-Bold, Adobe Clean"
        fontWeight="700"
      >
        <tspan x="0" y="0">
          Hc
        </tspan>
      </text>
    </g>
    <g id="Wc3" data-name="Group 17816" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17263"
        data-name="Group 17263"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17228"
          data-name="Group 17228"
          transform="translate(209.151 450.492) rotate(103)"
        >
          <path
            id="m-G-right-circ-base-back-3-8"
            data-name="m-G-right-circ-base-back-3"
            d="M.03,2.915c-.067,2.395,9.077,3.7,20.424,3.026S41.045,2.835,41.111.479"
            transform="translate(16.699 0.424) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-8"
            data-name="m-G-right-circ-base-front-3"
            d="M41.182,2.468C41.292.7,32.169-.112,20.814.593S.154,3.351.05,5.02"
            transform="translate(18 0.3) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17241"
          data-name="Group 17241"
          transform="translate(523.917 448.709) rotate(77)"
        >
          <path
            id="m-G-right-circ-base-back-3-9"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.2C-.067.808,9.077-.5,20.425.176s20.59,3.106,20.657,5.462"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 2.866, 41.375)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-9"
            data-name="m-G-right-circ-base-front-3"
            d="M41.132,2.552c.11,1.763-9.014,2.58-20.369,1.875S.1,1.67,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 5.259, 41.401)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_53"
        data-name="Ellipse 53"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1227.615 519.93) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12193"
        data-name="Path 12193"
        d="M-25.2-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-31.74-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16C-22.18-6.7-22.7-4.04-22.9-2.92h-.02C-23.46-5.66-24.42-9.68-25.2-12.98ZM-10.54-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.86.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Zm4.6-3.48c1.72,0,2.6.6,2.6,1.78,0,1.02-.74,1.82-2.6,1.82a6.473,6.473,0,0,1-3.18-.84c-.08-.04-.18,0-.18.08V-.72c0,.1.02.22.12.26A7.033,7.033,0,0,0-5.86.2c2.74,0,5-1.38,5-3.9A3.152,3.152,0,0,0-3.28-6.82,2.9,2.9,0,0,0-1.46-9.48c0-2.02-1.58-3.38-4.16-3.38A7.152,7.152,0,0,0-9-12.08c-.12.06-.1.1-.1.2v1.84c0,.08.02.12.14.08a5.7,5.7,0,0,1,2.88-.76c1.52,0,2.26.62,2.26,1.56,0,1-.84,1.48-2.3,1.48h-1c-.1,0-.12.06-.12.18v1.82c0,.1.04.14.14.14Z"
        transform="translate(1269.615 533.43)"
        fill="#5c76aa"
      />
    </g>
    <g id="Wc1" data-name="Group 17817" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17265"
        data-name="Group 17265"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17226"
          data-name="Group 17226"
          transform="translate(205.151 465.777) rotate(103)"
        >
          <path
            id="m-G-right-circ-base-back-3-10"
            data-name="m-G-right-circ-base-back-3"
            d="M.03,2.915c-.067,2.395,9.077,3.7,20.424,3.026S41.045,2.835,41.111.479"
            transform="translate(16.699 0.424) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-10"
            data-name="m-G-right-circ-base-front-3"
            d="M41.182,2.468C41.292.7,32.169-.112,20.814.593S.154,3.351.05,5.02"
            transform="translate(18 0.3) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17239"
          data-name="Group 17239"
          transform="translate(527.917 463.994) rotate(77)"
        >
          <path
            id="m-G-right-circ-base-back-3-11"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.2C-.067.808,9.077-.5,20.425.176s20.59,3.106,20.657,5.462"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 2.866, 41.375)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-11"
            data-name="m-G-right-circ-base-front-3"
            d="M41.132,2.552c.11,1.763-9.014,2.58-20.369,1.875S.1,1.67,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 5.259, 41.401)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_54"
        data-name="Ellipse 54"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1230.865 538.955) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12194"
        data-name="Path 12194"
        d="M-21.94-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16C-26.16-6.44-26.76-4.24-27-2.94h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14H-31.7c-.1,0-.14.04-.12.16L-28.48-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C-20.2-5.66-21.16-9.68-21.94-12.98ZM-7.28-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-9.6.2,6.092,6.092,0,0,0-7.38-.12c.08-.02.1-.06.1-.16ZM-1.92,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H-3.28a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(1266.865 552.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="Fc5" data-name="Group 17821" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17266"
        data-name="Group 17266"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17229"
          data-name="Group 17229"
          transform="translate(133.45 508.428) rotate(-45)"
        >
          <path
            id="m-G-right-circ-base-back-3-12"
            data-name="m-G-right-circ-base-back-3"
            d="M.111,2.428C.2,4.812,3.432,6.529,7.342,6.377s7.008-2.156,6.924-4.5"
            transform="translate(16.219 0.144) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-12"
            data-name="m-G-right-circ-base-front-3"
            d="M14.381,4c-.042-1.752-3.248-2.983-7.159-2.8S.174,3.005.214,4.665"
            transform="translate(17.657 0.112) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17238"
          data-name="Group 17238"
          transform="translate(541.113 512.332) rotate(-135)"
        >
          <path
            id="m-G-right-circ-base-back-3-13"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.958C.086,1.574,3.321-.143,7.231.009s7.008,2.156,6.924,4.5"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.987, 14.566)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-13"
            data-name="m-G-right-circ-base-front-3"
            d="M14.168.667c-.042,1.752-3.248,2.983-7.159,2.8S-.04,1.66,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 4.135, 14.376)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_55"
        data-name="Ellipse 55"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1252.865 567.381) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12195"
        data-name="Path 12195"
        d="M-24.7-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16h-7.72c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16Zm14.16,3.24c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.86.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16ZM-2-10.54c.12,0,.16-.04.18-.14l.52-1.82c.04-.12,0-.16-.1-.16H-7.46c-.12,0-.14.02-.18.14-.04.1-1.24,4.4-1.36,4.92v.98c0,.12.08.14.18.14C-4.9-6.16-3.34-5.4-3.34-3.8c0,1.02-.66,1.88-2.48,1.88A6.9,6.9,0,0,1-8.98-2.7a.086.086,0,0,0-.14.08V-.66c0,.14.04.2.12.24A7.349,7.349,0,0,0-5.8.2C-2.84.2-.9-1.48-.9-3.96c0-2.44-1.66-3.96-5.78-4.4.12-.5.4-1.62.54-2.18Z"
        transform="translate(1284.865 580.881)"
        fill="#5c76aa"
      />
    </g>
    <g id="Fc1" data-name="Group 17820" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17267"
        data-name="Group 17267"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17230"
          data-name="Group 17230"
          transform="matrix(0.259, -0.966, 0.966, 0.259, 145.309, 538.889)"
        >
          <path
            id="m-G-right-circ-base-back-3-14"
            data-name="m-G-right-circ-base-back-3"
            d="M0,.4c.063,1.738,2.422,2.991,5.273,2.88S10.384,1.71,10.323,0"
            transform="translate(12.855 0.04) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-14"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332,2.055C10.3.777,7.964-.121,5.111.013S-.029,1.331,0,2.541"
            transform="translate(14.404 0.126) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17237"
          data-name="Group 17237"
          transform="matrix(-0.259, -0.966, 0.966, -0.259, 533.311, 533.037)"
        >
          <path
            id="m-G-right-circ-base-back-3-15"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.887C.063,1.148,2.422-.1,5.273.007s5.111,1.573,5.05,3.283"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.72, 10.623)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-15"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332.486C10.3,1.764,7.964,2.662,5.111,2.528S-.029,1.21,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 3.016, 10.484)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_56"
        data-name="Ellipse 56"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1247.615 596.955) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12196"
        data-name="Path 12196"
        d="M-21.44-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16H-23.7c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16ZM-7.28-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-9.6.2,6.092,6.092,0,0,0-7.38-.12c.08-.02.1-.06.1-.16ZM-1.92,0c.08,0,.12-.04.12-.14V-12.6c0-.1-.02-.14-.1-.14H-3.28a.521.521,0,0,0-.3.08,9.913,9.913,0,0,1-2.78,1.2c-.1.02-.12.06-.12.16v1.7c0,.12.04.14.16.12a8.178,8.178,0,0,0,2.1-.72V-.14c0,.1.04.14.16.14Z"
        transform="translate(1279.615 610.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="Fc2" data-name="Group 17822" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17268"
        data-name="Group 17268"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17231"
          data-name="Group 17231"
          transform="matrix(0.105, -0.995, 0.995, 0.105, 158.53, 543.432)"
        >
          <path
            id="m-G-right-circ-base-back-3-16"
            data-name="m-G-right-circ-base-back-3"
            d="M0,.4c.063,1.738,2.422,2.991,5.273,2.88S10.384,1.71,10.323,0"
            transform="translate(12.855 0.04) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-16"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332,2.055C10.3.777,7.964-.121,5.111.013S-.029,1.331,0,2.541"
            transform="translate(14.404 0.126) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17236"
          data-name="Group 17236"
          transform="matrix(-0.105, -0.995, 0.995, -0.105, 521.157, 535.719)"
        >
          <path
            id="m-G-right-circ-base-back-3-17"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.887C.063,1.148,2.422-.1,5.273.007s5.111,1.573,5.05,3.283"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.72, 10.623)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-17"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332.486C10.3,1.764,7.964,2.662,5.111,2.528S-.029,1.21,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 3.016, 10.484)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_57"
        data-name="Ellipse 57"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(853.115 638.455) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12191"
        data-name="Path 12191"
        d="M-24.56-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16h-7.72c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16ZM-10.4-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-15.4-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.72.2,6.092,6.092,0,0,0-10.5-.12c.08-.02.1-.06.1-.16ZM-1.16,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14H-3c-1.42,0-2.18,0-2.94.06A23.064,23.064,0,0,0-3.46-4.18,6.132,6.132,0,0,0-1.2-8.8a3.916,3.916,0,0,0-4.3-4.06A7.391,7.391,0,0,0-9.02-12a.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02A42.211,42.211,0,0,1-9.34-1.74c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(859.115 669.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="Fc4" data-name="Group 17823" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17270"
        data-name="Group 17270"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17233"
          data-name="Group 17233"
          transform="matrix(-0.225, -0.974, 0.974, -0.225, 184.898, 542.381)"
        >
          <path
            id="m-G-right-circ-base-back-3-18"
            data-name="m-G-right-circ-base-back-3"
            d="M0,.4c.063,1.738,2.422,2.991,5.273,2.88S10.384,1.71,10.323,0"
            transform="translate(12.855 0.04) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-18"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332,2.055C10.3.777,7.964-.121,5.111.013S-.029,1.331,0,2.541"
            transform="translate(14.404 0.126) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17234"
          data-name="Group 17234"
          transform="translate(497.917 531.408) rotate(-77)"
        >
          <path
            id="m-G-right-circ-base-back-3-19"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.887C.063,1.148,2.422-.1,5.273.007s5.111,1.573,5.05,3.283"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.72, 10.623)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-19"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332.486C10.3,1.764,7.964,2.662,5.111,2.528S-.029,1.21,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 3.016, 10.484)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_58"
        data-name="Ellipse 58"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(886.615 625.955) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12190"
        data-name="Path 12190"
        d="M-25.28-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16h-7.72c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16Zm14.16,3.24c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-13.44.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Zm6.84-.96V-.16A.147.147,0,0,0-4.12,0h2c.08,0,.14-.04.14-.16V-3.02H-.76c.1,0,.14-.04.16-.1l.26-1.72c.02-.08,0-.14-.12-.14H-1.98v-7.54c0-.1-.06-.14-.16-.14h-2.5a.289.289,0,0,0-.28.12L-10-4.98a.374.374,0,0,0-.1.28v1.56c0,.08.04.12.16.12Zm0-1.96H-7.62c.6-.9,2.94-4.48,3.44-5.48h.02c-.04.7-.12,1.96-.12,3.7Z"
        transform="translate(896.615 656.955)"
        fill="#5c76aa"
      />
    </g>
    <g id="Fc3" data-name="Group 17819" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17269"
        data-name="Group 17269"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17232"
          data-name="Group 17232"
          transform="translate(171.838 544.359) rotate(-94)"
        >
          <path
            id="m-G-right-circ-base-back-3-20"
            data-name="m-G-right-circ-base-back-3"
            d="M0,.4c.063,1.738,2.422,2.991,5.273,2.88S10.384,1.71,10.323,0"
            transform="translate(12.855 0.04) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-20"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332,2.055C10.3.777,7.964-.121,5.111.013S-.029,1.331,0,2.541"
            transform="translate(14.404 0.126) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17235"
          data-name="Group 17235"
          transform="matrix(0.07, -0.998, 0.998, 0.07, 509.361, 534.801)"
        >
          <path
            id="m-G-right-circ-base-back-3-21"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.887C.063,1.148,2.422-.1,5.273.007s5.111,1.573,5.05,3.283"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 0.72, 10.623)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-21"
            data-name="m-G-right-circ-base-front-3"
            d="M10.332.486C10.3,1.764,7.964,2.662,5.111,2.528S-.029,1.21,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 3.016, 10.484)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_59"
        data-name="Ellipse 59"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1212.865 633.455) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12197"
        data-name="Path 12197"
        d="M-24.7-5.3h5.1c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14h-5.1v-3.5h5.68c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16h-7.72c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16h2.12c.12,0,.16-.04.16-.16Zm14.16,3.24c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.86.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Zm4.6-3.48c1.72,0,2.6.6,2.6,1.78,0,1.02-.74,1.82-2.6,1.82a6.473,6.473,0,0,1-3.18-.84c-.08-.04-.18,0-.18.08V-.72c0,.1.02.22.12.26A7.033,7.033,0,0,0-5.86.2c2.74,0,5-1.38,5-3.9A3.152,3.152,0,0,0-3.28-6.82,2.9,2.9,0,0,0-1.46-9.48c0-2.02-1.58-3.38-4.16-3.38A7.152,7.152,0,0,0-9-12.08c-.12.06-.1.1-.1.2v1.84c0,.08.02.12.14.08a5.7,5.7,0,0,1,2.88-.76c1.52,0,2.26.62,2.26,1.56,0,1-.84,1.48-2.3,1.48h-1c-.1,0-.12.06-.12.18v1.82c0,.1.04.14.14.14Z"
        transform="translate(1218.865 664.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="Wc2" data-name="Group 17818" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17264"
        data-name="Group 17264"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17227"
          data-name="Group 17227"
          transform="translate(207.151 458.492) rotate(103)"
        >
          <path
            id="m-G-right-circ-base-back-3-22"
            data-name="m-G-right-circ-base-back-3"
            d="M.03,2.915c-.067,2.395,9.077,3.7,20.424,3.026S41.045,2.835,41.111.479"
            transform="translate(16.699 0.424) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-22"
            data-name="m-G-right-circ-base-front-3"
            d="M41.182,2.468C41.292.7,32.169-.112,20.814.593S.154,3.351.05,5.02"
            transform="translate(18 0.3) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17240"
          data-name="Group 17240"
          transform="translate(525.917 456.709) rotate(77)"
        >
          <path
            id="m-G-right-circ-base-back-3-23"
            data-name="m-G-right-circ-base-back-3"
            d="M0,3.2C-.067.808,9.077-.5,20.425.176s20.59,3.106,20.657,5.462"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 2.866, 41.375)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-23"
            data-name="m-G-right-circ-base-front-3"
            d="M41.132,2.552c.11,1.763-9.014,2.58-20.369,1.875S.1,1.67,0,0"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, 5.259, 41.401)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_60"
        data-name="Ellipse 60"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(854.115 529.455) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12188"
        data-name="Path 12188"
        d="M-25.06-12.98c-.04-.12-.06-.16-.16-.16h-2.32a.179.179,0,0,0-.2.16c-1.54,6.54-2.14,8.74-2.38,10.04h-.02c-.16-1.1-.52-2.92-2.22-10.06-.02-.14-.1-.14-.2-.14h-2.26c-.1,0-.14.04-.12.16L-31.6-.16c.06.1.1.16.22.16h2.12c.14,0,.18-.08.2-.18.64-2.44,2.2-8.22,2.56-10.24h.02c.4,1.98,1.74,7.7,2.34,10.3.04.08.06.12.2.12h2.16c.12,0,.16-.02.2-.12l3.38-12.84c.02-.12-.02-.18-.14-.18h-2.1a.182.182,0,0,0-.2.16c-1.4,6.28-1.92,8.94-2.12,10.06h-.02C-23.32-5.66-24.28-9.68-25.06-12.98ZM-10.4-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-15.4-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-12.72.2,6.092,6.092,0,0,0-10.5-.12c.08-.02.1-.06.1-.16ZM-1.16,0a.179.179,0,0,0,.2-.14l.42-1.8c.02-.1-.02-.14-.16-.14H-3c-1.42,0-2.18,0-2.94.06A23.064,23.064,0,0,0-3.46-4.18,6.132,6.132,0,0,0-1.2-8.8a3.916,3.916,0,0,0-4.3-4.06A7.391,7.391,0,0,0-9.02-12a.235.235,0,0,0-.1.22v1.84c0,.1.06.14.16.08a5.779,5.779,0,0,1,2.94-.82c1.68,0,2.36.9,2.36,2.2,0,1.02-.34,2.18-2.36,4.02A42.211,42.211,0,0,1-9.34-1.74c-.08.06-.08.12-.08.24V-.16c0,.1,0,.16.14.16Z"
        transform="translate(837.615 542.955)"
        fill="#5c76aa"
      />
    </g>
    <g id="uTc" data-name="Group 17809" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17252"
        data-name="Group 17252"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17208"
          data-name="Group 17208"
          transform="translate(457.449 528.049) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-24"
            data-name="m-G-right-circ-base-back-3"
            d="M-.025,6.375c-.249,5.6,24.624,8.395,55.553,6.514S111.7,5.07,111.946-.435"
            transform="translate(28.659 1.152) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-24"
            data-name="m-G-right-circ-base-front-3"
            d="M112.066,4.332C112.413.21,87.583-1.45,56.633.505S.282,7.512-.047,11.415"
            transform="translate(31.816 0.843) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17209"
          data-name="Group 17209"
          transform="translate(345.742 528.049) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-25"
            data-name="m-G-right-circ-base-back-3"
            d="M-.025,6.375c-.249,5.6,24.624,8.395,55.553,6.514S111.7,5.07,111.946-.435"
            transform="translate(28.659 1.152) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-25"
            data-name="m-G-right-circ-base-front-3"
            d="M112.066,4.332C112.413.21,87.583-1.45,56.633.505S.282,7.512-.047,11.415"
            transform="translate(31.816 0.843) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_61"
        data-name="Ellipse 61"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(911.865 617.455)"
        fill="#5c76aa"
      />
      <path
        id="Path_12189"
        data-name="Path 12189"
        d="M-4.18-9.72c0-.1-.02-.14-.14-.14h-2.1c-.12,0-.16.04-.16.14v7.1A2.536,2.536,0,0,1-8.4-1.9c-1.2,0-1.74-.76-1.74-2V-9.72c0-.1-.02-.14-.14-.14h-2.1c-.12,0-.16.02-.16.14v6.1C-12.54-.82-11.08.2-9.18.2A3.952,3.952,0,0,0-6.4-.82a4.338,4.338,0,0,0,.14.7c.02.08.06.12.2.12h1.84c.12,0,.18-.04.16-.14A17.756,17.756,0,0,1-4.18-2.2ZM.34-.18c0,.14.04.2.18.18H2.58c.1,0,.16-.06.16-.18V-10.96H6.78c.1,0,.16-.06.14-.18l-.18-1.82c-.02-.14-.08-.18-.2-.18H-3.4c-.12,0-.16.04-.18.16l-.26,1.86c-.02.1.02.16.12.16H.34ZM13.3-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1,8.3-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0,10.98.2,6.092,6.092,0,0,0,13.2-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(983.675 622.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="mTc" data-name="Group 17808" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17253"
        data-name="Group 17253"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17210"
          data-name="Group 17210"
          transform="translate(451.152 605.754) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-26"
            data-name="m-G-right-circ-base-back-3"
            d="M.021,6.37c-.186,5.592,22.266,8.56,50.147,6.892s50.607-7.429,50.79-12.93"
            transform="translate(20.953 1.038) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-26"
            data-name="m-G-right-circ-base-front-3"
            d="M101.1,5.1C101.384.98,78.979-.85,51.079.892S.305,7.509.036,11.408"
            transform="translate(24.111 0.76) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17211"
          data-name="Group 17211"
          transform="translate(340.911 605.754) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-27"
            data-name="m-G-right-circ-base-back-3"
            d="M.021,6.37c-.186,5.592,22.266,8.56,50.147,6.892s50.607-7.429,50.79-12.93"
            transform="translate(20.953 1.038) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-27"
            data-name="m-G-right-circ-base-front-3"
            d="M101.1,5.1C101.384.98,78.979-.85,51.079.892S.305,7.509.036,11.408"
            transform="translate(24.111 0.76) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_62"
        data-name="Ellipse 62"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1151.865 688.955) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12192"
        data-name="Path 12192"
        d="M-12.86,0c.12,0,.16-.04.16-.16V-7.22a2.814,2.814,0,0,1,1.9-.74c1.08,0,1.68.64,1.68,1.96V-.18c0,.14.06.18.18.18h2.08c.12,0,.14-.06.14-.16V-5.98a9.151,9.151,0,0,0-.06-1.18,2.833,2.833,0,0,1,1.96-.82c1.22,0,1.68.9,1.68,2.04V-.18c0,.12.04.18.18.18H-.9c.1,0,.18-.04.18-.16v-6c0-3.02-1.72-3.9-3.38-3.9A4.929,4.929,0,0,0-7.38-8.74a2.8,2.8,0,0,0-2.58-1.32,4.269,4.269,0,0,0-2.92,1.12,3.39,3.39,0,0,0-.12-.8.19.19,0,0,0-.2-.12h-1.84c-.12,0-.18.04-.16.14.04.38.08,1.2.08,1.98V-.18c0,.12.04.18.16.18ZM2.92-.18c0,.14.04.2.18.18H5.16c.1,0,.16-.06.16-.18V-10.96H9.36c.1,0,.16-.06.14-.18l-.18-1.82c-.02-.14-.08-.18-.2-.18H-.82c-.12,0-.16.04-.18.16l-.26,1.86c-.02.1.02.16.12.16H2.92ZM15.88-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0,13.56.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Z"
        transform="translate(1091.865 693.955)"
        fill="#5c76aa"
      />
    </g>
    <g id="Kc" data-name="Group 17807" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17254"
        data-name="Group 17254"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17213"
          data-name="Group 17213"
          transform="translate(439.502 708.377) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-28"
            data-name="m-G-right-circ-base-back-3"
            d="M.106,6.031c-.042,5.582,16.849,8.939,37.731,7.76S75.662,7.256,75.7,1.764"
            transform="translate(20.401 0.775) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-28"
            data-name="m-G-right-circ-base-front-3"
            d="M75.882,6.624C76.024,2.516,59.188.3,38.294,1.549S.329,7.269.2,11.16"
            transform="translate(23.654 0.584) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17212"
          data-name="Group 17212"
          transform="translate(327.005 708.377) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-29"
            data-name="m-G-right-circ-base-back-3"
            d="M.106,6.031c-.042,5.582,16.849,8.939,37.731,7.76S75.662,7.256,75.7,1.764"
            transform="translate(20.401 0.775) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-29"
            data-name="m-G-right-circ-base-front-3"
            d="M75.882,6.624C76.024,2.516,59.188.3,38.294,1.549S.329,7.269.2,11.16"
            transform="translate(23.654 0.584) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_63"
        data-name="Ellipse 63"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(944.115 790.455) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12199"
        data-name="Path 12199"
        d="M6.34-6.76a.149.149,0,0,1,0-.18l5.36-6c.08-.08.06-.2-.12-.2H9.28a.262.262,0,0,0-.24.12L3.9-7.1v-5.88c0-.12-.04-.16-.16-.16H1.64c-.12,0-.16.04-.16.14V-.18c0,.14.06.18.18.18H3.74c.12,0,.16-.04.16-.18V-6.1L9.12-.16A.354.354,0,0,0,9.46,0H12a.129.129,0,0,0,.1-.22Zm13.4,4.7c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0,17.42.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Z"
        transform="translate(905.615 801.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="Fc" data-name="Group 17806" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17255"
        data-name="Group 17255"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17215"
          data-name="Group 17215"
          transform="translate(450.1 754.506) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-30"
            data-name="m-G-right-circ-base-back-3"
            d="M-.046,4.269c-.108,5.586,19.329,8.765,43.415,7.363S87.047,4.687,87.153-.808"
            transform="translate(17.504 0.987) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-30"
            data-name="m-G-right-circ-base-front-3"
            d="M87.2,4.618C87.406.506,68.021-1.535,43.919-.058S.09,6.072-.106,9.967"
            transform="translate(21.314 0.914) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17214"
          data-name="Group 17214"
          transform="translate(329.321 754.506) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-31"
            data-name="m-G-right-circ-base-back-3"
            d="M-.046,4.269c-.108,5.586,19.329,8.765,43.415,7.363S87.047,4.687,87.153-.808"
            transform="translate(17.504 0.987) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-31"
            data-name="m-G-right-circ-base-front-3"
            d="M87.2,4.618C87.406.506,68.021-1.535,43.919-.058S.09,6.072-.106,9.967"
            transform="translate(21.314 0.914) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_64"
        data-name="Ellipse 64"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1151.865 835.955) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12198"
        data-name="Path 12198"
        d="M3.9-5.3H9c.1,0,.16-.04.16-.14V-7.32c0-.12-.06-.14-.16-.14H3.9v-3.5H9.58c.12,0,.18-.04.16-.16l-.2-1.86c-.02-.12-.06-.16-.18-.16H1.64c-.12,0-.16.04-.16.14V-.16c0,.1.04.16.14.16H3.74c.12,0,.16-.04.16-.16ZM18.06-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0,15.74.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Z"
        transform="translate(1156.365 849.955)"
        fill="#5c76aa"
      />
    </g>
    <g id="Cc" data-name="Group 17811" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17256"
        data-name="Group 17256"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17217"
          data-name="Group 17217"
          transform="translate(447.524 804.541) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-32"
            data-name="m-G-right-circ-base-back-3"
            d="M.032,5.075c-.063,5.583,17.639,8.883,39.541,7.633S79.261,6.043,79.323.55"
            transform="translate(18.821 0.81) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-32"
            data-name="m-G-right-circ-base-front-3"
            d="M79.453,5.7C79.615,1.6,61.968-.567,40.052.757S.22,6.608.066,10.5"
            transform="translate(22.365 0.671) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17216"
          data-name="Group 17216"
          transform="translate(324.708 804.541) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-33"
            data-name="m-G-right-circ-base-back-3"
            d="M.032,5.075c-.063,5.583,17.639,8.883,39.541,7.633S79.261,6.043,79.323.55"
            transform="translate(18.821 0.81) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-33"
            data-name="m-G-right-circ-base-front-3"
            d="M79.453,5.7C79.615,1.6,61.968-.567,40.052.757S.22,6.608.066,10.5"
            transform="translate(22.365 0.671) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_65"
        data-name="Ellipse 65"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(936.615 883.955) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12200"
        data-name="Path 12200"
        d="M-9.16-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1-16.42-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9A.212.212,0,0,0-9.16-13a6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8,0,4.56,3.16,6.74,6.86,6.74A8.624,8.624,0,0,0-9.3-.16a.254.254,0,0,0,.14-.26Zm8.66.14c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(918.115 895.955)"
        fill="#5c76aa"
      />
    </g>
    <g id="UCc" data-name="Group 17812" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17257"
        data-name="Group 17257"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17220"
          data-name="Group 17220"
          transform="translate(431.599 895.859) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-34"
            data-name="m-G-right-circ-base-back-3"
            d="M-.024,2.448C-.092,5.47,10.81,7.161,24.327,6.369S48.846,2.55,48.913-.422"
            transform="translate(9.692 0.535) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-34"
            data-name="m-G-right-circ-base-front-3"
            d="M48.944,2.465C49.066.241,38.191-.836,24.664,0S.062,3.376-.054,5.482"
            transform="translate(11.705 0.478) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17221"
          data-name="Group 17221"
          transform="translate(310.15 895.859) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-35"
            data-name="m-G-right-circ-base-back-3"
            d="M-.024,2.448C-.092,5.47,10.81,7.161,24.327,6.369S48.846,2.55,48.913-.422"
            transform="translate(9.692 0.535) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-35"
            data-name="m-G-right-circ-base-front-3"
            d="M48.944,2.465C49.066.241,38.191-.836,24.664,0S.062,3.376-.054,5.482"
            transform="translate(11.705 0.478) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_66"
        data-name="Ellipse 66"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1133.865 971.955) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12201"
        data-name="Path 12201"
        d="M3.72-12.98c0-.12-.04-.16-.16-.16H1.46c-.14,0-.16.06-.16.18v8.04C1.3-1.48,3.32.2,6.48.2c2.96,0,5.26-1.48,5.26-5.22v-7.96c0-.1-.04-.16-.16-.16H9.48c-.12,0-.16.06-.16.18v7.82c0,2.44-1.18,3.2-2.76,3.2C4.64-1.94,3.72-3.2,3.72-5.1ZM23.52-2.2c0-.12-.06-.14-.14-.1a7.107,7.107,0,0,1-2.46.34A4.373,4.373,0,0,1,16.26-6.6a4.31,4.31,0,0,1,4.62-4.6,6.4,6.4,0,0,1,2.6.44c.08.04.16.04.16-.12v-1.9a.212.212,0,0,0-.12-.22,6.846,6.846,0,0,0-2.6-.34c-4.36,0-7.16,2.78-7.16,6.8C13.76-1.98,16.92.2,20.62.2a8.624,8.624,0,0,0,2.76-.36.254.254,0,0,0,.14-.26Zm8.66.14c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2,2.87,2.87,0,0,1-3.12-3.02,2.841,2.841,0,0,1,3.1-3A4.7,4.7,0,0,1,32-7.72c.08.02.16.02.16-.12l.02-1.78c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0,29.86.2a6.092,6.092,0,0,0,2.22-.32c.08-.02.1-.06.1-.16Z"
        transform="translate(1137.365 985.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="Ac" data-name="Group 17813" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17258"
        data-name="Group 17258"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17219"
          data-name="Group 17219"
          transform="translate(428.021 933.859) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-36"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.447c-.034,3.019,9.538,4.8,21.381,4.127S42.843,2.97,42.876,0"
            transform="translate(9.691 0.469) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-36"
            data-name="m-G-right-circ-base-front-3"
            d="M42.928,2.885C43.016.663,33.473-.506,21.622.21S.083,3.374,0,5.478"
            transform="translate(11.704 0.419) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17218"
          data-name="Group 17218"
          transform="translate(307.609 933.859) rotate(90)"
        >
          <path
            id="m-G-right-circ-base-back-3-37"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.447c-.034,3.019,9.538,4.8,21.381,4.127S42.843,2.97,42.876,0"
            transform="translate(9.691 0.469) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-37"
            data-name="m-G-right-circ-base-front-3"
            d="M42.928,2.885C43.016.663,33.473-.506,21.622.21S.083,3.374,0,5.478"
            transform="translate(11.704 0.419) rotate(94)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_67"
        data-name="Ellipse 67"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(957.365 1006.955) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12203"
        data-name="Path 12203"
        d="M-12.42-3.46l1.16,3.34a.2.2,0,0,0,.2.12h2.24c.12,0,.14-.06.12-.18l-4.62-12.84c-.02-.1-.04-.12-.14-.12h-2.78c-.08,0-.12.06-.12.14a3.058,3.058,0,0,1-.18,1.08L-20.66-.2c-.02.14.02.2.14.2h2a.2.2,0,0,0,.22-.16l1.1-3.3Zm-4.14-2.18c.6-1.82,1.4-4.16,1.72-5.5h.02c.4,1.4,1.34,4.18,1.76,5.5ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(939.365 1018.455)"
        fill="#5c76aa"
      />
    </g>
    <g id="HIc" data-name="Group 17814" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17259"
        data-name="Group 17259"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17222"
          data-name="Group 17222"
          transform="translate(313.441 973.391) rotate(120)"
        >
          <path
            id="m-G-right-circ-base-back-3-38"
            data-name="m-G-right-circ-base-back-3"
            d="M.034,9.245C.141,3.38,12.114-.56,26.786.17S53.256,6.116,53.15,11.886"
            transform="matrix(0.07, 0.998, -0.998, 0.07, 17.892, -0.231)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-38"
            data-name="m-G-right-circ-base-front-3"
            d="M53.173,2.922c0,4.314-11.907,7.057-26.586,6.25S0,4.085,0,0"
            transform="matrix(0.07, 0.998, -0.998, 0.07, 10.639, 0.125)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17224"
          data-name="Group 17224"
          transform="translate(423.627 947.65) rotate(60)"
        >
          <path
            id="m-G-right-circ-base-back-3-39"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.64c.107,5.866,12.08,9.805,26.752,9.076S53.221,5.77,53.116,0"
            transform="matrix(0.07, -0.998, 0.998, 0.07, 4.688, 53.186)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-39"
            data-name="m-G-right-circ-base-front-3"
            d="M53.173,6.391c0-4.314-11.907-7.057-26.586-6.25S0,5.227,0,9.313"
            transform="matrix(0.07, -0.998, 0.998, 0.07, 0, 53.044)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_68"
        data-name="Ellipse 68"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(1129.365 1023.803) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12202"
        data-name="Path 12202"
        d="M-15.54,0c.12,0,.16-.04.16-.16V-13c0-.1-.04-.14-.14-.14h-2.14a.126.126,0,0,0-.14.14v5.08h-5.58v-5.06c0-.12-.04-.16-.16-.16h-2.08c-.12,0-.16.04-.16.18V-.18c0,.12.04.18.18.18h2.06c.12,0,.16-.06.16-.18V-5.74h5.58V-.16c0,.12.04.16.16.16ZM-10-12.98c0-.12-.04-.16-.16-.16h-2.1c-.1,0-.16.02-.16.16V-.18c0,.12.04.18.16.18h2.1c.12,0,.16-.04.16-.18ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(1159.365 1036.303)"
        fill="#5c76aa"
      />
    </g>
    <g id="Mc" data-name="Group 17815" transform="translate(-802.67 -77.283)">
      <g
        id="Group_17260"
        data-name="Group 17260"
        transform="translate(689.5 77.283)"
      >
        <g
          id="Group_17223"
          data-name="Group 17223"
          transform="matrix(-0.454, 0.891, -0.891, -0.454, 278.55, 997.936)"
        >
          <path
            id="m-G-right-circ-base-back-3-40"
            data-name="m-G-right-circ-base-back-3"
            d="M.034,9.245C.141,3.38,12.108-.56,26.773.169S53.229,6.114,53.124,11.884"
            transform="matrix(0.07, 0.998, -0.998, 0.07, 17.892, -0.231)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-40"
            data-name="m-G-right-circ-base-front-3"
            d="M53.147,2.92c0,4.314-11.9,7.057-26.573,6.251S0,4.085,0,0"
            transform="matrix(0.07, 0.998, -0.998, 0.07, 10.639, 0.125)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g
          id="Group_17225"
          data-name="Group 17225"
          transform="matrix(0.454, 0.891, -0.891, 0.454, 459.777, 974.717)"
        >
          <path
            id="m-G-right-circ-base-back-3-41"
            data-name="m-G-right-circ-base-back-3"
            d="M0,2.639C.107,8.5,12.074,12.444,26.739,11.715S53.195,5.77,53.09,0"
            transform="matrix(0.07, -0.998, 0.998, 0.07, 4.689, 53.159)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            strokeDasharray="10"
            opacity="0.25"
            style={{ isolation: "isolate" }}
          />
          <path
            id="m-G-right-circ-base-front-3-41"
            data-name="m-G-right-circ-base-front-3"
            d="M53.147,6.391c0-4.314-11.9-7.057-26.573-6.251S0,5.226,0,9.311"
            transform="matrix(0.07, -0.998, 0.998, 0.07, 0, 53.017)"
            fill="none"
            stroke="#5c76aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <circle
        id="Ellipse_69"
        data-name="Ellipse 69"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(921.615 1048.803) rotate(90)"
        fill="#5c76aa"
      />
      <path
        id="Path_12204"
        data-name="Path 12204"
        d="M-23.06-13.02-23.82-.16c-.02.14.02.16.14.16h2c.12,0,.16-.02.16-.14.2-4.2.36-9.28.38-11.3h.04c.56,2.44,2.16,8.26,3.02,11.3.02.12.06.14.2.14h2a.159.159,0,0,0,.18-.16c.8-2.66,2.86-9.34,3.32-11.26h.02c-.02,1.26.02,7.02.12,11.28,0,.12.06.14.16.14h2.22c.1,0,.16-.04.14-.16l-.54-12.86c-.02-.1-.06-.12-.16-.12h-3.12c-.12,0-.14.06-.18.14-.62,2.06-2.6,8.12-3.02,9.82h-.02c-.4-1.86-2.14-7.8-2.74-9.82a.179.179,0,0,0-.2-.14h-3.2C-23-13.14-23.04-13.14-23.06-13.02ZM-.5-2.06c0-.08-.04-.14-.14-.1a5.759,5.759,0,0,1-1.74.2A2.87,2.87,0,0,1-5.5-4.98a2.841,2.841,0,0,1,3.1-3,4.7,4.7,0,0,1,1.72.26c.08.02.16.02.16-.12L-.5-9.62c0-.12-.02-.18-.1-.22a5.744,5.744,0,0,0-1.88-.22c-3.3,0-5.5,2.2-5.5,5.16A4.865,4.865,0,0,0-2.82.2,6.092,6.092,0,0,0-.6-.12c.08-.02.1-.06.1-.16Z"
        transform="translate(904.615 1061.303)"
        fill="#5c76aa"
      />
    </g>
  </>,
];

export default FemaleFrontC;
