import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";

import Icon from "./Icon";
import Button from "../../../Button2/Button2";

interface Props {
  trial_app_permissions: Array<Permission>;
  editMode: boolean;
  handleEditedData: any;
  resetPermission: any;
}

interface Permission {
  protocol_type: "fullBody" | "feet" | "hand" | "fingers" | "wrist";
  is_enabled: boolean;
  start_date: Date | string;
  end_date: Date | string;
  created_date: Date | string;
  modified_date: Date | string;
  captures_allowed: number;
  captures_taken: number;
}

function TrialAppPermissions({
  trial_app_permissions,
  editMode,
  handleEditedData,
  resetPermission,
}: Props) {
  const formatDate = (date: Date | string) => {
    let return_date = dayjs(date).format();
    if (return_date === "Invalid Date") {
      return null;
    } else {
      return return_date;
    }
  };

  const toISO = (date: any) => {
    return dayjs(date).isValid() ? dayjs(date).format() : null;
  };

  return (
    <>
      {trial_app_permissions &&
        trial_app_permissions.length &&
        trial_app_permissions.map((permissions: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <Fade in={!editMode}>
                <Box
                  component="div"
                  sx={{
                    display: editMode ? "none" : "flex",
                    alignItems: "center",
                    gap: 2,
                    my: 2,
                    marginRight: "58px",
                  }}
                >
                  <Icon
                    icon={permissions.protocol_type}
                    isActive={permissions.is_enabled}
                  />
                  <Box
                    component="div"
                    sx={{
                      display: { xs: "block", md: "flex" },
                      width: "100%",
                      gap: 2,
                    }}
                  >
                    <Box component="div" sx={{ flex: "1" }}>
                      <Typography
                        variant="caption"
                        sx={{ color: "var(--grey-60)" }}
                      >
                        Start date
                      </Typography>
                      <Typography>
                        {formatDate(permissions.start_date) || "None"}
                      </Typography>
                    </Box>
                    <Box component="div" sx={{ flex: "1" }}>
                      <Typography
                        variant="caption"
                        sx={{ color: "var(--grey-60)" }}
                      >
                        End date
                      </Typography>
                      <Typography>
                        {formatDate(permissions.end_date) || "None"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Fade>
              <Fade in={editMode}>
                <Box
                  key={index}
                  component="div"
                  sx={{
                    display: !editMode ? "none" : "flex",
                    alignItems: "center",
                    gap: 2,
                    my: 2,
                  }}
                >
                  <Icon
                    icon={permissions.protocol_type}
                    isActive={permissions.is_enabled}
                  />
                  <Box
                    component="div"
                    sx={{ display: { xs: "block", md: "flex" }, gap: 2 }}
                  >
                    <DateTimePicker
                      sx={{ mb: { xs: 2, md: 0 }, width: "100%" }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      label="Start date"
                      value={dayjs(permissions.start_date)}
                      maxDate={dayjs(permissions.end_date)}
                      onChange={(newValue) =>
                        handleEditedData(
                          permissions.protocol_type,
                          "start_date",
                          toISO(newValue)
                        )
                      }
                    />
                    <DateTimePicker
                      sx={{ width: "100%" }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      label="End date"
                      value={dayjs(permissions.end_date)}
                      minDate={dayjs(permissions.start_date)}
                      onChange={(newValue) =>
                        handleEditedData(
                          permissions.protocol_type,
                          "end_date",
                          toISO(newValue)
                        )
                      }
                    />
                  </Box>
                  <Button
                    icon="delete"
                    muiIconButton
                    onClick={() => {
                      resetPermission(permissions.protocol_type);
                    }}
                    disabled={
                      !Boolean(permissions.start_date || permissions.end_date)
                    }
                  />
                </Box>
              </Fade>
            </React.Fragment>
          );
        })}
    </>
  );
}

export default TrialAppPermissions;
