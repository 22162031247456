import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../Context/AuthContext";

import TextField from "../../../Components/TextField";
import Button from "../../../Components/Button2";
import CloseIcon from "@mui/icons-material/Close";
import { createClientBrand } from "../../../Services/Users/ClientBrands";
import { Snackbar, Portal, Box, Typography, IconButton, Alert } from "@mui/material";


interface Props {
  handleOpen: any;
}

function BrandCreate({ handleOpen }: Props) {
  const { t }: { t: any } = useTranslation("general");
  const { currentUser, setTmpUser } = useAuth();
  const [brandData, setBrandData] = useState<any>({
    name: null,
    email: null,
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [toastLoading, setToastLoading] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<string | null>(null);

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const token = await currentUser.getIdToken();
    setToastLoading(true);
    await createClientBrand({brandData, token}).then((res: any) => {
      if (res.status === 200) {
        setTmpUser(brandData.email);
        handleOpen(false);
      } else {
        setToastStatus(res.error);
      }
    });
  };

  useEffect(() => {
    if (Object.values(brandData).every(Boolean)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [brandData]);

  return (
    <>
      <Portal>
        <Snackbar
          open={toastLoading}
          autoHideDuration={5000}
          onClose={() =>  {
            setToastLoading(false);
            setToastStatus(null);
          }}
        >
          <Alert icon={false} severity={toastStatus ? "error" : "success"}>
            {toastStatus ? "Error" : "Success"}
          </Alert>
        </Snackbar>
      </Portal>
      <Box
        component="div"
        sx={{ m: 1, display: "flex", justifyContent: "flex-end" }}
      >
        <IconButton onClick={() => handleOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
        <Box component="div" sx={{ mx: 6, mb: 8 }}>
          <Typography
            variant="h1"
            sx={{
              mb: 4,
              fontSize: "3.438rem",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            {t("create_new_brand")}
          </Typography>
          <Box
            component="form"
            id="signup"
            onSubmit={(e: any) => onSubmit(e)}
            sx={{ width: "100%", maxWidth: "680px" }}
          >
            <TextField
              placeholder={t("brand_name")}
              inputType="text"
              setFunction={setBrandData}
              userData={brandData}
              dataIndex="name"
            />
            <TextField
              placeholder={t("email")}
              inputType="email"
              setFunction={setBrandData}
              userData={brandData}
              dataIndex="email"
            />
            <Button
              fullWidth
              type="submit"
              form="signup"
              disabled={disabled}
              onClick={(e: React.SyntheticEvent) => onSubmit(e)}
            >
              {t("submit")}
            </Button>
          </Box>
        </Box>
    </>
  );
}

export default BrandCreate;
