import { ThemeOptions, createTheme } from "@mui/material/styles";
import fonts from "./constants/fonts";
// mui theme does not currently support css variables in theme
// once it does use colors defined in constants

const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#4AB9B6",
    },
    secondary: {
      main: "#71B5B5",
      light: "rgba(113,181,181,0.2)",
    },
    error: {
      main: "#C90000",
    },
  },
  typography: {
    fontFamily: fonts.join(","),
    allVariants: {
      color: "var(--text-grey)",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: fonts.join(","),
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

// theme.typography.h1 = { fontFamily: "Rajdhani", color: "var(--text-grey)" };
// theme.typography.h2 = { fontFamily: "Rajdhani", color: "var(--text-grey)" };
// theme.typography.h3 = { fontFamily: "Rajdhani", color: "var(--text-grey)" };
// theme.typography.h4 = { fontFamily: "Rajdhani", color: "var(--text-grey)" };
// theme.typography.h5 = { fontFamily: "Rajdhani", color: "var(--text-grey)" };
// theme.typography.h6 = { fontFamily: "Rajdhani", color: "var(--text-grey)" };

export default theme;
