import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  email: string;
  company: string | null;
}

function AboutThisContact({ email, company }: Props) {
  return (
    <Box component="div">
      <Box component="div" sx={{ mb: 2 }}>
        <Typography sx={{ mb: 1, color: "var(--grey-60)" }}>Email</Typography>
        <Typography>{email}</Typography>
      </Box>
      <Box component="div">
        <Typography sx={{ mb: 1, color: "var(--grey-60)" }}>Company</Typography>
        <Typography>{company || "-"}</Typography>
      </Box>
    </Box>
  );
}

export default AboutThisContact;
