import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Icon from "./Icon";
import Button from "../../../Button2/Button2";
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import { StyledInputElement, StyledInputRoot, StyledButton } from "./styles";
import Switch from '@mui/material/Switch';


interface Props {
  app_permissions: any;
  editMode: boolean;
  handleEditedData: any;
  resetPermission: any;
}

function AppPermissions({
  app_permissions,
  editMode,
  handleEditedData,
  resetPermission,
}: Props) {
  const formatDate = (date: Date | string) => {
    let return_date = dayjs(date).format();
    if (return_date === "Invalid Date") {
      return null;
    } else {
      return return_date;
    }
  };

  const toISO = (date: any) => {
    return dayjs(date).isValid() ? dayjs(date).format() : null;
  };

  const permission_types = {
    "measures": app_permissions?.measures,
    "model3d": app_permissions?.model3d,
    "sizing": app_permissions?.sizing
  }

  const [checked, setChecked] = React.useState({
    measures: permission_types.measures,
    sizing: permission_types.sizing,
    model3d:permission_types.model3d,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setChecked({
      ...checked,
      [value]: event.target.checked,
    });
    handleEditedData(
      "permissions",
      value,
      event.target.checked,
      false
    )
  };

  return (
    <>
      {app_permissions?.bodyparts &&
        app_permissions?.bodyparts.length &&
        app_permissions?.bodyparts.map((permissions: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <Fade in={!editMode}>
                <Box
                  component="div"
                  sx={{
                    display: editMode ? "none" : "flex",
                    alignItems: "center",
                    gap: 2,
                    my: 2,
                    marginRight: "58px",
                  }}
                >
                  <Icon
                    icon={permissions.protocol_type}
                    isActive={permissions.is_enabled}
                  />
                  <Box
                    component="div"
                    sx={{
                      display: { xs: "block", md: "flex" },
                      width: "100%",
                      gap: 2,
                    }}
                  >
                    <Box component="div" sx={{ flex: "1" }}>
                      <Typography
                        variant="caption"
                        sx={{ color: "var(--grey-60)" }}
                      >
                        Start date
                      </Typography>
                      <Typography>
                        {formatDate(permissions.start_date) || "None"}
                      </Typography>
                    </Box>
                    <Box component="div" sx={{ flex: "1" }}>
                      <Typography
                        variant="caption"
                        sx={{ color: "var(--grey-60)" }}
                      >
                        End date
                      </Typography>
                      <Typography>
                        {formatDate(permissions.end_date) || "None"}
                      </Typography>
                    </Box>
                    <Box component="div" sx={{ flex: "1" }}>
                      <Typography
                        variant="caption"
                        sx={{ color: "var(--grey-60)" }}
                      >
                        Captures Allowed
                      </Typography>
                      <Typography>
                        {permissions.captures_allowed === -1 ? "Unlimited" : permissions.captures_allowed|| "None"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Fade>
              <Fade in={editMode}>
                <Box
                  key={index}
                  component="div"
                  sx={{
                    display: !editMode ? "none" : "flex",
                    alignItems: "center",
                    gap: 2,
                    my: 2,
                  }}
                >
                  <Icon
                    icon={permissions.protocol_type}
                    isActive={permissions.is_enabled}
                  />
                  <Box
                    component="div"
                    sx={{ display: { xs: "block", md: "flex" }, gap: 2 }}
                  >
                    <DateTimePicker
                      sx={{ mb: { xs: 2, md: 0 }, width: "100%" }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      label="Start date"
                      value={dayjs(permissions.start_date)}
                      maxDate={dayjs(permissions.end_date)}
                      onChange={(newValue) =>
                        handleEditedData(
                          permissions.protocol_type,
                          "start_date",
                          toISO(newValue),
                          false
                        )
                      }
                    />
                    <DateTimePicker
                      sx={{ width: "100%" }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      label="End date"
                      value={dayjs(permissions.end_date)}
                      minDate={dayjs(permissions.start_date)}
                      onChange={(newValue) =>
                        handleEditedData(
                          permissions.protocol_type,
                          "end_date",
                          toISO(newValue),
                          false
                        )
                      }
                    />
                    <NumberInput
                      slots={{
                        root: StyledInputRoot,
                        input: StyledInputElement,
                        incrementButton: StyledButton,
                        decrementButton: StyledButton,
                      }}
                      slotProps={{
                        incrementButton: {
                          children: '▴',
                        },
                        decrementButton: {
                          children: '▾',
                        },
                      }}
                      value={permissions.captures_allowed}
                      min={0}
                      onChange={(_, newValue) => 
                        handleEditedData(
                        permissions.protocol_type,
                        "captures_allowed",
                        newValue,
                        false
                      )}
                    />
                  </Box>
                  <Button
                    icon="delete"
                    muiIconButton
                    onClick={() => {
                      resetPermission(permissions.protocol_type, false);
                    }}
                    disabled={
                      !Boolean(permissions.start_date || permissions.end_date)
                    }
                  />
                </Box>
              </Fade>
            </React.Fragment>
          );
        })}
        {permission_types && Object.entries(permission_types).map(([key, value]) => { 
          return (
            <React.Fragment key={key}>
              <Fade in={!editMode}>
                <Box
                  component="div"
                  sx={{
                    display: editMode ? "none" : "flex",
                    alignItems: "center",
                    gap: 2,
                    my: 2,
                    marginRight: "58px",
                  }}
                >
                  <div>{key} : {value ? "True" : "False"}</div>
                </Box>
              </Fade>
              <Fade in={editMode}>
                <Box
                  key={key}
                  component="div"
                  sx={{
                    display: !editMode ? "none" : "flex",
                    alignItems: "center",
                    gap: 2,
                    my: 2,
                  }}
                >
                  <div>{key}:</div>
                  <Switch
                    checked={checked[key]}
                    onChange={(event) => {handleChange(event, key)}}
                  />
                </Box>
              </Fade>
            </React.Fragment>
          );
        })}
        
    </>
  );
}


export default AppPermissions;
