// @ts-nocheck

import React, { useRef } from "react";
interface Props {
  data: any[];
  nodes: any;
  lightsRef: any;
  materals: any;
  gender: string;
}

const Meshes = ({ data, nodes, lightsRef, materials, gender }: Props) => {
  const getData = (id: string) => {
    let idData = data.find((e: any) => {
      if (e.id === id) {
        return e;
      } else {
        return;
      }
    });
    return idData?.visible;
  };

  return (
    <>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Il_1?.geometry}
        material={materials.emiss}
        visible={getData("Il")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Il_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("Il")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HWl1_1?.geometry}
        material={materials.emiss}
        visible={getData("HWl1")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HWl1_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("HWl1")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HTl2_1?.geometry}
        material={materials.emiss}
        visible={getData("HTl2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HTl2_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("HTl2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HTl1_1?.geometry}
        material={materials.emiss}
        visible={getData("HTl1")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HTl1_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("HTl1")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HKl_1?.geometry}
        material={materials.emiss}
        visible={getData("HKl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HKl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("HKl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HCl2_1?.geometry}
        material={materials.emiss}
        visible={getData("HCl2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HCl2_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("HCl2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HCl_1?.geometry}
        material={materials.emiss}
        visible={getData("HCl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HCl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("HCl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HAl_1?.geometry}
        material={materials.emiss}
        visible={getData("HAl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HAl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("HAl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.H_1?.geometry}
        material={materials.emiss}
        visible={getData("H")}
      ></mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.H_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("H")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Ol_1?.geometry}
        material={materials.emiss}
        visible={getData("Ol")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Ol_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("Ol")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.MHl_1?.geometry}
        material={materials.emiss}
        visible={getData("MHl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.MHl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("MHl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.THl_1?.geometry}
        material={materials.emiss}
        visible={getData("THl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.THl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("THl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WIl_1?.geometry}
        material={materials.emiss}
        visible={getData("WIl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WIl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("WIl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WKl_1?.geometry}
        material={materials.emiss}
        visible={getData("WKl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WKl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("WKl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bl2_1?.geometry}
        material={materials.emiss}
        visible={getData("Bl2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bl2_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("Bl2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bl1_1?.geometry}
        material={materials.emiss}
        visible={getData("Bl1")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bl1_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("Bl1")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WCl_1?.geometry}
        material={materials.emiss}
        visible={getData("WCl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WCl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("WCl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WAl_1?.geometry}
        material={materials.emiss}
        visible={getData("WAl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WAl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("WAl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.KWl_1?.geometry}
        material={materials.emiss}
        visible={getData("KWl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.KWl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("KWl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HWl2_1?.geometry}
        material={materials.emiss}
        visible={getData("HWl2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HWl2_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("HWl2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CAl_1?.geometry}
        material={materials.emiss}
        visible={getData("CAl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CAl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("CAl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CCl_1?.geometry}
        material={materials.emiss}
        visible={getData("CCl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CCl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("CCl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CKl_1?.geometry}
        material={materials.emiss}
        visible={getData("CKl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CKl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("CKl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sw_1?.geometry}
        material={materials.emiss}
        visible={getData("Sw")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sw_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("Sw")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bw_1?.geometry}
        material={materials.emiss}
        visible={getData("Bw")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bw_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("Bw")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CWl_1?.geometry}
        material={materials.emiss}
        visible={getData("CWl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CWl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("CWl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BCl_1?.geometry}
        material={materials.emiss}
        visible={getData("BCl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BCl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("BCl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WHl_1?.geometry}
        material={materials.emiss}
        visible={getData("WHl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WHl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("WHl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sl_1?.geometry}
        material={materials.emiss}
        visible={getData("Sl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("Sl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Fc?.geometry}
        material={materials.emiss}
        visible={getData("Fc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HEc?.geometry}
        material={materials.emiss}
        visible={getData("HEc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Nc?.geometry}
        material={materials.emiss}
        visible={getData("Nc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CHc?.geometry}
        material={materials.emiss}
        visible={getData("CHc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.W1?.geometry}
        material={materials.emiss}
        visible={getData("W1")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.W2?.geometry}
        material={materials.emiss}
        visible={getData("W2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Hc?.geometry}
        material={materials.emiss}
        visible={getData("Hc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.uTc?.geometry}
        material={materials.emiss}
        visible={getData("uTc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mTc?.geometry}
        material={materials.emiss}
        visible={getData("mTc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kc?.geometry}
        material={materials.emiss}
        visible={getData("Kc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cc?.geometry}
        material={materials.emiss}
        visible={getData("Cc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.UCc?.geometry}
        material={materials.emiss}
        visible={getData("UCc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HIc?.geometry}
        material={materials.emiss}
        visible={getData("HIc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mc?.geometry}
        material={materials.emiss}
        visible={getData("Mc")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Ac?.geometry}
        material={materials.emiss}
        visible={getData("Ac")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Fc1?.geometry}
        material={materials.emiss}
        visible={getData("Fc1")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Fc4?.geometry}
        material={materials.emiss}
        visible={getData("Fc4")}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Fc2?.geometry}
        material={materials.emiss}
        visible={getData("Fc2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Fc3?.geometry}
        material={materials.emiss}
        visible={getData("Fc3")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Fc5?.geometry}
        material={materials.emiss}
        visible={getData("Fc5")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wc1?.geometry}
        material={materials.emiss}
        visible={getData("Wc1")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wc2?.geometry}
        material={materials.emiss}
        visible={getData("Wc2")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wc3?.geometry}
        material={materials.emiss}
        visible={getData("Wc3")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HFl_1?.geometry}
        material={materials.emiss}
        visible={getData("HFl")}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.HFl_2?.geometry}
        material={materials["Metallic car paint 01"]}
        visible={getData("HFl")}
      />
    </>
  );
};

export default Meshes;
